import React, { useEffect, useMemo, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import {
  getChecklistsSingleTemplateRequest, getChecklistTools,
} from '../../../store/actions/checklists';
import useInputState from '../../../helpers/hooks/useInputState';
import MyInput from '../../../templates/MyInput';
import MySelect from '../../../templates/MySelect';
import MyLoading from '../../../templates/MyLoading';
import { COLORS } from '../../../helpers/constants';
import MyButton from '../../../templates/MyButton';
import sortAndConvertChecklistCategories from '../../../helpers/sortAndConvertChecklistCategories';
import Api from '../../../Api';
import getFlatCategoriesListFromNested from '../../../helpers/getFlatCategoriesListFromNested';
import langData from '../../../helpers/localData/langData';
import ToolsList from './components/ToolsList';
import ControlItem from './components/ControlItem';
import createChecklistTemplateItems, {
  CHECKLIST_TEMPLATE_ITEMS_TYPES,
} from '../../../helpers/createChecklistTemplateItems';
import orderBySomeField from '../../../helpers/orderBySomeField';
import CancelButton from '../../../components/CancelButton';
import { setLanguage } from '../../../store/actions/account';
import LangData from '../../../helpers/localData/langData';

function SingleChecklistTemplate() {
  const dispatch = useDispatch();
  const { t, lang } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const templateId = searchParams.get('template_id');

  const [templateLang, setTemplateLang, templateLangError, setTemplateLangError] = useInputState();
  const [templateName, setTemplateName, templateNameError, setTemplateNameError] = useInputState();
  const [templateParent, setTemplateParent, templateParentError, setTemplateParentError] = useInputState(0);
  const [requirements, setRequirements, requirementsError, setRequirementsError] = useInputState();

  const [selectedToolsList, setSelectedToolsList] = useState([]);

  const [savingLoading, setSavingLoading] = useState(false);
  const [nestedCategories, setNestedCategories] = useState([]);
  const [gettingLoading, setGettingLoading] = useState(true);

  const [incomingControls, setIncomingControls] = useState([]);
  const [incomingControlsDoc, setIncomingControlsDoc] = useInputState();

  const [operationalControls, setOperationalControls] = useState([]);
  const [operationalControlsDoc, setOperationalControlsDoc] = useInputState();

  const [outputControls, setOutputControls] = useState([]);
  const [outputControlsDoc, setOutputControlsDoc] = useInputState();

  const singleTemplate = useSelector((store) => store.checklists.checklistsSingleTemplate);
  const gettingSingleTemplateLoading = useSelector((store) => store.checklists.gettingChecklistsSingleTemplateLoading);

  const categoriesToDraw = useMemo(() => {
    if (nestedCategories?.length) {
      const flattenedCategories = getFlatCategoriesListFromNested({ nestedArr: nestedCategories });

      return [{ id: 0, title: t('root_directory'), titleAsTree: t('root_directory') }, ...flattenedCategories];
    }
    return [];
  }, [nestedCategories]);

  useEffect(() => {
    setTemplateLang(LangData.find((i) => i.code === lang)?.id, true);
  }, [lang]);

  useEffect(() => {
    (async () => {
      try {
        setGettingLoading(true);
        if (templateId) {
          dispatch(getChecklistsSingleTemplateRequest(templateId));
        }
        dispatch(getChecklistTools());
        const { data } = await Api.getChecklistsCategories();
        if (data?.length) {
          setNestedCategories(sortAndConvertChecklistCategories(data));
        }
      } catch (e) {
        console.warn(e, 'while getting checklist categories');
      } finally {
        setGettingLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!gettingSingleTemplateLoading) {
      if (templateId && singleTemplate) {
        setTemplateName(singleTemplate.title);
        setTemplateLang(singleTemplate.lang_id);
        setTemplateParent(singleTemplate.catalog_id);
        setRequirements(singleTemplate.requirements);

        setIncomingControlsDoc(singleTemplate.incoming_docs);
        setOperationalControlsDoc(singleTemplate.operational_docs);
        setOutputControlsDoc(singleTemplate.output_docs);

        if (Array.isArray(singleTemplate.incoming) && singleTemplate.incoming.length) {
          setIncomingControls(orderBySomeField(singleTemplate.incoming));
        } else {
          setIncomingControls([]);
        }

        if (Array.isArray(singleTemplate.operational) && singleTemplate.operational.length) {
          setOperationalControls(orderBySomeField(singleTemplate.operational));
        } else {
          setOperationalControls([]);
        }

        if (Array.isArray(singleTemplate.output) && singleTemplate.output.length) {
          setOutputControls(orderBySomeField(singleTemplate.output));
        } else {
          setOutputControls([]);
        }

        if (singleTemplate.tools_array) {
          try {
            const toolsData = JSON.parse(singleTemplate.tools_array);
            if (Array.isArray(toolsData)) {
              setSelectedToolsList(toolsData);
            }
          } catch (e) {
            console.log('Cannot convert tools', singleTemplate.tools_array);
          }
        }
      }
    }
  }, [gettingSingleTemplateLoading]);

  const save = async () => {
    let flag = false;

    if (!templateLang) {
      setTemplateLangError(t('language') + t('is_required_m_r'));
      flag = true;
    }

    if (!templateName) {
      setTemplateNameError(t('template_name') + t('is_required_s_r'));
      flag = true;
    }

    if (!Number.isFinite(templateParent)) {
      setTemplateParentError(t('category') + t('is_required_j_r'));
      flag = true;
    }

    if (flag) {
      return;
    }

    try {
      setSavingLoading(true);

      const payload = {
        catalog_id: templateParent,
        title: templateName,
        requirements,
        lang_id: templateLang,
        tools_array: JSON.stringify(selectedToolsList),
        incoming_docs: incomingControlsDoc,
        operational_docs: operationalControlsDoc,
        output_docs: outputControlsDoc,
      };

      console.log(payload);

      let newTemplateId;

      if (templateId) {
        payload.id = singleTemplate.id;
        await Api.editChecklistTemplate(payload);
      } else {
        const { data } = await Api.addChecklistTemplate(payload);
        newTemplateId = data?.id;
      }

      const firstFlag = await createChecklistTemplateItems({
        items: incomingControls,
        type: CHECKLIST_TEMPLATE_ITEMS_TYPES.incoming,
        templateId: Number.isFinite(newTemplateId) ? newTemplateId : templateId,
      });

      const secondFlag = await createChecklistTemplateItems({
        items: operationalControls,
        type: CHECKLIST_TEMPLATE_ITEMS_TYPES.operational,
        templateId: Number.isFinite(newTemplateId) ? newTemplateId : templateId,
      });

      const thirdFlag = await createChecklistTemplateItems({
        items: outputControls,
        type: CHECKLIST_TEMPLATE_ITEMS_TYPES.output,
        templateId: Number.isFinite(newTemplateId) ? newTemplateId : templateId,
      });

      if (firstFlag && secondFlag && thirdFlag) {
        navigate('..');
      }
    } catch (e) {
      console.warn(e, 'while adding or editing checklist category');
    } finally {
      setSavingLoading(false);
    }
  };

  return (
    <div className="single_checklist_wrapper">
      <SinglePageHeader text={templateId ? t('edit_checklist_template') : t('add_checklist_template')} />
      {(gettingLoading) ? <MyLoading color={COLORS.main} /> : (
        !gettingSingleTemplateLoading && templateId && !singleTemplate ? (
          <p className="no_template">
            {t('no_template')}
            {' '}
            {templateId}
          </p>
        ) : (
          <div className="single_checklist_container">
            {templateId && (
              <div className="id_row">
                <p className="bold_text">ID:</p>
                <p className="simple_text">{templateId}</p>
              </div>
            )}
            <MySelect buttonText={t('choose_template_lang')} abilityToClear={false} withTranslation showKey="name" onlyDropDown label={t('language')} data={langData} value={templateLang} onChange={setTemplateLang} error={templateLangError} t={t} />
            <MyInput wrapperClassName="template_input" label={t('template_name')} value={templateName} onChange={setTemplateName} error={templateNameError} />
            <MySelect className="category_select" buttonText={t('choose_category')} abilityToClear={false} showKey="titleAsTree" showKeyInInput="title" onlyDropDown label={t('category')} data={categoriesToDraw} value={templateParent} onChange={setTemplateParent} error={templateParentError} t={t} />
            <MyInput placeholder={t('technical_requirements_placeholder')} textarea wrapperClassName="template_input" className="template_input_large" label={t('technical_requirements')} value={requirements} onChange={setRequirements} error={requirementsError} />
            <ToolsList list={selectedToolsList} onChange={setSelectedToolsList} needEverythingSelected={!templateId} />
            <div className="controls_wrapper">
              <ControlItem
                title={t('incoming_control')}
                list={incomingControls}
                setList={setIncomingControls}
                doc={incomingControlsDoc}
                setDoc={setIncomingControlsDoc}
              />
              <ControlItem
                title={t('operational_control')}
                list={operationalControls}
                setList={setOperationalControls}
                doc={operationalControlsDoc}
                setDoc={setOperationalControlsDoc}
              />
              <ControlItem
                title={t('output_control')}
                list={outputControls}
                setList={setOutputControls}
                doc={outputControlsDoc}
                setDoc={setOutputControlsDoc}
              />
            </div>
            <div className="buttons_row">
              <MyButton text={t('save')} onClick={save} loading={savingLoading} />
              <CancelButton />
            </div>
          </div>
        )
      )}

    </div>
  );
}

export default SingleChecklistTemplate;
