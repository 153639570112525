import { all, fork } from 'redux-saga/effects';
import account from './account';
import events from './events';
import prescriptions from './prescriptions';
import companies from './companies';
import letterheads from './letterheads';
import users from './users';
import roles from './roles';
import projects from './projects';
import objects from './objects';
import allocs from './allocs';
import configurations from './configurations';
import clients from './clients';
import acts from './acts';

import checklists from './checklists';
import checkups from './checkups';
import drawings from './drawings';

export default function* watchers() {
  yield all([
    account,
    events,
    prescriptions,
    companies,
    letterheads,
    users,
    roles,
    projects,
    objects,
    allocs,
    configurations,
    clients,
    acts,

    checklists,
    checkups,
    drawings,
  ].map(fork));
}
