import React from 'react';
import useTranslation from '../../../../translations';

const useSortTypes = () => {
  const { t } = useTranslation();
  return [
    {
      name: t('by_creation_date'),
      key: 'udate',
    },
    {
      name: t('by_project'),
      key: 'project',
    },
    {
      name: t('by_user'),
      key: 'user',
    },
    {
      name: t('by_number_out'),
      key: 'number-out',
    },
    {
      name: t('by_status'),
      key: 'status',
    },
  ];
};

export default useSortTypes;
