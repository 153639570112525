import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  GET_CHECKUPS_FAIL,
  GET_CHECKUPS_REQUEST,
  GET_CHECKUPS_SUCCESS,
} from '../actions/checkups';

export default function* watcher() {
  yield takeLatest(GET_CHECKUPS_REQUEST, getCheckups);
}

function* getCheckups(action) {
  try {
    const { data } = yield call(Api.getAllCheckups, action.payload);

    yield put({
      type: GET_CHECKUPS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting checkups');
    console.log(e?.response?.data);
    yield put({
      type: GET_CHECKUPS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
