import {
  GET_CHECKUPS_FAIL,
  GET_CHECKUPS_REQUEST,
  GET_CHECKUPS_SUCCESS, SET_CHECKUPS,
} from '../actions/checkups';
import { LOG_OUT } from '../actions/account';

const initialState = {
  checkups: {},
  checkupsLoading: false,
  checkupsError: '',

  singleCheckup: {},

  creatingCheckupLoading: false,
  creatingCheckupError: '',

  savingCheckupLoading: false,
  savingCheckupError: '',

  gettingSingleCheckupLoading: false,
  gettingSingleCheckupError: '',

};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CHECKUPS_REQUEST: {
      return {
        ...state,
        checkupsLoading: true,
        checkupsError: '',
      };
    }
    case GET_CHECKUPS_SUCCESS: {
      return {
        ...state,
        checkups: action.payload,
        checkupsLoading: false,
        checkupsError: '',
      };
    }
    case SET_CHECKUPS: {
      return {
        ...state,
        checkups: action.payload,
        checkupsLoading: true,
        checkupsError: '',
      };
    }
    case GET_CHECKUPS_FAIL: {
      return {
        ...state,
        checkupsLoading: false,
        checkupsError: 'error',
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
