import React, { useEffect, useRef, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import MyButton from '../../templates/MyButton';
import useTranslation from '../../translations';
import { changeShowPdf } from '../../store/actions/prescriptions';
import htmlStaticText from '../../helpers/htmlStaticText';
import Api from '../../Api';
import langData from '../../helpers/localData/langData';

const DOMDocument = document;

function PdfIframe({ document, activeLetterhead, documentType }) {
  const iframeRef = useRef(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showPdf = useSelector((store) => store.prescriptions.showPdf);

  const [isRendered, setIsRendered] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [noLetterheadError, setNoLetterheadError] = useState(false);

  useEffect(() => {
    if (iframeRef?.current && activeLetterhead && document) {
      setTimeout(() => {
        const render = documentType === 'prescription' ? iframeRef?.current?.contentWindow?.window?.renderPrescription : iframeRef?.current?.contentWindow?.window?.renderAct;
        if (render) {
          const lang = langData.find((item) => item.id === activeLetterhead['lang-id']);
          const payload = {
            ...document,
            activeLetterhead,
          };
          render(payload, lang?.code || 'ru');
          setIsRendered(true);
        }
      }, 800);
    }

    if (activeLetterhead === '') {
      setNoLetterheadError(true);
      setIsRendered(true);
    }
  }, [iframeRef, activeLetterhead, document]);

  const onEyePress = () => {
    dispatch(changeShowPdf(!showPdf));
  };

  const download = () => {
    if (!iframeRef?.current?.contentWindow?.printDocument) return;

    const oldPageTitle = DOMDocument.title;

    DOMDocument.title = `${documentType[0].toUpperCase() + documentType.slice(1)}_${document['number-out']}`;

    iframeRef?.current.contentWindow.printDocument();

    DOMDocument.title = oldPageTitle;
  };

  const saveToNextCloud = async () => {
    try {
      if (!iframeRef?.current || !activeLetterhead || !document) return;

      const innerHtml = iframeRef?.current.contentWindow.document.body.innerHTML;
      const htmlStatic = htmlStaticText;

      const fullHtml = htmlStatic.replace('<!--PUT HERE-->', innerHtml);

      setSaveLoading(true);
      const payload = {
        'path-folder': document['path-folder'],
        filename: `${document['path-folder'].split('/').reverse()[1]}.pdf`,
        html: fullHtml,

      };
      const { data } = documentType === 'prescription' ? await Api.savePrescriptionToNextCloud(payload) : await Api.saveActToNextCloud(payload);
      console.log(data);
    } catch (e) {
      console.warn('Error while saving document to NextCloud', e);
    } finally {
      setSaveLoading(false);
    }
  };

  return (
    <div className="pdf_iframe_component">
      <div className="line" />
      <div className="pdf_buttons_row">
        <MyButton text={t('print_pdf')} className="pdf_button" onClick={download} loading={!isRendered} disabled={noLetterheadError} />
        <MyButton text={t('save_pdf_to_nextcloud')} className="pdf_button" onClick={saveToNextCloud} loading={!isRendered || saveLoading} disabled={noLetterheadError} />
        <MyButton className="pdf_button icon" onClick={onEyePress} iconUri={showPdf ? '/images/see.png' : '/images/unsee.png'} />
        {document?.['agreement-user-alc-id'] && document?.status !== 'agreement' && (
          <p className="approved_with">
            {t('approved_with')}
            {' '}
            {document?.['agreement-user-alc']?.['fio-short']}
          </p>
        )}
      </div>
      <div className={`pdf_iframe_wrapper ${!showPdf && 'non_visible'}`}>
        {noLetterheadError ? <div className="no_letterhead_wrapper"><p className="no_letterhead_text">{t('no_letterhead_error')}</p></div> : (
          <iframe className="pdf_iframe" ref={iframeRef} src="/pdf/index.html" title="Pdf Iframe" />
        )}
      </div>
    </div>

  );
}

export default PdfIframe;
