import {
  GET_MY_ALLOCATIONS_FAIL,
  GET_MY_ALLOCATIONS_REQUEST,
  GET_MY_ALLOCATIONS_SUCCESS,
  SET_MY_SCHEDULE_FAIL,
  SET_MY_SCHEDULE_REQUEST,
  SET_MY_SCHEDULE_SUCCESS,
  GET_PROFILE_SUCCESS,
  LOG_OUT,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  SET_IS_ADMIN,
  SET_IS_ROOT,
  SET_LANGUAGE,
  SET_TOKEN,
} from '../actions/account';
import LocalStorageServices from '../../helpers/LocalStorageServices';
import { ROLES } from '../../helpers/constants';

const initialState = {
  token: '',
  loginLoading: false,
  loginError: '',

  lang: 'en',
  profile: {},
  isRoot: false,
  isAdmin: false,
  role: '',

  myAllocations: [],
  gettingMyAllocationsLoading: false,
  gettingMyAllocationsError: '',

  settingMyScheduleLoading: false,
  settingMyScheduleError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return {
        ...state,
        loginLoading: true,
        loginError: '',
      };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        loginLoading: false,
        loginError: '',
      };
    }
    case LOGIN_FAIL: {
      return {
        ...state,
        loginError: action?.payload || 'login_error',
        loginLoading: false,
      };
    }

    case SET_LANGUAGE: {
      LocalStorageServices.setLang(action.payload);
      return {
        ...state,
        lang: action.payload,
      };
    }

    case SET_IS_ROOT: {
      return {
        ...state,
        isRoot: action.payload,
      };
    }

    case SET_IS_ADMIN: {
      return {
        ...state,
        isAdmin: action.payload,
      };
    }

    case GET_PROFILE_SUCCESS: {
      const role = action.payload.is_admin ? ROLES.admin
        : action.payload.is_client ? ROLES.client
          : action.payload.is_constructor ? ROLES.constructor
            : action.payload.is_stroymanager ? ROLES.stroymanager
              : action.payload.is_chief ? ROLES.chief : '';

      return {
        ...state,
        profile: action.payload,
        role,
      };
    }

    case GET_MY_ALLOCATIONS_REQUEST: {
      return {
        ...state,
        gettingMyAllocationsLoading: true,
        gettingMyAllocationsError: '',
      };
    }
    case GET_MY_ALLOCATIONS_SUCCESS: {
      return {
        ...state,
        myAllocations: action.payload,
        gettingMyAllocationsLoading: false,
        gettingMyAllocationsError: '',
      };
    }
    case GET_MY_ALLOCATIONS_FAIL: {
      return {
        ...state,
        myAllocations: [],
        gettingMyAllocationsLoading: false,
        gettingMyAllocationsError: action.payload,
      };
    }

    case SET_MY_SCHEDULE_REQUEST: {
      return {
        ...state,
        settingMyScheduleLoading: true,
        settingMyScheduleError: '',
      };
    }
    case SET_MY_SCHEDULE_SUCCESS: {
      return {
        ...state,
        settingMyScheduleLoading: false,
        settingMyScheduleError: '',
      };
    }
    case SET_MY_SCHEDULE_FAIL: {
      return {
        ...state,
        settingMyScheduleLoading: false,
        settingMyScheduleError: action.payload,
      };
    }

    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload,
        profile: !action.payload && {},
      };
    }

    case LOG_OUT: {
      return {
        ...initialState,
        lang: state.lang,
      };
    }

    default: {
      return state;
    }
  }
}
