import React from 'react';
import DrawingMarker from '../../../../../components/DrawingMarker';

const DrawingDetailsMarkers = ({
  imageSizes, singleDrawing, sizeCoefficient, isOnlyOpenViols,
}) => (
  imageSizes?.offsetWidth && singleDrawing?.viols
    ?.map((violItem, violIndex) => {
      const { point_left: pointLeft, point_top: pointTop, point_direction: pointDirection } = violItem;
      const { offsetWidth, offsetHeight } = imageSizes;

      const x = (pointLeft * offsetWidth) / 100;
      const y = (pointTop * offsetHeight) / 100;
      const angle = pointDirection;

      const dotCoordinates = { x, y, angle };

      return (isOnlyOpenViols
        && (violItem.flag_overdue || ['accept', 'accept-force', 'force-accept', 'canceled'].includes(violItem.predpisan_status))
        ? null
        : <DrawingMarker key={violIndex} coordinates={dotCoordinates} text={violIndex + 1} sizeCoefficient={sizeCoefficient} />
      );
    })
);

export default DrawingDetailsMarkers;
