import {
  DELETE_USER_REQUEST,
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS, SAVE_USER_FAIL, SAVE_USER_REQUEST, SAVE_USER_SUCCESS,
  SET_SINGLE_USER,
} from '../actions/users';
import { LOG_OUT } from '../actions/account';

const initialState = {
  users: {},
  usersLoading: false,
  usersError: '',

  singleUser: {},

  saveLoading: false,
  saveError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_REQUEST: {
      return {
        ...state,
        usersLoading: true,
        usersError: '',
        singleUser: {},
      };
    }
    case GET_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload,
        usersLoading: false,
        usersError: '',
      };
    }
    case GET_USERS_FAIL: {
      return {
        ...state,
        usersLoading: false,
        usersError: 'error',
      };
    }

    case SET_SINGLE_USER: {
      const singleUser = state.users?.items?.find((item) => item.id === +action.payload) || {};
      return {
        ...state,
        singleUser,
      };
    }

    case SAVE_USER_REQUEST: {
      return {
        ...state,
        saveLoading: true,
        saveError: '',
      };
    }

    case SAVE_USER_SUCCESS: {
      return {
        ...state,
        saveLoading: false,
        saveError: '',
      };
    }

    case SAVE_USER_FAIL: {
      return {
        ...state,
        saveLoading: false,
        saveError: action.payload?.error,
      };
    }

    case DELETE_USER_REQUEST: {
      return {
        ...state,
        usersLoading: true,
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
