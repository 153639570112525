import React, { useEffect, useMemo, useState } from 'react';
import '../SingleCheckup/css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useTranslation from '../../../translations';
import MyLoading from '../../../templates/MyLoading';
import { CHECKUP_STATUSES, COLORS, ROLES } from '../../../helpers/constants';
import Api from '../../../Api';
import useInputState from '../../../helpers/hooks/useInputState';
import MySelect from '../../../templates/MySelect';
import MyInput from '../../../templates/MyInput';
import MyButton from '../../../templates/MyButton';
import getFlatCategoriesListFromNested from '../../../helpers/getFlatCategoriesListFromNested';
import sortAndConvertChecklistCategories from '../../../helpers/sortAndConvertChecklistCategories';
import DeleteButton from '../../../components/DeleteButton';
import sleep from '../../../helpers/sleep';
import LangData from '../../../helpers/localData/langData';

function SingleCheckupRequest() {
  const dispatch = useDispatch();
  const { t, lang } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const checkupId = searchParams.get('checkup_id');

  const profile = useSelector((store) => store.account.profile);
  const role = useSelector((store) => store.account.role);

  const [comment, setComment, commentError, setCommentError] = useInputState();

  const [gettingLoading, setGettingLoading] = useState(true);
  const [singleCheckup, setSingleCheckup] = useState();
  const [slots, setSlots] = useState();
  const [finishingCheckup, setFinishingCheckup] = useState(false);

  const [templateParent, setTemplateParent, templateParentError, setTemplateParentError] = useInputState(0);
  const [nestedCategories, setNestedCategories] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [template, setTemplate, templateError, setTemplateError] = useInputState();

  const [date, setDate, dateError, setDateError] = useInputState();
  const [timeData, setTimeData] = useState();
  const [time, setTime, timeError, setTimeError] = useInputState();

  const [timeBefore, setTimeBefore] = useState('');
  const [timeAfter, setTimeAfter] = useState('');
  const [timeAfterError, setTimeAfterError] = useState(false);

  const [submittingLoading, setSubmittingLoading] = useState(false);
  const [deletingLoading, setDeletingLoading] = useState(false);

  const categoriesToDraw = useMemo(() => {
    if (nestedCategories?.length) {
      const flattenedCategories = getFlatCategoriesListFromNested({ nestedArr: nestedCategories });

      return [{ id: 0, title: t('root_directory'), titleAsTree: t('root_directory') }, ...flattenedCategories];
    }
    return [];
  }, [nestedCategories]);

  const handleCategory = (val) => {
    setTemplateParent(val, true);
    setTemplate(null, true);
  };

  const deleteCheckup = async () => {
    try {
      setDeletingLoading(true);
      const payload = {
        checkup_id: checkupId,
        status: CHECKUP_STATUSES.deleted,
      };

      const { data } = await Api.editCheckup(payload);

      if (data?.message === 'Success') {
        goBack();
      }
    } catch (e) {
      console.warn(e, 'while deleting checkup');
    } finally {
      setDeletingLoading(false);
    }
  };

  const saveCheckup = async () => {
    let flag = false;

    if (!template) {
      setTemplateError(t('template') + t('is_required_m_r'));
      flag = true;
    }
    /* if (!comment) {
      setCommentError(t('note_error'));
      flag = true;
    } */
    if (!date) {
      setDateError(t('date') + t('is_required_j_r'));
      flag = true;
    }
    if (!time) {
      setTimeError(t('time') + t('is_required_s_r'));
      flag = true;
    }
    if (flag) {
      return;
    }

    try {
      setSubmittingLoading(true);
      const payload = {
        checkup_id: checkupId,
        checklist_template_id: template,
        comment,
        appointment_date: `${date} ${time}`,
        status: CHECKUP_STATUSES.wait,
      };

      const { data } = await Api.editCheckup(payload);

      if (data?.message === 'Success') {
        goBack();
      }
    } catch (e) {
      console.warn(e, 'while submitting checkup');
    } finally {
      setSubmittingLoading(false);
    }
  };

  const goBack = () => {
    navigate('/checkups');
  };

  const handleDate = (val, slotsFromArg) => {
    setDate(val, true);
    setTime(null, true);
    setTimeAfterError(false);

    setTimeAfter('');
    setTimeBefore('');

    const currentDate = slotsFromArg
      ? slotsFromArg?.find((item) => item.date === val)
      : slots?.find((item) => item.date === val);

    const times = Object.keys(currentDate.slots).map((key) => ({
      id: key,
      title: (currentDate.slots[key] === true || currentDate.slots[key]?.id === +checkupId) ? key : `${key} ${currentDate.slots[key]?.labor}`,
      checkup_id: currentDate.slots[key]?.id,
    }));

    setTimeData(times);

    return times;
  };

  const handleTime = (val, timeDataFromArguments) => {
    setTime(val, true);

    const notEmptyList = timeDataFromArguments
      ? timeDataFromArguments?.filter((item) => (item.id !== item.title || item.id === val))
      : timeData?.filter((item) => (item.id !== item.title || item.id === val));

    const currentIndex = notEmptyList.findIndex((item) => item.id === val);

    if (currentIndex > 0) {
      setTimeBefore(notEmptyList[currentIndex - 1]?.title);
    } else {
      setTimeBefore(t('no_checkup2'));
    }

    if (notEmptyList[currentIndex]?.id !== notEmptyList[currentIndex]?.title && notEmptyList[currentIndex]?.checkup_id !== +checkupId) {
      setTimeAfter(notEmptyList[currentIndex]?.title);
      setTimeAfterError(true);
    } else if (currentIndex < notEmptyList.length - 1) {
      setTimeAfter(notEmptyList[currentIndex + 1]?.title);
      setTimeAfterError(false);
    } else {
      setTimeAfter(t('no_checkup2'));
      setTimeAfterError(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setGettingLoading(true);

        const { data: singleCheckupData } = await Api.getCheckup(checkupId);
        const { data: categoriesData } = await Api.getChecklistsCategories();
        const { data: templatesData } = await Api.getChecklistsTemplates();
        const { data: slotsData } = await Api.getMySlots();

        if (categoriesData?.length) {
          setNestedCategories(sortAndConvertChecklistCategories(categoriesData));
        }
        if (templatesData?.length) {
          setTemplates(templatesData);
        }
        if (slotsData?.length) {
          setSlots(slotsData);

          if (singleCheckupData) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            const [currentDate, currentTime] = singleCheckupData?.appointment_date?.split(' ');
            const dateVal = slotsData.find((item) => item.date === currentDate);

            if (dateVal) {
              const currentTimes = handleDate(dateVal.date, slotsData);
              handleTime(currentTime, currentTimes);
            }
          }
        }
        if (singleCheckupData) {
          setSingleCheckup(singleCheckupData);
          setComment(singleCheckupData.comment);
        }
      } catch (e) {
        console.warn(e, 'while getting checklist categories');
      } finally {
        setGettingLoading(false);
      }
    })();
  }, []);

  return (
    <div className="single_checkup_wrapper">
      <SinglePageHeader text={t('checkup_request')} />
      {gettingLoading ? <MyLoading color={COLORS.main} />
        : role !== ROLES.stroymanager ? <p className="no_checkup">{t('checkup_only_engineer')}</p>
          : singleCheckup?.engineer_fio !== profile?.fio ? <p className="no_checkup">{t('checkup_request_wrong_engineer')}</p>
            : ![CHECKUP_STATUSES.requested, CHECKUP_STATUSES.deleted].includes(singleCheckup?.status) ? <p className="no_checkup">{t('request_checkup_wrong_status')}</p>
              : !singleCheckup ? (
                <p className="no_checkup">
                  {t('no_checkup')}
                  {checkupId}
                </p>
              ) : (
                <div>
                  <div className="top_part">
                    <div className="selections_part no_height">
                      <div className="selection_part_item">
                        <p className="selection_title">ID:</p>
                        <p className="selection_value">{checkupId}</p>
                      </div>

                      <div className="selection_part_item">
                        <p className="selection_title">
                          {t('podryadchik')}
                          :
                        </p>
                        <p className="selection_value">{singleCheckup?.fio}</p>
                      </div>

                      <div className="selection_part_item">
                        <p className="selection_title">
                          {t('phone')}
                          :
                        </p>
                        <p className="selection_value">{singleCheckup?.phone}</p>
                      </div>

                      <div className="selection_part_item">
                        <p className="selection_title">
                          {t('what_to_appoint')}
                          :
                        </p>
                        <p className="selection_value">{singleCheckup?.request}</p>
                      </div>

                      <div className="selection_part_item">
                        <p className="selection_title">
                          {t('category')}
                          :
                        </p>
                        <MySelect
                          wrapperClassName="selection_value"
                          buttonText={t('choose_category')}
                          abilityToClear={false}
                          showKey="titleAsTree"
                          showKeyInInput="title"
                          onlyDropDown
                          data={categoriesToDraw}
                          value={templateParent}
                          onChange={handleCategory}
                          error={templateParentError}
                          t={t}
                        />
                      </div>

                      <div className="selection_part_item at_start_with_margin">
                        <p className="selection_title text_at_start_small">
                          {t('template')}
                          :
                        </p>
                        <MySelect
                          wrapperClassName="selection_value"
                          buttonText={t('choose_template')}
                          abilityToClear={false}
                          onlyDropDown
                          data={templates.filter((item) => item.catalog_id === templateParent && item.lang_id === LangData.find((i) => i.code === lang).id)}
                          value={template}
                          onChange={setTemplate}
                          error={templateError}
                          t={t}
                        />
                      </div>

                      <div className="selection_part_item at_start_with_margin">
                        <p className="selection_title text_at_start">
                          {t('note')}
                          :
                        </p>
                        <MyInput
                          textarea
                          value={comment}
                          onChange={setComment}
                          wrapperClassName="selection_value"
                          className="note_input"
                          error={commentError}
                        />
                      </div>

                      <div className="line" />

                      <div className="selection_part_item">
                        <p className="selection_title">
                          {t('before')}
                          :
                        </p>
                        <p className="selection_value">{timeBefore}</p>
                      </div>

                      <div className="selection_part_item at_start_with_margin">
                        <p className="selection_title text_at_start_small">
                          {t('date')}
                          :
                        </p>
                        <MySelect
                          wrapperClassName="selection_value"
                          buttonText={t('date')}
                          abilityToClear={false}
                          idToNum={false}
                          onlyDropDown
                          data={slots?.map((item) => ({
                            id: item.date,
                            title: moment(item.date, 'DD.MM.YYYY').format('DD MMM'),
                          }))}
                          value={date}
                          onChange={handleDate}
                          error={dateError}
                          t={t}
                        />
                      </div>

                      <div className="selection_part_item at_start_with_margin">
                        <p className="selection_title text_at_start_small">
                          {t('time')}
                          :
                        </p>
                        <MySelect
                          wrapperClassName="selection_value"
                          buttonText={t('time')}
                          showKeyInInput="id"
                          abilityToClear={false}
                          idToNum={false}
                          maxHeight={200}
                          onlyDropDown
                          data={timeData}
                          value={time}
                          onChange={handleTime}
                          error={timeError}
                          t={t}
                        />
                      </div>

                      <div className="selection_part_item">
                        <p className="selection_title">
                          {t('after2')}
                          :
                        </p>
                        <p className={`selection_value ${timeAfterError && 'selection_value_error'}`}>
                          {timeAfter}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="checkup_request_buttons_row">
                    <MyButton loading={submittingLoading} disabled={deletingLoading} className="submit_checkup" text={t('submit')} onClick={saveCheckup} />
                    <DeleteButton text={t('delete')} onClick={deleteCheckup} loading={deletingLoading} disabled={submittingLoading} />
                  </div>

                </div>
              )}
    </div>
  );
}

export default SingleCheckupRequest;
