import React from 'react';
import './css/index.scss';
import MyButton from '../../templates/MyButton';

function AddButton({ text, onClick, ...p }) {
  return (
    <MyButton onClick={onClick} text={text} iconUri="/images/plus.png" iconClassName="add_button_icon" {...p} />
  );
}

export default AddButton;
