import React from 'react';
import EditButton from '../../../../components/EditButton';
import DeleteButton from '../../../../components/DeleteButton';

function CompanyItem(item, index, onItemEdit, showDeleteModal, t, loading, isAdmin) {
  return item && (
    <div key={item.id} className="company_item">
      <div className="company_left">
        <img src="/images/companies_img.png" alt="img" className="company_img" />
        <div className="company_info_div">
          <p className="company_title">{item.title}</p>
          <p className="company_desc">{item.descr}</p>
          <p className="company_info">{item.address}</p>
          <p className="company_info">{item['legal-data']}</p>
          <p className="company_info">{item.chief}</p>
          <p className="company_info">{item.chief_role}</p>
        </div>
      </div>
      {!loading && isAdmin && (
        <div className="row">
          <EditButton onClick={() => onItemEdit(item)} />
          <DeleteButton onClick={() => showDeleteModal(item)} />
        </div>
      )}
    </div>
  );
}

export default CompanyItem;
