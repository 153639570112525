import React from 'react';
import EditButton from '../../../../components/EditButton';
import DeleteButton from '../../../../components/DeleteButton';

function LetterheadItem(item, index, onItemEdit, showDeleteModal, t, loading) {
  return item && (
    <div key={item.id} className="letterhead_item">
      <div className="letterhead_left">
        <img src="/images/letterheads.png" alt="img" className="letterhead_img" />
        <div className="letterhead_info_div">
          <p className="letterhead_title">{item.title}</p>
          <p className="letterhead_desc">{item.descr}</p>
          <p className="letterhead_address">{item.address}</p>
          <p className="letterhead_info">{item['legal-name']}</p>
          <p className="letterhead_info">{`${t('inn')}: ${item['legal-inn'] || ''}`}</p>
          <p className="letterhead_info">{`${t('kpp')}: ${item['legal-kpp'] || ''}`}</p>
          <p className="letterhead_info">{`${t('ogrn')}: ${item['legal-ogrn'] || ''}`}</p>
          <p className="letterhead_info">{`${t('bill')}: ${item['legal-bill'] || ''}`}</p>
          <p className="letterhead_info">{`${t('contacts')}: ${item.contacts || ''}`}</p>
          <p className="letterhead_info">{`${t('footer')}: ${item.footer || ''}`}</p>
        </div>
      </div>
      {!loading && (
        <div className="row">
          <EditButton onClick={() => onItemEdit(item)} />
          <DeleteButton onClick={() => showDeleteModal(item)} />
        </div>
      )}
    </div>
  );
}

export default LetterheadItem;
