import {
  DELETE_OBJECT_REQUEST,
  GET_OBJECTS_FAIL,
  GET_OBJECTS_REQUEST,
  GET_OBJECTS_SUCCESS, SAVE_OBJECT_FAIL, SAVE_OBJECT_REQUEST, SAVE_OBJECT_SUCCESS,
  SET_SINGLE_OBJECT,
} from '../actions/objects';
import { LOG_OUT } from '../actions/account';

const initialState = {
  objects: {},
  objectsLoading: false,
  objectsError: '',

  singleObject: {},

  saveLoading: false,
  saveError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_OBJECTS_REQUEST: {
      return {
        ...state,
        objectsLoading: true,
        objectsError: '',
        singleObject: {},
      };
    }
    case GET_OBJECTS_SUCCESS: {
      return {
        ...state,
        objects: action.payload,
        objectsLoading: false,
        objectsError: '',
      };
    }
    case GET_OBJECTS_FAIL: {
      return {
        ...state,
        objectsLoading: false,
        objectsError: 'error',
      };
    }

    case SET_SINGLE_OBJECT: {
      const singleObject = state.objects?.items?.find((item) => item.id === +action.payload) || {};
      return {
        ...state,
        singleObject,
      };
    }

    case SAVE_OBJECT_REQUEST: {
      return {
        ...state,
        saveLoading: true,
        saveError: '',
      };
    }

    case SAVE_OBJECT_SUCCESS: {
      return {
        ...state,
        saveLoading: false,
        saveError: '',
      };
    }

    case SAVE_OBJECT_FAIL: {
      return {
        ...state,
        saveLoading: false,
        saveError: 'error',
      };
    }

    case DELETE_OBJECT_REQUEST: {
      return {
        ...state,
        objectsLoading: true,
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
