import {
  DELETE_ALLOC_REQUEST,
  GET_ALLOCS_FAIL,
  GET_ALLOCS_REQUEST,
  GET_ALLOCS_SUCCESS, SAVE_ALLOC_FAIL, SAVE_ALLOC_REQUEST, SAVE_ALLOC_SUCCESS,
  SET_SINGLE_ALLOC,
} from '../actions/allocs';
import { LOG_OUT } from '../actions/account';

const initialState = {
  allocs: {},
  allocsLoading: false,
  allocsError: '',

  singleAlloc: {},

  saveLoading: false,
  saveError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALLOCS_REQUEST: {
      return {
        ...state,
        allocsLoading: true,
        allocsError: '',
        singleAlloc: {},
      };
    }
    case GET_ALLOCS_SUCCESS: {
      return {
        ...state,
        allocs: action.payload,
        allocsLoading: false,
        allocsError: '',
      };
    }
    case GET_ALLOCS_FAIL: {
      return {
        ...state,
        allocsLoading: false,
        allocsError: 'error',
      };
    }

    case SET_SINGLE_ALLOC: {
      const singleAlloc = state.allocs?.items?.find((item) => item.id === +action.payload) || {};
      return {
        ...state,
        singleAlloc,
      };
    }

    case SAVE_ALLOC_REQUEST: {
      return {
        ...state,
        saveLoading: true,
        saveError: '',
      };
    }

    case SAVE_ALLOC_SUCCESS: {
      return {
        ...state,
        saveLoading: false,
        saveError: '',
      };
    }

    case SAVE_ALLOC_FAIL: {
      return {
        ...state,
        saveLoading: false,
        saveError: 'error',
      };
    }

    case DELETE_ALLOC_REQUEST: {
      return {
        ...state,
        allocsLoading: true,
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
