// the format is 4:00

export default (time) => {
  if (typeof time !== 'string' || !time.includes(':')) return;

  const [hours, minutes] = time.split(':');

  const newHours = +hours + 1 >= 24 ? '00' : +hours + 1;

  return `${newHours}:${minutes}`;
};
