import React from 'react';
import '../css/index.scss';
import { t } from '../../../../translations';

function HeaderRow() {
  return (
    <div className="header_row">
      <p className="item first">{t('date')}</p>
      <p className="item second">{t('project')}</p>
      <p className="item third">{t('worker')}</p>
      <p className="item fourth">{t('type')}</p>
      <p className="item fifth">{t('number')}</p>
      <p className="item sixth">{t('status')}</p>
    </div>
  );
}

export default HeaderRow;
