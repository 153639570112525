import React from 'react';
import './css/index.scss';

function MyCheckbox({
  value = false, onChange, disabled, className, label, rightLabel, labelClassName = '',
}) {
  const changeVal = () => {
    if (disabled) return;

    onChange((prev) => !prev);
  };

  return (
    <div
      className={`${className} checkbox_wrapper ${rightLabel && 'with_right_label'} ${disabled && 'disabled'}`}
      onClick={changeVal}
    >
      <div className="left_part">
        <div className={`box ${disabled && 'disabled'}`}>
          {value && <img className="tick" src={disabled ? '/images/tick_disabled.png' : '/images/tick.png'} alt="tick" />}
        </div>
        <p className={`label ${labelClassName}`}>{label}</p>
      </div>
      <p className={`label ${labelClassName}`}>{rightLabel}</p>
    </div>
  );
}

export default MyCheckbox;
