import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  DELETE_COMPANY_FAIL,
  DELETE_COMPANY_REQUEST,
  GET_COMPANIES_FAIL,
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS, SAVE_COMPANY_FAIL,
  SAVE_COMPANY_REQUEST, SAVE_COMPANY_SUCCESS,
} from '../actions/companies';

export default function* watcher() {
  yield takeLatest(GET_COMPANIES_REQUEST, getCompanies);
  yield takeLatest(SAVE_COMPANY_REQUEST, saveCompany);
  yield takeLatest(DELETE_COMPANY_REQUEST, deleteCompany);
}

function* getCompanies(action) {
  try {
    const { data } = yield call(Api.getCompanies, action.payload);

    yield put({
      type: GET_COMPANIES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting companies');
    console.log(e?.response?.data);
    yield put({
      type: GET_COMPANIES_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* saveCompany(action) {
  try {
    let data;
    if (action.payload.save) {
      const res = yield call(Api.addCompany, action.payload.data);
      data = res.data;
    } else {
      const res = yield call(Api.editCompany, action.payload.data);
      data = res.data;
    }

    yield put({
      type: SAVE_COMPANY_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while saving company');
    console.log(e?.response?.data);
    yield put({
      type: SAVE_COMPANY_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* deleteCompany(action) {
  try {
    const { id, requestData } = action.payload;
    yield call(Api.deleteCompany, id);
    yield put({
      type: GET_COMPANIES_REQUEST,
      payload: requestData,
    });
  } catch (e) {
    console.log(e, 'while deleting company');
    console.log(e?.response?.data);
    yield put({
      type: DELETE_COMPANY_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
