import axios from 'axios';
import LocalStorageServices from './helpers/LocalStorageServices';
import { SERVER_URL } from './serverUrl';

const api = axios.create({
  baseURL: SERVER_URL,
  timeout: 10 * 60 * 1000, // 10 minutes
});

api.interceptors.request.use(
  (params) => {
    const token = LocalStorageServices.getToken();
    if (token) {
      params.headers.Authorization = `Bearer ${token}`;
    }

    /* if (!params.url.startsWith(`${SERVER_URL}/api/draws/img`)
      && !params.url.startsWith(`${SERVER_URL}/api/draws/thumb`)) {
      console.log(params.method?.toUpperCase(), params.url);
    } */
    return params;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (res) => res,
  async (error) => {
    if (error?.response?.status === 401) {
      LocalStorageServices.removeToken();
    }
    return Promise.reject(error);
  },
);

class Api {
  static url = SERVER_URL;

  static drawings_img_url = `${this.url}/api/draws/img/`;

  static drawings_thumb_url = `${this.url}/api/draws/thumb/`;

  static drawings_thumb_url_no_request = `${this.url}/api/draws/thumb/26d26ef8-4d7b-4706-b9ed-2bff1d50043a/`;

  static drawings_url_no_request = `${this.url}/api/draws/img/26d26ef8-4d7b-4706-b9ed-2bff1d50043a/`;

  static viols_img_url_no_request = `${this.url}/api/image/26d26ef8-4d7b-4706-b9ed-2bff1d50043a/`;

  static getRequest(src, config) {
    return api.get(src, config);
  }

  /* Account start */

  static login(data) {
    return api.post('/api/auth/login', data);
  }

  static getProfile() {
    return api.post('/api/user/profile');
  }

  static getEvents(data) {
    return api.post('/api/doc-history/get', data);
  }

  static getRoles(data) {
    return api.post('/api/user-types/get', data);
  }

  static getMyAllocations() {
    return api.get('/api/profile/projects');
  }

  static setMySchedule(data) {
    return api.put('/api/profile/schedule', data);
  }

  static getMySlots() {
    return api.get('/api/checkups/user/slots');
  }

  /* Account end */

  /* Prescriptions start */

  static createPrescription(data) {
    return api.post('/api/predpisans/draft', data);
  }

  static getSinglePrescription(data) {
    return api.post('/api/predpisans/item', data);
  }

  static getPrescriptions(data) {
    return api.post('/api/predpisans/get', data);
  }

  static savePrescription(data) {
    return api.post('/api/predpisans/edit', data);
  }

  static makePrescriptionActive(data) {
    return api.post('/api/predpisans/active', data);
  }

  static setPrescriptionStatus(data) {
    return api.post('/api/predpisans/set-status', data);
  }

  static repeatPrescription(data) {
    return api.post('/api/predpisans/repeat', data);
  }

  static savePrescriptionToNextCloud(data) {
    return api.post('/api/predpisans/print', data);
  }

  /* Prescriptions end */

  /* Acts start */

  static createAct(data) {
    return api.post('/api/doc-acts/draft', data);
  }

  static getSingleAct(data) {
    return api.post('/api/doc-acts/item', data);
  }

  static getActs(data) {
    return api.post('/api/doc-acts/get', data);
  }

  static saveAct(data) {
    return api.post('/api/doc-acts/edit', data);
  }

  static makeActAccept(data) {
    return api.post('/api/doc-acts/accept', data);
  }

  static setActStatus(data) {
    return api.post('/api/doc-acts/set-status', data);
  }

  static saveActToNextCloud(data) {
    return api.post('/api/doc-acts/print', data);
  }

  /* Acts end */

  /* Companies start */

  static getCompanies(data) {
    return api.post('/api/comps/get', data);
  }

  static editCompany(data) {
    return api.post('/api/comps/edit', data);
  }

  static addCompany(data) {
    return api.post('/api/comps/add', data);
  }

  static deleteCompany(id) {
    return api.delete(`/api/comps/delete/${id}`);
  }

  /* Companies end */

  /* Letterheads start */

  static getLetterheads(data) {
    return api.post('/api/stroym-comps/get', data);
  }

  static editLetterhead(data) {
    return api.post('/api/stroym-comps/edit', data);
  }

  static addLetterhead(data) {
    return api.post('/api/stroym-comps/add', data);
  }

  static deleteLetterhead(id) {
    return api.delete(`/api/stroym-comps/delete/${id}`);
  }

  /* Letterheads end */

  /* Users start */

  static getUsers(data) {
    return api.post('/api/users/get', data);
  }

  static editUser(data) {
    return api.post('/api/users/edit', data);
  }

  static addUser(data) {
    return api.post('/api/users/add', data);
  }

  static deleteUser(id) {
    return api.delete(`/api/users/delete/${id}`);
  }

  /* Users end */

  /* Projects start */

  static getProjects(data) {
    return api.post('/api/projects/get', data);
  }

  static editProject(data) {
    return api.post('/api/projects/edit', data);
  }

  static addProject(data) {
    return api.post('/api/projects/add', data);
  }

  static deleteProject(id) {
    return api.delete(`/api/projects/delete/${id}`);
  }

  /* Projects end */

  /* Objects start */

  static getObjects(data) {
    return api.post('/api/objects/get', data);
  }

  static editObject(data) {
    return api.post('/api/objects/edit', data);
  }

  static addObject(data) {
    return api.post('/api/objects/add', data);
  }

  static deleteObject(id) {
    return api.delete(`/api/objects/delete/${id}`);
  }

  /* Objects end */

  /* Alloc start */

  static getAllocs(data) {
    return api.post('/api/alloc-users/get', data);
  }

  static editAlloc(data) {
    return api.post('/api/alloc-users/edit', data);
  }

  static addAlloc(data) {
    return api.post('/api/alloc-users/add', data);
  }

  static deleteAlloc(id) {
    return api.delete(`/api/alloc-users/delete/${id}`);
  }

  static sendEmails(data) {
    return api.post('/api/tasks-email/adds', data);
  }

  /* Alloc end */

  /* Configuration start */

  static getConfigurations(data) {
    return api.post('/api/configuration/get', data);
  }

  static editConfiguration(data) {
    return api.post('/api/configuration/edit', data);
  }

  static addConfiguration(data) {
    return api.post('/api/configuration/add', data);
  }

  static deleteConfiguration(id) {
    return api.delete(`/api/configuration/delete/${id}`);
  }

  /* Configuration end */

  /* Clients start */

  static getClients(data) {
    return api.post('/api/clients/get', data);
  }

  static editClient(data) {
    return api.post('/api/clients/edit', data);
  }

  static addClient(data) {
    return api.post('/api/clients/add', data);
  }

  static deleteClient(id) {
    return api.delete(`/api/clients/delete/${id}`);
  }

  /* Clients end */

  /* Checklist categories start */

  static getChecklistsCategories(data) {
    return api.get('/api/checklists/categories', data);
  }

  static addChecklistCategory(data) {
    return api.post('/api/checklists/categories', data);
  }

  static editChecklistCategory(data) {
    return api.put('/api/checklists/categories', data);
  }

  static deleteChecklistCategory(data) {
    return api.delete('/api/checklists/categories', { data });
  }

  /* Checklists categories end */

  /* Checklists templates start */

  static getChecklistsTemplates(data) {
    return api.get('/api/checklists/template', data);
  }

  static getChecklistsSingleTemplate(id) {
    return api.get(`/api/checklists/template/${id}`);
  }

  static addChecklistTemplate(data) {
    return api.post('/api/checklists/template', data);
  }

  static editChecklistTemplate(data) {
    return api.put('/api/checklists/template', data);
  }

  static deleteChecklistTemplate(data) {
    return api.delete('/api/checklists/template', { data });
  }

  static getChecklistTools(data) {
    return api.get('/api/checklists/tools', data);
  }

  /* Checklists templates end */

  /* Checklists templates items start */

  static addCheckListTemplateItem(data) {
    return api.post('/api/checklists/template/item', data);
  }

  static editCheckListTemplateItem(data) {
    return api.put('/api/checklists/template/item', data);
  }

  static deleteCheckListTemplateItem(data) {
    return api.delete('/api/checklists/template/item', { data });
  }

  /* Checklists templates items end */

  /* Checkups start */

  static createCheckup(data) {
    return api.post('/api/checkups', data);
  }

  static getAllCheckups(data) {
    return api.get('/api/checkups/all', {
      params: data,
    });
  }

  static getCheckup(id) {
    return api.get(`/api/checkups/${id}`);
  }

  static editCheckupMark(data) {
    return api.put('/api/checkups/mark', data);
  }

  static editCheckup(data) {
    return api.put('/api/checkups', data);
  }

  /* Checkups end */

  /* Drawings start */

  static getAllDrawings(data) {
    return api.get('/api/draws/all', {
      params: data,
    });
  }

  static createDrawings(data) {
    return api.post('/api/draws', data);
  }

  static editDrawing(data) {
    return api.put('/api/draws', data);
  }

  static deleteDrawing(id) {
    return api.delete(`/api/draws/${id}`);
  }

  static getDrawing(id) {
    return api.get(`/api/draws/${id}`);
  }

  static getDrawingDetails(id) {
    return api.get(`/api/draws/details/${id}`);
  }

  /* Drawings end */

  /* Appointment start */

  static getAppointment(data) {
    return api.get('/api/appointment', {
      params: data,
    });
  }

  static refreshAppointment(data) {
    return api.get('/api/appointment/status', {
      params: data,
    });
  }

  static createAppointment(data) {
    return api.post('/api/appointment', data);
  }

  static deleteAppointment(id, syncUuid) {
    return api.delete(`/api/appointment?id=${id}&sync_uuid=${syncUuid}`);
  }

  /* Appointment end */
}

export default Api;
