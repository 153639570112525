export default [
  {
    id: 1,
    code: 'ru',
    name: 'russian',
  },
  {
    id: 2,
    code: 'en',
    name: 'english',
  },
];
