export const getItemsActsNumbers = (items, onClick) => {
  if (items?.length) {
    const numbersList = [];
    const objectList = [];
    items.forEach((item) => {
      if (item['act-number-out'] && !numbersList.includes(item['act-number-out'])) {
        numbersList.push(item['act-number-out']);
        objectList.push(item);
      }
    });
    return numbersList.map((item, index) => (
      <div key={index}>
        <p className="act_number" onClick={(ev) => onClick(ev, objectList[index])}>{`№ ${item}`}</p>
      </div>
    ));
  }
};
