import {
  DELETE_LETTERHEAD_REQUEST,
  GET_LETTERHEADS_FAIL,
  GET_LETTERHEADS_REQUEST,
  GET_LETTERHEADS_SUCCESS, SAVE_LETTERHEAD_FAIL, SAVE_LETTERHEAD_REQUEST, SAVE_LETTERHEAD_SUCCESS,
  SET_SINGLE_LETTERHEAD,
} from '../actions/letterheads';
import { LOG_OUT } from '../actions/account';

const initialState = {
  letterheads: {},
  letterheadsLoading: false,
  letterheadsError: '',

  singleLetterhead: {},

  saveLoading: false,
  saveError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_LETTERHEADS_REQUEST: {
      return {
        ...state,
        letterheadsLoading: true,
        letterheadsError: '',
        singleLetterhead: {},
      };
    }
    case GET_LETTERHEADS_SUCCESS: {
      return {
        ...state,
        letterheads: action.payload,
        letterheadsLoading: false,
        letterheadsError: '',
      };
    }
    case GET_LETTERHEADS_FAIL: {
      return {
        ...state,
        letterheadsLoading: false,
        letterheadsError: 'error',
      };
    }

    case SET_SINGLE_LETTERHEAD: {
      const singleLetterhead = state.letterheads?.items?.find((item) => item.id === +action.payload) || {};
      return {
        ...state,
        singleLetterhead,
      };
    }

    case SAVE_LETTERHEAD_REQUEST: {
      return {
        ...state,
        saveLoading: true,
        saveError: '',
      };
    }

    case SAVE_LETTERHEAD_SUCCESS: {
      return {
        ...state,
        saveLoading: false,
        saveError: '',
      };
    }

    case SAVE_LETTERHEAD_FAIL: {
      return {
        ...state,
        saveLoading: false,
        saveError: 'error',
      };
    }

    case DELETE_LETTERHEAD_REQUEST: {
      return {
        ...state,
        letterheadsLoading: true,
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
