import LocalStorageServices from './LocalStorageServices';
import LangData from './localData/langData';

export const sortCategories = (arr) => arr.sort((prev, next) => {
  if (prev.parent_id < next.parent_id) {
    return -1;
  }
  if (prev.parent_id > next.parent_id) {
    return 1;
  }
  return prev.sort_number > next.sort_number ? 1 : -1;
});

export const sortTemplates = (arr, interfaceLangId) => {
  const lang = LocalStorageServices.getLang();
  const langId = LangData.find((item) => item.code === lang).id;
  return arr.filter((item) => item.lang_id === langId)
    .sort((prev, next) => {
      if (prev.catalog_id < next.catalog_id) {
        return -1;
      }
      if (prev.catalog_id > next.catalog_id) {
        return 1;
      }
      return prev.title > next.title ? 1 : -1;
    });
};

const convertCategories = (sortedArr) => {
  const nestedArr = [];

  sortedArr.forEach((item) => {
    if (item.parent_id === 0 || item.catalog_id === 0) {
      nestedArr.push(item);
    } else {
      const parentNode = sortedArr.find((el) => el.id === item.parent_id);
      if (parentNode) {
        if (parentNode.children) {
          parentNode.children.push(item);
        } else {
          parentNode.children = [item];
        }
      }
    }
  });

  return nestedArr;
};

export default (categories, templates) => {
  const sortedCategories = sortCategories(categories);

  if (templates?.length) {
    sortTemplates(templates).forEach((item) => {
      if (item.catalog_id === 0) {
        sortedCategories.push(item);
        return;
      }
      const parentNode = sortedCategories.find((el) => el.id === item.catalog_id);
      if (parentNode) {
        if (parentNode.children) {
          parentNode.children.push(item);
        } else {
          parentNode.children = [item];
        }
      }
    });
  }

  return convertCategories(sortedCategories);
};
