import React, { useEffect } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useTranslation from '../../../translations';
import {
  getChecklistsCategoriesAndTemplatesRequest,
} from '../../../store/actions/checklists';
import HeaderRightButtons from './components/HeaderRightButtons';
import ChecklistsTree from './components/ChecklistsTree';
import MyButton from '../../../templates/MyButton';
import { getMyAllocationsRequest } from '../../../store/actions/account';
import MyLoading from '../../../templates/MyLoading';
import { COLORS } from '../../../helpers/constants';

function Checklists() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isAdmin = useSelector((store) => store.account.isAdmin);

  const convertedChecklists = useSelector((store) => store.checklists.convertedChecklists);
  const gettingLoading = useSelector((store) => store.checklists.gettingChecklistsCategoriesAndTemplatesLoading);
  const gettingError = useSelector((store) => store.checklists.gettingChecklistsCategoriesAndTemplatesError);

  const addCategory = () => {
    navigate('category/add');
  };

  const addChecklist = () => {
    navigate('template/add');
  };

  const requestData = () => {
    dispatch(getChecklistsCategoriesAndTemplatesRequest());
  };

  useEffect(() => {
    requestData();
    dispatch(getMyAllocationsRequest());
  }, []);

  return (
    <div className="checklists_wrapper">
      <SinglePageHeader
        text={t('checklists_header')}
        withGoBack={false}
        rightComponent={
          isAdmin && <HeaderRightButtons addChecklist={addChecklist} addCategory={addCategory} t={t} />
        }
        leftComponent={
          <MyButton onClick={requestData} className="refresh_button" iconClassName="refresh" iconUri="/images/refresh.png" loading={gettingLoading} loadingEqualsDisabled={false} />
        }
      />
      {gettingLoading ? <MyLoading color={COLORS.main} /> : !!convertedChecklists?.length && <ChecklistsTree />}
    </div>
  );
}

export default Checklists;
