export const GET_CHECKLISTS_CATEGORIES_REQUEST = 'GET_CHECKLISTS_CATEGORIES_REQUEST';
export const GET_CHECKLISTS_CATEGORIES_SUCCESS = 'GET_CHECKLISTS_CATEGORIES_SUCCESS';
export const GET_CHECKLISTS_CATEGORIES_FAIL = 'GET_CHECKLISTS_CATEGORIES_FAIL';
export function getChecklistsCategoriesRequest(data) {
  return {
    type: GET_CHECKLISTS_CATEGORIES_REQUEST,
    payload: data,
  };
}

export const GET_CHECKLISTS_TEMPLATES_REQUEST = 'GET_CHECKLISTS_TEMPLATES_REQUEST';
export const GET_CHECKLISTS_TEMPLATES_SUCCESS = 'GET_CHECKLISTS_TEMPLATES_SUCCESS';
export const GET_CHECKLISTS_TEMPLATES_FAIL = 'GET_CHECKLISTS_TEMPLATES_FAIL';
export function getChecklistsTemplatesRequest(data) {
  return {
    type: GET_CHECKLISTS_TEMPLATES_REQUEST,
    payload: data,
  };
}

export const GET_CHECKLISTS_SINGLE_TEMPLATE_REQUEST = 'GET_CHECKLISTS_SINGLE_TEMPLATE_REQUEST';
export const GET_CHECKLISTS_SINGLE_TEMPLATE_SUCCESS = 'GET_CHECKLISTS_SINGLE_TEMPLATE_SUCCESS';
export const GET_CHECKLISTS_SINGLE_TEMPLATE_FAIL = 'GET_CHECKLISTS_SINGLE_TEMPLATE_FAIL';
export function getChecklistsSingleTemplateRequest(data) {
  return {
    type: GET_CHECKLISTS_SINGLE_TEMPLATE_REQUEST,
    payload: data,
  };
}

export const GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_REQUEST = 'GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_REQUEST';
export const GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_SUCCESS = 'GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_SUCCESS';
export const GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_FAIL = 'GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_FAIL';
export function getChecklistsCategoriesAndTemplatesRequest(data) {
  return {
    type: GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_REQUEST,
    payload: data,
  };
}

export const GET_CHECKLISTS_TOOLS_REQUEST = 'GET_CHECKLISTS_TOOLS_REQUEST';
export const GET_CHECKLISTS_TOOLS_SUCCESS = 'GET_CHECKLISTS_TOOLS_SUCCESS';
export const GET_CHECKLISTS_TOOLS_FAIL = 'GET_CHECKLISTS_TOOLS_FAIL';
export function getChecklistTools(data) {
  return {
    type: GET_CHECKLISTS_TOOLS_REQUEST,
    payload: data,
  };
}

export const SET_CONVERTED_CHECKLISTS = 'SET_CONVERTED_CHECKLISTS';
export function setConvertedChecklists(data) {
  return {
    type: SET_CONVERTED_CHECKLISTS,
    payload: data,
  };
}
