import React, { useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import useInputState from '../../../helpers/hooks/useInputState';
import MyInput from '../../../templates/MyInput';
import MyButton from '../../../templates/MyButton';
import useTranslation from '../../../translations';
import LanguageSelector from '../../../components/LanguageSelector';
import { loginRequest } from '../../../store/actions/account';

function Login() {
  const dispatch = useDispatch();
  const [login, setLogin] = useInputState();
  const [password, setPassword] = useInputState();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { t, lang, setLanguage } = useTranslation();

  const loginLoading = useSelector((store) => store.account.loginLoading);
  const loginError = useSelector((store) => store.account.loginError);

  const onSubmit = (ev) => {
    ev.preventDefault();
    dispatch(loginRequest({ login, password }));
  };

  const onEyeClick = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <div className="login_wrapper">
      <img src="/images/loginBackground.png" alt="bg" className="background" />
      <img src="/images/logoWhite.png" alt="logo" className="logo" />
      <div className="registration_form_div">
        <LanguageSelector lang={lang} setLanguage={setLanguage} />
        <form onSubmit={onSubmit}>
          <MyInput
            label={t('email')}
            placeholder={`${t('eg')} user@mail.ru`}
            type="email"
            id="email"
            value={login}
            onChange={setLogin}
          />
          <div className="input_row">
            <MyInput
              label={t('password')}
              placeholder="•••••••••"
              type={isPasswordVisible ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={setPassword}
              autoComplete="on"
              wrapperClassName="password_input"
            />
            <MyButton
              iconUri={isPasswordVisible ? '/images/see.png' : '/images/unsee.png'}
              onClick={onEyeClick}
              className="password_eye_button"
            />
          </div>
          <MyButton
            loading={loginLoading}
            onClick={onSubmit}
            text={t('login')}
            className="login_button"
          />
          <MyInput
            type="submit"
            style={{ display: 'none' }}
          />
        </form>
        {loginError && (
          <div className="error_div">
            <p className="error_text">{t(loginError)}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
