import React from 'react';
import EditButton from '../../../../components/EditButton';
import DeleteButton from '../../../../components/DeleteButton';
import getRoleName from '../../../../helpers/getRoleName';

function ClientItem(item, index, onItemEdit, showDeleteModal, t, loading) {
  return item && (
    <div key={item.id} className="client_item">
      <div className="client_left">
        <img src="/images/projects.png" alt="img" className="client_img" />
        <div className="client_info_div">
          <p className="client_title">{item.title}</p>
          <p className="client_desc">{item.descr}</p>
          <p className="client_info">{item.enabled.toString()}</p>
          <p className="client_info">{item['expiration-date']}</p>
          <p className="client_info">{item.tariff}</p>
          <p className="client_info">{item['users-limit']}</p>
          <p className="client_info">{item['is-treal'].toString()}</p>
        </div>
      </div>
      {!item.is_admin && !loading && (
        <div className="row">
          <EditButton onClick={() => onItemEdit(item)} />
          <DeleteButton onClick={() => showDeleteModal(item)} />
        </div>
      )}
    </div>
  );
}

export default ClientItem;
