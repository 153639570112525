import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useInputState from '../../../helpers/hooks/useInputState';
import MyInput from '../../../templates/MyInput';
import MyButton from '../../../templates/MyButton';
import CancelButton from '../../../components/CancelButton';
import {
  getProjectsRequest, saveProjectRequest,
  setSingleProject,
} from '../../../store/actions/projects';
import MySelect from '../../../templates/MySelect';
import SelectedLetterheadItem from './components/SelectedLetterheadItem';
import { getLetterheadsRequest } from '../../../store/actions/letterheads';
import requestAllData from '../../../helpers/requestAllData';

function SingleProject() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('project_id');

  const projects = useSelector((store) => store.projects.projects);
  const singleProject = useSelector((store) => store.projects.singleProject);
  const saveLoading = useSelector((store) => store.projects.saveLoading);
  const saveError = useSelector((store) => store.projects.saveError);

  const letterheads = useSelector((store) => store.letterheads.letterheads);
  const user = useSelector((store) => store.account.profile);

  const [title, setTitle, titleError, setTitleError] = useInputState();
  const [desc, setDesc, descError, setDescError] = useInputState();
  const [letterhead, setLetterhead, letterheadError, setLetterheadError] = useInputState();

  const [toSave, setToSave] = useState(false);

  useEffect(() => {
    if (!letterheads?.items) {
      dispatch(getLetterheadsRequest(requestAllData));
    }
    if (!projectId) {
      return;
    }
    if (!projects?.items) {
      dispatch(getProjectsRequest(requestAllData));
    }
  }, []);

  useEffect(() => {
    if (projectId && projects?.items) {
      dispatch(setSingleProject(projectId));
    }
  }, [projects]);

  useEffect(() => {
    if (projectId && singleProject?.id) {
      setTitle(singleProject?.title);
      setDesc(singleProject?.descr);
      setLetterhead(singleProject && singleProject['stroymanager-comp-id']);
    }
  }, [singleProject]);

  useEffect(() => {
    if (toSave && !saveLoading) {
      if (!saveError) {
        goBack();
      } else {
        setToSave(false);
      }
    }
  }, [saveLoading]);

  const goBack = () => {
    navigate(-1, { replace: true });
  };

  const onSave = () => {
    let flag = false;
    if (!title) {
      setTitleError(t('title') + t('is_required_s_r'));
      flag = true;
    }

    if (!desc) {
      setDescError(t('description') + t('is_required_s_r'));
      flag = true;
    }
    if (!letterhead) {
      setLetterheadError(t('letterhead') + t('is_required_m_r'));
      flag = true;
    }

    if (flag) {
      return;
    }

    const data = {
      'act-last-number': null,
      descr: desc,
      'legal-data': null,
      'object-card-file': null,
      'predpisanie-last-number': null,
      'stroymanager-comp-id': letterhead,
      title,
      'user-eng-id': user?.uid,
    };

    if (projectId) {
      data.id = +projectId;
      delete data['client-id'];
      delete data['group-tag-name'];
    }

    if (projectId) {
      dispatch(saveProjectRequest({ data }));
    } else {
      dispatch(saveProjectRequest({ data, save: true }));
    }
    setToSave(true);
  };

  return (
    <div className="single_project_wrapper">
      <SinglePageHeader text={projectId ? t('edit_project') : t('add_project')} />
      {projects?.items && projectId && !singleProject?.id ? (
        <p className="no_object">
          {t('no_project')}
          {projectId}
        </p>
      ) : (
        <>
          <MyInput wrapperClassName="single_project_input" label={t('title')} value={title} onChange={setTitle} error={titleError} />
          <MyInput wrapperClassName="single_project_input" label={t('description')} value={desc} onChange={setDesc} error={descError} />
          <MySelect wrapperClassName="single_project_input" label={t('letterhead')} renderItem={SelectedLetterheadItem} data={letterheads?.items} showKey="title" buttonText={t('choose_letterhead')} value={letterhead} onChange={setLetterhead} error={letterheadError} t={t} />
          <div className="buttons_row">
            <MyButton loading={saveLoading} onClick={onSave} text={projectId ? t('edit_project_button') : t('add_project_button')} />
            <CancelButton loading={saveLoading} />
          </div>
        </>
      )}
    </div>
  );
}

export default SingleProject;
