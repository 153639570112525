import React, { useEffect } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getActsRequest } from '../../../store/actions/acts';
import SortableList from '../../../templates/SortableList';
import HeaderRow from './components/HeaderRow';
import ItemRow from './components/ItemRow';
import useSortTypes from './components/sortTypes';
import AddButton from '../../../components/AddButton';
import useTranslation from '../../../translations';
import prescriptions from '../Prescriptions';

function Acts() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const acts = useSelector((store) => store.acts.acts);
  const actsLoading = useSelector((store) => store.acts.actsLoading);

  const sortTypes = useSortTypes();

  const getActs = (data) => {
    dispatch(getActsRequest(data));
  };

  const onAddClick = () => {
    navigate('/acts/edit');
  };

  const onItemClick = (item) => {
    if (item.status === 'draft') {
      navigate(`/acts/edit?act_id=${item?.id}`);
    } else {
      navigate(`/acts/view?act_id=${item?.id}`);
    }
  };

  const addButton = () => (
    AddButton({ text: t('add_act_btn'), onClick: onAddClick })
  );

  const onPrescriptionNumberPress = (ev, prescriptionId, prescriptionStatus) => {
    ev.stopPropagation();
    if (prescriptionStatus === 'draft') {
      navigate(`/prescriptions/edit?prescription_id=${prescriptionId}`);
    } else {
      navigate(`/prescriptions/view?prescription_id=${prescriptionId}`);
    }
  };

  return (
    <div className="acts_wrapper">
      <SortableList
        data={acts}
        loading={actsLoading}
        requestData={getActs}
        sortTypes={sortTypes}
        withSorting
        HeaderRow={HeaderRow}
        renderItem={ItemRow}
        blockNotAdmin={false}
        TitleButton={addButton}
        onItemClick={onItemClick}
        additionalCLick={onPrescriptionNumberPress}
      />
    </div>
  );
}

export default Acts;
