import React from 'react';
import MyButton from '../../../../templates/MyButton';

function HeaderRightButtons({ addChecklist, addCategory, t }) {
  return (
    <div className="header_buttons_row">
      <MyButton onClick={addChecklist} text={t('add_checklist')} className="header_button" />
      <MyButton onClick={addCategory} text={t('add_category')} />
    </div>
  );
}

export default HeaderRightButtons;
