import {
  CHANGE_SHOW_PDF,
  CREATE_ACT_FAIL,
  CREATE_ACT_REQUEST,
  CREATE_ACT_SUCCESS,
  GET_ACTS_FAIL,
  GET_ACTS_REQUEST,
  GET_ACTS_SUCCESS,
  GET_SINGLE_ACT_FAIL,
  GET_SINGLE_ACT_REQUEST,
  GET_SINGLE_ACT_SUCCESS,
  SAVE_ACT_FAIL,
  SAVE_ACT_REQUEST,
  SAVE_ACT_SUCCESS,
} from '../actions/acts';
import { LOG_OUT } from '../actions/account';

const initialState = {
  acts: {},
  actsLoading: false,
  actsError: '',

  singleAct: {},

  creatingActLoading: false,
  creatingActError: '',

  savingActLoading: false,
  savingActError: '',

  gettingSingleActLoading: false,
  gettingSingleActError: '',

};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACTS_REQUEST: {
      return {
        ...state,
        actsLoading: true,
        actsError: '',
      };
    }
    case GET_ACTS_SUCCESS: {
      return {
        ...state,
        acts: action.payload,
        actsLoading: false,
        actsError: '',
      };
    }
    case GET_ACTS_FAIL: {
      return {
        ...state,
        actsLoading: false,
        actsError: 'error',
      };
    }

    case CREATE_ACT_REQUEST: {
      return {
        ...state,
        creatingActLoading: true,
        creatingActError: '',
      };
    }
    case CREATE_ACT_SUCCESS: {
      return {
        ...state,
        singleAct: action.payload,
        creatingActLoading: false,
        creatingActError: '',
      };
    }
    case CREATE_ACT_FAIL: {
      return {
        ...state,
        creatingActLoading: false,
        creatingActError: 'errors',
      };
    }

    case SAVE_ACT_REQUEST: {
      return {
        ...state,
        savingActLoading: true,
        savingActError: '',
      };
    }
    case SAVE_ACT_SUCCESS: {
      return {
        ...state,
        savingActLoading: false,
        savingActError: '',
      };
    }
    case SAVE_ACT_FAIL: {
      return {
        ...state,
        savingActLoading: false,
        savingActError: 'errors',
      };
    }

    case GET_SINGLE_ACT_REQUEST: {
      return {
        ...state,
        gettingSingleActLoading: true,
        gettingSingleActError: '',
        singleAct: {},
      };
    }
    case GET_SINGLE_ACT_SUCCESS: {
      return {
        ...state,
        gettingSingleActLoading: false,
        gettingSingleActError: '',
        singleAct: action.payload,
      };
    }
    case GET_SINGLE_ACT_FAIL: {
      return {
        ...state,
        gettingSingleActLoading: false,
        gettingSingleActError: 'error',
        singleAct: {},
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
