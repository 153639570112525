import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  CREATE_ACT_FAIL,
  CREATE_ACT_REQUEST,
  CREATE_ACT_SUCCESS,
  GET_ACTS_FAIL,
  GET_ACTS_REQUEST,
  GET_ACTS_SUCCESS,
  GET_SINGLE_ACT_FAIL,
  GET_SINGLE_ACT_REQUEST,
  GET_SINGLE_ACT_SUCCESS,
  SAVE_ACT_FAIL,
  SAVE_ACT_REQUEST,
  SAVE_ACT_SUCCESS,
} from '../actions/acts';

export default function* watcher() {
  yield takeLatest(GET_ACTS_REQUEST, getActs);
  yield takeLatest(CREATE_ACT_REQUEST, createAct);
  yield takeLatest(SAVE_ACT_REQUEST, saveAct);
  yield takeLatest(GET_SINGLE_ACT_REQUEST, getSingleActs);
}

function* getActs(action) {
  try {
    const { data } = yield call(Api.getActs, action.payload);

    yield put({
      type: GET_ACTS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting acts');
    console.log(e?.response?.data);
    yield put({
      type: GET_ACTS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* createAct(action) {
  try {
    const { data } = yield call(Api.createAct, action.payload);

    yield put({
      type: CREATE_ACT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while creating act');
    console.log(e?.response?.data);
    yield put({
      type: CREATE_ACT_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* saveAct(action) {
  try {
    const { data } = yield call(Api.saveAct, action.payload);

    yield put({
      type: SAVE_ACT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while saving act');
    console.log(e?.response?.data);
    yield put({
      type: SAVE_ACT_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* getSingleActs(action) {
  try {
    const { data } = yield call(Api.getSingleAct, action.payload);

    yield put({
      type: GET_SINGLE_ACT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting single act');
    console.log(e?.response?.data);
    yield put({
      type: GET_SINGLE_ACT_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
