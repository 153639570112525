import React from 'react';
import '../css/index.scss';
import { t } from '../../../../translations';

function HeaderRow() {
  return (
    <div className="header_row">
      <p className="item second">{t('number')}</p>
      <p className="item third">{t('status')}</p>
      <p className="item fourth">{t('date')}</p>
      <p className="item fifth">{t('work_type')}</p>
      <p className="item sixth">{t('object')}</p>
      <p className="item eights">{t('engineer')}</p>
      <p className="item ninth">{t('podryadchik')}</p>
      <p className="item tenth">{t('checks')}</p>
    </div>
  );
}

export default HeaderRow;
