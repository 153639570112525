import React from 'react';
import useTranslation from '../../../../translations';
import MySelect from '../../../../templates/MySelect';
import { ScheduleData, ScheduleDayOffId } from '../../../../helpers/localData/scheduleData';

function ScheduleItem({
  label, start, end, setStart, setEnd, endData,
}) {
  const { t, lang } = useTranslation();

  return (
    <div className="schedule_item">
      <p className="schedule_label">{label}</p>
      <MySelect
        data={ScheduleData(lang).slice(0, -1)}
        value={start}
        onChange={setStart}
        onlyDropDown
        abilityToClear={false}
        wrapperClassName="schedule_select"
        maxHeight={200}
      />
      {start !== ScheduleDayOffId && (
        <>
          <p className="schedule_line">–</p>
          <MySelect
            data={endData}
            value={end}
            onChange={setEnd}
            onlyDropDown
            abilityToClear={false}
            wrapperClassName="schedule_select"
            maxHeight={200}
          />
        </>
      )}

    </div>
  );
}

export default ScheduleItem;
