import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ScrollContainer from 'react-indiana-drag-scroll';
import SinglePageHeader from '../../../../components/SinglePageHeader';
import useTranslation from '../../../../translations';
import MyLoading from '../../../../templates/MyLoading';
import { COLORS } from '../../../../helpers/constants';
import Api from '../../../../Api';
import MyImg from '../../../../templates/MyImg';
import MyButton from '../../../../templates/MyButton';
import MyCheckbox from '../../../../templates/MyCheckbox';
import { getDrawingsRequest } from '../../../../store/actions/drawings';
import DrawingItem from '../../Drawings/components/DrawingItem';
import DrawingViolItem from './components/DrawingViolItem';
import DrawingDetailsMarkers from './components/DrawingDetailsMarkers';

const MAX_SIZE = 7;
const STEP = 0.5;

function SingleDrawingDetails() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const drawings = useSelector((store) => store.drawings.drawings);
  const drawingsLoading = useSelector((store) => store.drawings.drawingsLoading);

  const drawingId = searchParams.get('drawing_id');
  const isAdmin = useSelector((store) => store.account.isAdmin);

  const [singleDrawing, setSingleDrawing] = useState();
  const [gettingLoading, setGettingLoading] = useState(false);

  const [isOnlyOpenViols, setIsOnlyOpenViols] = useState(true);

  const [imgStyle, setImgStyle] = useState({});
  const [sizeCoefficient, setSizeCoefficient] = useState(1);
  const [imageSizes, setImageSizes] = useState({});

  const onPlusClick = () => {
    if (sizeCoefficient <= MAX_SIZE - STEP) {
      setSizeCoefficient((prev) => prev + STEP);
    }
  };

  const onMinusClick = () => {
    if (sizeCoefficient >= 1 + STEP) {
      setSizeCoefficient((prev) => prev - STEP);
    }
  };

  const onEditClick = () => {
    if (isAdmin) {
      navigate(`/drawings/edit?drawing_id=${singleDrawing?.id}`);
    }
  };

  const onItemClick = (drawing) => {
    navigate(`/drawings/details?drawing_id=${drawing?.id}`);
  };

  const handleOnlyOpenViolsCheckbox = (val) => {
    setIsOnlyOpenViols(val);
  };

  const onDrawingImageLoad = (ev) => {
    const { offsetHeight, offsetWidth } = ev.target;
    setImageSizes({ offsetHeight, offsetWidth });
  };

  useEffect(() => {
    if (singleDrawing?.project_id) {
      dispatch(getDrawingsRequest({ project_id: singleDrawing?.project_id }));
    }
    if (singleDrawing?.id) {
      const { height, width } = singleDrawing;
      if (height > width) {
        setImgStyle({ height: '100%' });
      } else if (width > height) {
        setImgStyle({ width: '100%' });
      } else {
        setImgStyle({ height: '100%', width: '100%' });
      }
    }
  }, [singleDrawing]);

  useEffect(() => {
    if (singleDrawing?.id) {
      setImgStyle((prev) => ({
        width: prev?.width && `${100 * sizeCoefficient}%`,
        height: prev?.height && `${100 * sizeCoefficient}%`,
      }));
    }
  }, [sizeCoefficient]);

  useEffect(() => {
    if (drawingId) {
      (async () => {
        try {
          setGettingLoading(true);
          setSizeCoefficient(1);
          const { data } = await Api.getDrawingDetails(drawingId);
          setSingleDrawing(data);
        } catch (e) {
          console.warn(e, 'while getting single drawing');
        } finally {
          setGettingLoading(false);
        }
      })();
    }
  }, [drawingId]);

  return (
    <div className="single_drawing_wrapper">
      <SinglePageHeader text={singleDrawing?.draw_title || t('drawing_details')} />

      {gettingLoading ? <MyLoading color={COLORS.main} /> : !singleDrawing ? (
        <p className="no_drawing">
          {t('no_drawing')}
          {drawingId}
        </p>
      ) : (
        <div className="single_drawing_container">
          <div className="single_drawing_top_row">
            <div className="single_drawing_left">
              <div className="drawing_scroll_view">
                <img onLoad={onDrawingImageLoad} style={imgStyle} className="drawing_img" src={Api.drawings_url_no_request + drawingId} alt="A" />
                <DrawingDetailsMarkers
                  singleDrawing={singleDrawing}
                  sizeCoefficient={sizeCoefficient}
                  imageSizes={imageSizes}
                  isOnlyOpenViols={isOnlyOpenViols}
                />
              </div>
            </div>
            <div className="single_drawing_right">
              <p className="right_title">{t('violated_remarks')}</p>
              <MyCheckbox
                onChange={handleOnlyOpenViolsCheckbox}
                label={t('only_open')}
                value={isOnlyOpenViols}
                className="is_open_checkbox"
              />
              <ScrollContainer vertical className="viols_scroll_view">
                {singleDrawing?.viols?.map((violItem, violIndex) => (isOnlyOpenViols
                  && (violItem.flag_overdue || ['accept', 'accept-force', 'force-accept', 'canceled'].includes(violItem.predpisan_status))
                  ? null
                  : (
                    <DrawingViolItem
                      viol={violItem}
                      index={violIndex}
                      key={violIndex}
                    />
                  )))}
              </ScrollContainer>
            </div>
          </div>

          <div>
            <div className="buttons_row">
              <MyButton
                text="+"
                className="size_button"
                textClassName="size_button_text"
                onClick={onPlusClick}
                disabled={sizeCoefficient > MAX_SIZE - STEP}
              />
              <MyButton
                text="–"
                className="size_button"
                textClassName="size_button_text"
                onClick={onMinusClick}
                disabled={sizeCoefficient < 1 + STEP}
              />
            </div>
            <div className="drawing_info_row">
              <div>
                <div className="drawing_info_item">
                  <img className="drawing_info_img" src="/images/drawing_name_pink.png" alt="N" />
                  <p className="drawing_info_title">
                    {t('drawing_title')}
                    :
                  </p>
                  <p className="drawing_info_text">{singleDrawing?.draw_title}</p>
                </div>
                <div className="drawing_info_item">
                  <img className="drawing_info_img small" src="/images/project_pink.png" alt="P" />
                  <p className="drawing_info_title">
                    {t('project')}
                    :
                  </p>
                  <p className="drawing_info_text">{singleDrawing?.project_title}</p>
                </div>
                <div className="drawing_info_item">
                  <img className="drawing_info_img" src="/images/object_pink.png" alt="O" />
                  <p className="drawing_info_title">
                    {t('object')}
                    :
                  </p>
                  <p className="drawing_info_text">{singleDrawing?.build_objs_title}</p>
                </div>
              </div>
              {isAdmin && (
                <MyButton
                  text={t('pravka')}
                  onClick={onEditClick}
                  className="single_drawing_pravka"
                />
              )}
            </div>
          </div>

          <div className="single_drawing_bottom">
            <p className="single_drawing_bottom_title">
              {t('drawings_in_projects')}
              {' '}
              &quot;
              {singleDrawing?.project_title}
              &quot;
            </p>
            <ScrollContainer className="single_drawing_bottom_scroll_view">
              {drawingsLoading ? <MyLoading /> : (
                drawings?.length ? drawings.map((item, drawingIndex) => (
                  <DrawingItem
                    keyField={drawingIndex}
                    key={drawingIndex}
                    drawing={item}
                    onItemClick={onItemClick}
                  />
                )) : <p className="no_drawing">{t('no_drawings_in_project')}</p>
              )}
            </ScrollContainer>
          </div>
        </div>
      )}
    </div>
  );
}

export default SingleDrawingDetails;
