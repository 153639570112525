const func = ({
  nestedArr,
  /* addText = '・ ・ ', */
  addText = '----',
  nestedCount = 0,
  currentId,
}) => {
  const arr = nestedArr.reduce((prev, curr) => {
    if (curr.id === +currentId) {
      return prev;
    }

    const newObject = {
      ...curr,
      titleAsTree: `${addText.repeat(nestedCount)} ${curr.title}`,
    };

    if (curr.children?.length) {
      return [...prev, newObject, ...func({
        nestedArr: curr.children,
        addText,
        nestedCount: nestedCount + 1,
        currentId,
      })];
    }
    return [...prev, newObject];
  }, []);

  return arr;
};

export default func;
