import React from 'react';
import '../css/index.scss';

function AppointmentWrapper({ children }) {
  return (
    <div className="appointment_wrapper">
      <div className="main">
        <img className="main_logo" src="/images/appointment_logo.png" alt="Logo" />
        <div className="scroll_view">
          {children}
        </div>
      </div>
    </div>
  );
}

export default AppointmentWrapper;
