import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  DELETE_CLIENT_FAIL,
  DELETE_CLIENT_REQUEST,
  GET_CLIENTS_FAIL,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS, SAVE_CLIENT_FAIL,
  SAVE_CLIENT_REQUEST, SAVE_CLIENT_SUCCESS,
} from '../actions/clients';

export default function* watcher() {
  yield takeLatest(GET_CLIENTS_REQUEST, getClients);
  yield takeLatest(SAVE_CLIENT_REQUEST, saveClient);
  yield takeLatest(DELETE_CLIENT_REQUEST, deleteClient);
}

function* getClients(action) {
  try {
    const { data } = yield call(Api.getClients, action.payload);

    yield put({
      type: GET_CLIENTS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting clients');
    console.log(e?.response?.data);
    yield put({
      type: GET_CLIENTS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* saveClient(action) {
  try {
    let data;
    if (action.payload.save) {
      const res = yield call(Api.addClient, action.payload.data);
      data = res.data;
    } else {
      const res = yield call(Api.editClient, action.payload.data);
      data = res.data;
    }

    yield put({
      type: SAVE_CLIENT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while saving client');
    console.log(e?.response?.data);
    yield put({
      type: SAVE_CLIENT_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* deleteClient(action) {
  try {
    const { id, requestData } = action.payload;
    yield call(Api.deleteClient, id);
    yield put({
      type: GET_CLIENTS_REQUEST,
      payload: requestData,
    });
  } catch (e) {
    console.log(e, 'while deleting client');
    console.log(e?.response?.data);
    yield put({
      type: DELETE_CLIENT_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
