export const GET_LETTERHEADS_REQUEST = 'GET_LETTERHEADS_REQUEST';
export const GET_LETTERHEADS_SUCCESS = 'GET_LETTERHEADS_SUCCESS';
export const GET_LETTERHEADS_FAIL = 'GET_LETTERHEADS_FAIL';
export function getLetterheadsRequest(data) {
  return {
    type: GET_LETTERHEADS_REQUEST,
    payload: data,
  };
}

export const SET_SINGLE_LETTERHEAD = 'SET_SINGLE_LETTERHEAD';
export function setSingleLetterhead(data) {
  return {
    type: SET_SINGLE_LETTERHEAD,
    payload: data,
  };
}

export const SAVE_LETTERHEAD_REQUEST = 'SAVE_LETTERHEAD_REQUEST';
export const SAVE_LETTERHEAD_SUCCESS = 'SAVE_LETTERHEAD_SUCCESS';
export const SAVE_LETTERHEAD_FAIL = 'SAVE_LETTERHEAD_FAIL';
export function saveLetterheadRequest(data) {
  return {
    type: SAVE_LETTERHEAD_REQUEST,
    payload: data,
  };
}

export const DELETE_LETTERHEAD_REQUEST = 'DELETE_LETTERHEAD_REQUEST';
export const DELETE_LETTERHEAD_SUCCESS = 'DELETE_LETTERHEAD_SUCCESS';
export const DELETE_LETTERHEAD_FAIL = 'DELETE_LETTERHEAD_FAIL';
export function deleteLetterheadRequest(data) {
  return {
    type: DELETE_LETTERHEAD_REQUEST,
    payload: data,
  };
}
