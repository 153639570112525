export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export function getUsersRequest(data) {
  return {
    type: GET_USERS_REQUEST,
    payload: data,
  };
}

export const SET_SINGLE_USER = 'SET_SINGLE_USER';
export function setSingleUser(data) {
  return {
    type: SET_SINGLE_USER,
    payload: data,
  };
}

export const SAVE_USER_REQUEST = 'SAVE_USER_REQUEST';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const SAVE_USER_FAIL = 'SAVE_USER_FAIL';
export function saveUserRequest(data) {
  return {
    type: SAVE_USER_REQUEST,
    payload: data,
  };
}

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export function deleteUserRequest(data) {
  return {
    type: DELETE_USER_REQUEST,
    payload: data,
  };
}
