import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import AppointmentWrapper from './components/AppointmentWrapper';
import useTranslation from '../../../translations';
import MyButton from '../../../templates/MyButton';
import MyInput from '../../../templates/MyInput';
import useInputState from '../../../helpers/hooks/useInputState';
import Api from '../../../Api';
import LocalStorageServices from '../../../helpers/LocalStorageServices';
import { setSingleAppointment as setSingleAppointmentRequest } from '../../../store/actions/appointment';
import getNextHour from '../../../helpers/getNextHour';
import getAppointmentDateInfo from '../../../helpers/getAppointmentDateInfo';
import MyModal from '../../../templates/MyModal';

function ViewAppointment() {
  let { lang } = useParams();
  if (!['en', 'ru'].includes(lang)) lang = 'ru';

  const { t } = useTranslation(lang);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const appointmentId = searchParams.get('appointment_my_id');

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [deletingLoading, setDeletingLoading] = useState(false);
  const [singleAppointment, setSingleAppointment] = useState({});
  const [gettingLoading, setGettingLoading] = useState(false);

  const refreshAppointment = async () => {
    try {
      setGettingLoading(true);
      const { data } = await Api.refreshAppointment({ id: singleAppointment.id, sync_uuid: singleAppointment.sync_uuid });
      if (data?.length) {
        const newData = data[0];

        const now = moment();
        const date = moment(newData.date_time, 'DD.MM.YYYY HH.mm');

        const duration = moment.duration(now.diff(date));

        const dateIndex = Math.ceil(duration.asDays()) + 1;
        const newItem = {
          ...singleAppointment,
          date_time: newData.date_time,
          status: newData.status,
          info: {
            ...singleAppointment.info,
            date: newData.date_time.split((' '))[0],
            time: newData.date_time.split((' '))[1],
            dateInfo: getAppointmentDateInfo(date, dateIndex, t),
          },
        };
        LocalStorageServices.updateAppointment(singleAppointment.id, newItem);
        setSingleAppointment(newItem);
      }
    } catch (e) {
      console.warn(e, 'while getting single appointment');
    } finally {
      setGettingLoading(false);
    }
  };

  const openDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const deleteAppointment = async () => {
    try {
      setDeletingLoading(true);

      const { data } = await Api.deleteAppointment(singleAppointment.id, singleAppointment.sync_uuid);

      if (data?.message === 'Success') {
        closeDeleteModal();
        LocalStorageServices.deleteAppointment(singleAppointment.id);
        navigate('..');
      }
    } catch (e) {
      console.log(e?.response?.data);
      console.warn(e, 'while deleting appointment');
    } finally {
      setDeletingLoading(false);
    }
  };

  const onNewAppointmentClick = async () => {
    navigate('..');
  };

  const onEditAppointmentClick = async () => {
    navigate('..');
  };

  useEffect(() => {
    if (!appointmentId) {
      return navigate('..');
    }

    const appointment = LocalStorageServices.getAppointment(+appointmentId);

    if (!appointment) {
      navigate('..');
    } else {
      setSingleAppointment(appointment);
    }
  }, [appointmentId]);

  return (
    <AppointmentWrapper>
      <p className="page_title">{t('appointment_record')}</p>

      <div className="info_block">
        <img className="info_block_img" src="/images/appointment_success_icon.png" alt="U" />
        <div>
          <p className="info_block_title">{t('appointment_accepted')}</p>
          <p className="info_block_text">{t('appointment_accepted_text')}</p>
        </div>
      </div>

      <div className="simple_block margin_top_big">
        <p className="small_title">{t('appointment_status')}</p>
        <div className="status_row">
          <p className="simple_text">{t(singleAppointment?.status)}</p>
          <MyButton
            text={t('refresh').toUpperCase()}
            className="time_button"
            textClassName="button_text"
            onClick={refreshAppointment}
            loading={gettingLoading}
          />
        </div>
      </div>

      <div className="simple_block">
        <p className="small_title">{t('date')}</p>
        <p className="simple_text">{singleAppointment?.info?.dateInfo}</p>
      </div>
      <div className="simple_block">
        <p className="small_title">{t('time')}</p>
        <p className="simple_text">{`${singleAppointment?.info?.time} - ${getNextHour(singleAppointment?.info?.time)}`}</p>
      </div>
      <div className="simple_block">
        <p className="small_title">{t('engineer')}</p>
        <p className="simple_text">{singleAppointment?.info?.engineer?.fio}</p>
      </div>
      <div className="simple_block">
        <p className="small_title">{t('what_to_appoint')}</p>
        <p className="simple_text">{singleAppointment?.labor}</p>
      </div>

      <div className="buttons_row_appointment">
        <MyButton
          text={t('back').toUpperCase()}
          onClick={onEditAppointmentClick}
          className="bottom_button bottom_button_small"
          textClassName="button_text"
        />
        <MyButton
          text={t('delete').toUpperCase()}
          onClick={openDeleteModal}
          className="bottom_button bottom_button_small"
          textClassName="button_text"
        />
      </div>

      <div className="save_div">
        <img className="save_div_img" src="/images/appointment_save.png" alt="S" />
        <div>
          <p className="save_div_text">
            <span>{t('hint')}</span>
            {t('appointment_hint_text')}
          </p>
          <p className="save_div_link">{Api.url + location.pathname.slice(0, -5)}</p>
        </div>
      </div>

      <MyButton
        text={t('new_appointment')}
        onClick={onNewAppointmentClick}
        className="bottom_button"
        textClassName="button_text"
      />

      {isDeleteModalVisible && (
        <MyModal isOpen={isDeleteModalVisible} closeFunction={closeDeleteModal}>
          <p className="appointment_delete_text">{t('appointment_delete_text')}</p>
          <p className="appointment_delete_info">{singleAppointment.labor}</p>

          <div className="buttons_row_appointment">
            <MyButton
              text={t('cancel')}
              onClick={closeDeleteModal}
              disabled={deletingLoading}
            />
            <MyButton
              text={t('delete')}
              onClick={deleteAppointment}
              loading={deletingLoading}
            />
          </div>
        </MyModal>
      )}

    </AppointmentWrapper>
  );
}

export default ViewAppointment;
