export const COLORS = {
  main: '#D4246D',
  accent: '#00E9D8',
  text: '#444444',
  label: '#AAAAAA',
  dark: '#3F274D',
  white: '#FFFFFF',
  error: '#f14668',
};

export const MAIN_PADDINGS = 30;

export const ROLES = {
  admin: 'admin',
  root: 'root',
  stroymanager: 'stroymanager',
  constructor: 'constructor',
  client: 'client',
  chief: 'chief',
};

export const CHECKUP_STATUSES = {
  completed: 'completed',
  completed_with_warnings: 'completed_with_warnings',
  checkup_started: 'checkup_started',
  not_completed: 'not_completed',

  requested: 'requested',
  wait: 'wait',
  deleted: 'deleted',
};

export const CHECKUP_STATUSES_LIST = Object.values(CHECKUP_STATUSES).map((v, i) => ({ id: i + 1, title: v }));
