export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_FAIL = 'GET_CLIENTS_FAIL';
export function getClientsRequest(data) {
  return {
    type: GET_CLIENTS_REQUEST,
    payload: data,
  };
}

export const SET_SINGLE_CLIENT = 'SET_SINGLE_CLIENT';
export function setSingleClient(data) {
  return {
    type: SET_SINGLE_CLIENT,
    payload: data,
  };
}

export const SAVE_CLIENT_REQUEST = 'SAVE_CLIENT_REQUEST';
export const SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS';
export const SAVE_CLIENT_FAIL = 'SAVE_CLIENT_FAIL';
export function saveClientRequest(data) {
  return {
    type: SAVE_CLIENT_REQUEST,
    payload: data,
  };
}

export const DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL';
export function deleteClientRequest(data) {
  return {
    type: DELETE_CLIENT_REQUEST,
    payload: data,
  };
}
