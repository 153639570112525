import React from 'react';
import './css/index.scss';

function Instruction() {
  return (
    <div className="iframe_div">
      <iframe
        title="instruction_iframe"
        id="instruction_iframe"
        src="https://coda.io/embed/28jROs8NZY/_suDD9?viewMode=embedplay"
        align="middle"
        allow="fullscreen"
        className="iframe"
        frameBorder={0}
      />
    </div>
  );
}

export default Instruction;
