import { GET_ROLES_FAIL, GET_ROLES_REQUEST, GET_ROLES_SUCCESS } from '../actions/roles';
import { LOG_OUT } from '../actions/account';

const initialState = {
  roles: {},
  rolesLoading: false,
  rolesError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ROLES_REQUEST: {
      return {
        ...state,
        rolesLoading: true,
        rolesError: '',
      };
    }
    case GET_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.payload,
        rolesLoading: false,
        rolesError: '',
      };
    }
    case GET_ROLES_FAIL: {
      return {
        ...state,
        rolesLoading: false,
        rolesError: 'error',
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
