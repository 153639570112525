import { Outlet, Navigate } from 'react-router';
import Wrapper from '../templates/Wrapper';

export default function ({ token }) {
  return token ? (
    <Wrapper>
      <Outlet />
    </Wrapper>
  ) : <Navigate to="/login" replace />;
}
