import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useInputState from '../../../helpers/hooks/useInputState';
import MyInput from '../../../templates/MyInput';
import MyButton from '../../../templates/MyButton';
import CancelButton from '../../../components/CancelButton';
import {
  getConfigurationsRequest, saveConfigurationRequest,
  setSingleConfiguration,
} from '../../../store/actions/configurations';
import requestAllData from '../../../helpers/requestAllData';

function SingleConfiguration() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const configurationId = searchParams.get('configuration_key');

  const configurations = useSelector((store) => store.configurations.configurations);
  const singleConfiguration = useSelector((store) => store.configurations.singleConfiguration);
  const saveLoading = useSelector((store) => store.configurations.saveLoading);
  const saveError = useSelector((store) => store.configurations.saveError);

  const [key, setKey, keyError, setKeyError] = useInputState();
  const [value, setValue, valueError, setValueError] = useInputState();
  const [dataType, setDataType, dataTypeError, setDataTypeError] = useInputState();
  const [descr, setDescr, descrError, setDescrError] = useInputState();

  const [toSave, setToSave] = useState(false);

  useEffect(() => {
    if (!configurationId) {
      return;
    }
    if (!configurations?.items) {
      dispatch(getConfigurationsRequest(requestAllData));
    }
  }, []);

  useEffect(() => {
    if (configurationId && configurations?.items) {
      dispatch(setSingleConfiguration(configurationId));
    }
  }, [configurations]);

  useEffect(() => {
    if (configurationId && singleConfiguration?.key) {
      setKey(singleConfiguration.key);
      setValue(singleConfiguration.value);
      setDataType(singleConfiguration.data_type);
      setDescr(singleConfiguration.descr);
    }
  }, [singleConfiguration]);

  useEffect(() => {
    if (toSave && !saveLoading) {
      if (!saveError) {
        goBack();
      } else {
        setToSave(false);
      }
    }
  }, [saveLoading]);

  const goBack = () => {
    navigate(-1, { replace: true });
  };

  const onSave = () => {
    let flag = false;
    if (!key) {
      setKeyError(t('key') + t('is_required_m_r'));
      flag = true;
    }
    if (!value) {
      setValueError(t('value') + t('is_required_s_r'));
      flag = true;
    }
    if (!dataType) {
      setDataTypeError(t('data_type') + t('is_required_m_r'));
      flag = true;
    }
    if (!descr) {
      setDescrError(t('description') + t('is_required_s_r'));
      flag = true;
    }

    if (flag) {
      return;
    }

    const data = {
      key,
      value,
      descr,
      'data-type': dataType,
    };

    if (configurationId) {
      dispatch(saveConfigurationRequest({ data }));
    } else {
      dispatch(saveConfigurationRequest({ data, save: true }));
    }
    setToSave(true);
  };

  return (
    <div className="single_configuration_wrapper">
      <SinglePageHeader text={configurationId ? t('edit_configuration') : t('add_configuration')} />
      {configurations?.items && configurationId && !singleConfiguration?.key ? (
        <p className="no_configuration">
          {t('no_configuration')}
          {configurationId}
        </p>
      ) : (
        <>
          <MyInput wrapperClassName="single_configuration_input" className={configurationId && 'disabled'} label={t('key')} value={key} onChange={setKey} error={keyError} disabled={configurationId} />
          <MyInput wrapperClassName="single_configuration_input" label={t('value')} value={value} onChange={setValue} error={valueError} />
          <MyInput wrapperClassName="single_configuration_input" label={t('data_type')} value={dataType} onChange={setDataType} error={dataTypeError} />
          <MyInput wrapperClassName="single_configuration_input" label={t('description')} value={descr} onChange={setDescr} error={descrError} />
          <div className="buttons_row">
            <MyButton loading={saveLoading} onClick={onSave} text={configurationId ? t('edit_configuration_button') : t('add_configuration_button')} />
            <CancelButton loading={saveLoading} />
          </div>
        </>
      )}
    </div>
  );
}

export default SingleConfiguration;
