export const GET_ACTS_REQUEST = 'GET_ACTS_REQUEST';
export const GET_ACTS_SUCCESS = 'GET_ACTS_SUCCESS';
export const GET_ACTS_FAIL = 'GET_ACTS_FAIL';
export function getActsRequest(data) {
  return {
    type: GET_ACTS_REQUEST,
    payload: data,
  };
}
export const GET_SINGLE_ACT_REQUEST = 'GET_SINGLE_ACT_REQUEST';
export const GET_SINGLE_ACT_SUCCESS = 'GET_SINGLE_ACT_SUCCESS';
export const GET_SINGLE_ACT_FAIL = 'GET_SINGLE_ACT_FAIL';
export function getSingleActRequest(data) {
  return {
    type: GET_SINGLE_ACT_REQUEST,
    payload: data,
  };
}

export const CREATE_ACT_REQUEST = 'CREATE_ACT_REQUEST';
export const CREATE_ACT_SUCCESS = 'CREATE_ACT_SUCCESS';
export const CREATE_ACT_FAIL = 'CREATE_ACT_FAIL';
export function createActRequest(data) {
  return {
    type: CREATE_ACT_REQUEST,
    payload: data,
  };
}

export const SAVE_ACT_REQUEST = 'SAVE_ACT_REQUEST';
export const SAVE_ACT_SUCCESS = 'SAVE_ACT_SUCCESS';
export const SAVE_ACT_FAIL = 'SAVE_ACT_FAIL';
export function saveActRequest(data) {
  return {
    type: SAVE_ACT_REQUEST,
    payload: data,
  };
}
