import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  DELETE_OBJECT_FAIL,
  DELETE_OBJECT_REQUEST,
  GET_OBJECTS_FAIL,
  GET_OBJECTS_REQUEST,
  GET_OBJECTS_SUCCESS, SAVE_OBJECT_FAIL,
  SAVE_OBJECT_REQUEST, SAVE_OBJECT_SUCCESS,
} from '../actions/objects';

export default function* watcher() {
  yield takeLatest(GET_OBJECTS_REQUEST, getObjects);
  yield takeLatest(SAVE_OBJECT_REQUEST, saveObject);
  yield takeLatest(DELETE_OBJECT_REQUEST, deleteObject);
}

function* getObjects(action) {
  try {
    const { data } = yield call(Api.getObjects, action.payload);

    yield put({
      type: GET_OBJECTS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting objects');
    console.log(e?.response?.data);
    yield put({
      type: GET_OBJECTS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* saveObject(action) {
  try {
    let data;
    if (action.payload.save) {
      const res = yield call(Api.addObject, action.payload.data);
      data = res.data;
    } else {
      const res = yield call(Api.editObject, action.payload.data);
      data = res.data;
    }

    yield put({
      type: SAVE_OBJECT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while saving object');
    console.log(e?.response?.data);
    yield put({
      type: SAVE_OBJECT_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* deleteObject(action) {
  try {
    const { id, requestData } = action.payload;
    yield call(Api.deleteObject, id);
    yield put({
      type: GET_OBJECTS_REQUEST,
      payload: requestData,
    });
  } catch (e) {
    console.log(e, 'while deleting object');
    console.log(e?.response?.data);
    yield put({
      type: DELETE_OBJECT_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
