import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useInputState from '../../../helpers/hooks/useInputState';
import MyInput from '../../../templates/MyInput';
import MyButton from '../../../templates/MyButton';
import CancelButton from '../../../components/CancelButton';
import {
  getClientsRequest,
  saveClientRequest,
  setSingleClient,
} from '../../../store/actions/clients';
import generateClientPassword from '../../../helpers/generateUserPassword';
import validateClientPassword from '../../../helpers/validateUserPassword';
import MySelect from '../../../templates/MySelect';
import { getRolesRequest } from '../../../store/actions/roles';
import MyCheckbox from '../../../templates/MyCheckbox';
import validateNames from '../../../helpers/validateNames';
import requestAllData from '../../../helpers/requestAllData';

function SingleClient() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const clientId = searchParams.get('client_id');

  const clients = useSelector((store) => store.clients.clients);
  const singleClient = useSelector((store) => store.clients.singleClient);
  const saveLoading = useSelector((store) => store.clients.saveLoading);
  const saveError = useSelector((store) => store.clients.saveError);

  const roles = useSelector((store) => store.roles.roles);

  const [title, setTitle, titleError, setTitleError] = useInputState();
  const [descr, setDescr, descrError, setDescrError] = useInputState();
  const [enabled, setEnabled] = useState(false);
  const [expDate, setExpDate, expDateError, setExpDateError] = useInputState();
  const [tariff, setTariff, tariffError, setTariffError] = useInputState();
  const [usersLimit, setUsersLimit, usersLimitError, setUsersLimitError] = useInputState(10000);

  const [group, setGroup, groupError, setGroupError] = useInputState();
  const [login, setLogin, loginError, setLoginError] = useInputState();
  const [folder, setFolder, folderError, setFolderError] = useInputState();
  const [fio, setFio, fioError, setFioError] = useInputState();
  const [password, setPassword, passwordError, setPasswordError] = useInputState(generateClientPassword());

  const [simpleError, setSimpleError] = useState();
  const [toSave, setToSave] = useState(false);

  useEffect(() => {
    if (!roles?.items) {
      dispatch(getRolesRequest(requestAllData));
    }
    if (!clientId) {
      return;
    }
    if (!clients?.items) {
      dispatch(getClientsRequest(requestAllData));
    }
  }, []);

  useEffect(() => {
    if (clientId && clients?.items) {
      dispatch(setSingleClient(clientId));
    }
  }, [clients]);

  useEffect(() => {
    if (clientId && singleClient?.id) {
      setTitle(singleClient.title);
      setDescr(singleClient.descr);
      setEnabled(singleClient.enabled);
      setExpDate(singleClient['expiration-date']);
      setTariff(singleClient.tariff);
      setUsersLimit(singleClient['users-limit']);
    }
  }, [singleClient]);

  useEffect(() => {
    if (toSave && !saveLoading) {
      if (!saveError) {
        goBack();
      } else {
        if (saveError === 'Error! Such user exist!') {
          setLoginError(t('user_exists'));
        } else {
          setSimpleError(saveError);
        }
        setToSave(false);
      }
    }
  }, [saveLoading]);

  const goBack = () => {
    navigate(-1, { replace: true });
  };

  const onSave = () => {
    let flag = false;
    if (!title) {
      setTitleError(t('title') + t('is_required_s_r'));
      flag = true;
    }
    if (!descr) {
      setDescrError(t('description') + t('is_required_s_r'));
      flag = true;
    }
    if (!expDate) {
      setExpDateError(t('license_date') + t('is_required_j_r'));
      flag = true;
    }
    if (!usersLimit) {
      setUsersLimitError(t('users_limit') + t('is_required_s_r'));
      flag = true;
    }

    if (!clientId) {
      if (!group) {
        setGroupError(t('client_group') + t('is_required_s_r'));
        flag = true;
      } else {
        const groupValidateError = validateNames(group, true);
        if (groupValidateError) {
          setGroupError(t(groupValidateError));
          flag = true;
        }
      }

      if (!login) {
        setLoginError(t('client_login') + t('is_required_m_r'));
        flag = true;
      }

      if (!password) {
        setPasswordError(t('client_password') + t('is_required_m_r'));
        flag = true;
      } else {
        const passwordValidateError = validateClientPassword(password);
        if (passwordValidateError) {
          setPasswordError(t(passwordValidateError));
          flag = true;
        }
      }

      if (!folder) {
        setFolderError(t('client_folder') + t('is_required_s_r'));
        flag = true;
      } else {
        const folderValidateError = validateNames(folder);
        if (folderValidateError) {
          setFolderError(t(folderValidateError));
          flag = true;
        }
      }

      if (!fio) {
        setFioError(t('user_fio') + t('is_required_s_r'));
        flag = true;
      } else {
        const splitFio = fio.split(' ');
        if (splitFio.length !== 3) {
          setFioError(t('fio_3_words'));
          flag = true;
        } else if (splitFio[0].length > 15) {
          setFioError(t('fio_15_symbols'));
          flag = true;
        }
      }
    }

    if (flag) {
      return;
    }

    const data = {
      title,
      descr,
      tariff,
      enabled,
      'is-treal': false,
      'expiration-date': expDate,
      'users-limit': +usersLimit,
    };

    if (clientId) {
      data.id = +clientId;
    } else {
      data['group-tag-name'] = group;
      data.fio = fio;
      data['nextcloud-users-bind'] = true;
      data['webdav-folder'] = folder;
      data['webdav-login'] = login;
      data['webdav-password'] = password;
    }

    if (clientId) {
      dispatch(saveClientRequest({ data }));
    } else {
      dispatch(saveClientRequest({ data, save: true }));
    }
    setToSave(true);
  };

  return (
    <div className="single_client_wrapper">
      <SinglePageHeader text={clientId ? t('edit_client') : t('add_client')} />
      {clients?.items && clientId && !singleClient?.id ? (
        <p className="no_object">
          {t('no_client')}
          {clientId}
        </p>
      ) : (
        <>
          <MyInput wrapperClassName="single_client_input" label={t('title')} value={title} onChange={setTitle} error={titleError} />
          <MyInput wrapperClassName="single_client_input" label={t('description')} value={descr} onChange={setDescr} error={descrError} />
          <MyCheckbox className="single_client_input" label={t('enabled')} value={enabled} onChange={setEnabled} />
          <MyInput wrapperClassName="single_client_input" label={t('license_date')} value={expDate} onChange={setExpDate} error={expDateError} type="date" />
          <MyInput wrapperClassName="single_client_input" label={t('tariff')} value={tariff} onChange={setTariff} error={tariffError} />
          <MyInput wrapperClassName="single_client_input" type="number" label={t('users_limit')} value={usersLimit} onChange={setUsersLimit} error={usersLimitError} />
          {!clientId && (
            <>
              <div className="line" />
              <MyInput wrapperClassName="single_client_input" label={t('client_group')} value={group} onChange={setGroup} error={groupError} info={t('client_group_info')} />
              <MyInput wrapperClassName="single_client_input" label={t('client_login')} value={login} onChange={setLogin} error={loginError} info={t('client_login_info')} type="email" />
              <MyInput wrapperClassName="single_client_input" placeholder="•••••••••" label={t('client_password')} value={password} onChange={setPassword} error={passwordError} info={t('pass_info')} />
              <MyInput wrapperClassName="single_client_input" label={t('client_folder')} value={folder} onChange={setFolder} error={folderError} info={t('client_folder_info')} />
              <MyInput wrapperClassName="single_client_input" label={t('client_fio')} value={fio} onChange={setFio} error={fioError} info={t('client_fio_info')} />
            </>
          )}
          <p className="simple_error">{simpleError}</p>

          <div className="buttons_row">
            <MyButton loading={saveLoading} onClick={onSave} text={clientId ? t('edit_client_button') : t('add_client_button')} />
            <CancelButton loading={saveLoading} />
          </div>
        </>
      )}
    </div>
  );
}

export default SingleClient;
