import React, { useEffect } from 'react';
import './css/index.scss';
import moment from 'moment/moment';

function MyInput({
  wrapperClassName = '',
  labelClassName = '',
  infoClassName = '',
  className = '',

  label,
  innerRef,
  id,
  error,
  info,
  textarea,
  type = 'text',
  ...p
}) {
  useEffect(() => {
    if (type !== 'date') return;

    const date = p.value;
    if (moment(date, 'yyyy-MM-DD').year() >= 2050) {
      const event = { target: { name: p.name, value: moment(date).set('year', 2050).format('yyyy-MM-DD') } };
      p.onChange(event);
    }
  }, [p.value]);

  return (
    <div className={`input_div ${wrapperClassName}`}>
      {label && <label className={`label ${labelClassName}`}>{label}</label>}
      {textarea ? (
        <textarea className={`input ${className} ${error && 'error_input'}`} {...p} />
      ) : (
        <input
          type={type}
          className={`input ${type === 'date' && 'date_input'} ${className} ${error && 'error_input'}`}
          ref={innerRef}
          {...p}
        />
      )}
      {error ? <p className="error">{error}</p> : info ? <p className={`info ${infoClassName}`}>{info}</p> : null}
    </div>
  );
}

export default MyInput;
