export const GET_ALLOCS_REQUEST = 'GET_ALLOCS_REQUEST';
export const GET_ALLOCS_SUCCESS = 'GET_ALLOCS_SUCCESS';
export const GET_ALLOCS_FAIL = 'GET_ALLOCS_FAIL';
export function getAllocsRequest(data) {
  return {
    type: GET_ALLOCS_REQUEST,
    payload: data,
  };
}

export const SET_SINGLE_ALLOC = 'SET_SINGLE_ALLOC';
export function setSingleAlloc(data) {
  return {
    type: SET_SINGLE_ALLOC,
    payload: data,
  };
}

export const SAVE_ALLOC_REQUEST = 'SAVE_ALLOC_REQUEST';
export const SAVE_ALLOC_SUCCESS = 'SAVE_ALLOC_SUCCESS';
export const SAVE_ALLOC_FAIL = 'SAVE_ALLOC_FAIL';
export function saveAllocRequest(data) {
  return {
    type: SAVE_ALLOC_REQUEST,
    payload: data,
  };
}

export const DELETE_ALLOC_REQUEST = 'DELETE_ALLOC_REQUEST';
export const DELETE_ALLOC_SUCCESS = 'DELETE_ALLOC_SUCCESS';
export const DELETE_ALLOC_FAIL = 'DELETE_ALLOC_FAIL';
export function deleteAllocRequest(data) {
  return {
    type: DELETE_ALLOC_REQUEST,
    payload: data,
  };
}
