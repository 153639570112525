import React from 'react';
import './css/index.scss';
import { useNavigate } from 'react-router-dom';
import MyButton from '../../templates/MyButton';
import useTranslation from '../../translations';

function CancelButton({
  text, onClick, withoutMargin, loading, disabled,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1, { replace: true });
  };

  if (loading) {
    return null;
  }
  return (
    <MyButton
      text={text || t('cancel')}
      onClick={onClick || goBack}
      className={`cancel_button ${!withoutMargin && 'with_margin'}`}
      textClassName="cancel_button_text"
      disabled={disabled}
    />
  );
}

export default CancelButton;
