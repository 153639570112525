export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL';
export function getCompaniesRequest(data) {
  return {
    type: GET_COMPANIES_REQUEST,
    payload: data,
  };
}

export const SET_SINGLE_COMPANY = 'SET_SINGLE_COMPANY';
export function setSingleCompany(data) {
  return {
    type: SET_SINGLE_COMPANY,
    payload: data,
  };
}

export const SAVE_COMPANY_REQUEST = 'SAVE_COMPANY_REQUEST';
export const SAVE_COMPANY_SUCCESS = 'SAVE_COMPANY_SUCCESS';
export const SAVE_COMPANY_FAIL = 'SAVE_COMPANY_FAIL';
export function saveCompanyRequest(data) {
  return {
    type: SAVE_COMPANY_REQUEST,
    payload: data,
  };
}

export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';
export function deleteCompanyRequest(data) {
  return {
    type: DELETE_COMPANY_REQUEST,
    payload: data,
  };
}
