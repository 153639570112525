import React from 'react';
import './css/index.scss';

function LanguageSelector({ lang, setLanguage }) {
  const russianRow = () => (
    <div className="row" onClick={() => setLanguage('ru')}>
      <img src="/images/langRu.png" alt="ru" className="img" />
      <p className="text">Русский</p>
    </div>
  );
  const englishRow = () => (
    <div className="row" onClick={() => setLanguage('en')}>
      <img src="/images/langEn.png" alt="en" className="img" />
      <p className="text">English</p>
    </div>
  );

  return (
    <div className="language_selector_trigger">
      {lang === 'en' ? englishRow() : russianRow()}
      <div className="showing">
        {lang === 'en' ? russianRow() : englishRow()}
      </div>
    </div>
  );
}

export default LanguageSelector;
