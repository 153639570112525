import React from 'react';
import '../css/index.scss';
import moment from 'moment';
import MyButton from '../../../../templates/MyButton';
import { getItemsActsNumbers } from '../../../../helpers/getItemsActsNumbers';

function ItemRow(item, index, onItemEdit, showModal, t, loading, isAdmin, onItemClick, additionalClick) {
  const cloudPress = (ev) => {
    ev.stopPropagation();

    const url = `https://cloud.teamject.com/apps/files/?dir=${item['path-folder']}`;
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <div className={`item_row ${onItemClick && 'clickable'}`} key={item.id} onClick={() => onItemClick(item)}>
      <p className="item first">
        <span className={`circle ${item.priority === 'medium' ? 'yellow' : item.priority === 'critical' ? 'red' : 'green'}`} />
      </p>
      <div className="item second">
        <div className="second_inner">
          {item['prev-number-out'] && (
            <span className="small_number">
              {item['prev-number-out']}
              <img src="/images/arrow_right.png" alt=">" className="arrow_right" />
            </span>
          )}

          {item['number-out']}

          {item['next-number-out'] && (
            <span className="small_number">
              <img src="/images/arrow_right.png" alt=">" className="arrow_right" />
              {item['next-number-out']}
            </span>
          )}
        </div>
      </div>
      <p className="item third">{moment(item.udate).format('DD.MM.YYYY')}</p>
      <p className="item fourth">{item['build-obj-title']}</p>
      <p className="item fifth">{item['engineer-user-alc'] && item['engineer-user-alc']['fio-short']}</p>
      <p className="item sixth">
        {item['count-items-fix']}
        /
        {item['count-items-whole']}
      </p>
      <div className="item seventh">
        {getItemsActsNumbers(item.items, additionalClick)}
      </div>
      <p className={`item eights ${item.status !== 'draft' && item['flag-overdue'] && 'overdue'} ${item.status === 'canceled' && 'canceled'}`}>
        {t(item.status)}
        <br />
        {item.status !== 'draft' && item['flag-overdue'] && t('overdue')}
      </p>
      <div className="item ninth">
        <MyButton className="cloud_button" iconUri="/images/cloud.png" onClick={cloudPress} />
      </div>
    </div>
  );
}

export default ItemRow;
