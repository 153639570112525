import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useInputState from '../../../helpers/hooks/useInputState';
import MyInput from '../../../templates/MyInput';
import MyButton from '../../../templates/MyButton';
import CancelButton from '../../../components/CancelButton';
import {
  getUsersRequest,
  saveUserRequest,
  setSingleUser,
} from '../../../store/actions/users';
import generateUserPassword from '../../../helpers/generateUserPassword';
import validateUserPassword from '../../../helpers/validateUserPassword';
import MySelect from '../../../templates/MySelect';
import { getRolesRequest } from '../../../store/actions/roles';
import { getClientsRequest } from '../../../store/actions/clients';
import requestAllData from '../../../helpers/requestAllData';

function SingleUser() {
  const dispatch = useDispatch();
  const { t, lang } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get('user_id');

  const isRoot = useSelector((store) => store.account.isRoot);

  const users = useSelector((store) => store.users.users);
  const singleUser = useSelector((store) => store.users.singleUser);
  const saveLoading = useSelector((store) => store.users.saveLoading);
  const saveError = useSelector((store) => store.users.saveError);

  const roles = useSelector((store) => store.roles.roles);
  const clients = useSelector((store) => store.clients.clients);

  const [mail, setMail, mailError, setMailError] = useInputState();
  const [password, setPassword, passwordError, setPasswordError] = useInputState(userId ? '' : generateUserPassword());
  const [position, setPosition, positionError, setPositionError] = useInputState();
  const [fio, setFio, fioError, setFioError] = useInputState();
  const [role, setRole, roleError, setRoleError] = useInputState();
  const [client, setClient, clientError, setClientError] = useInputState();
  const [otherError, setOtherError] = useState('');

  const [toSave, setToSave] = useState(false);

  useEffect(() => {
    if (!roles?.items) {
      dispatch(getRolesRequest(requestAllData));
    }
    if (isRoot && !clients?.items) {
      dispatch(getClientsRequest(requestAllData));
    }
    if (!userId) {
      return;
    }
    if (!users?.items) {
      dispatch(getUsersRequest(requestAllData));
    }
  }, [isRoot]);

  useEffect(() => {
    if (userId && users?.items) {
      dispatch(setSingleUser(userId));
    }
  }, [users]);

  useEffect(() => {
    if (userId && singleUser?.id) {
      setMail(singleUser.uid);
      setPosition(singleUser.descr);
      setFio(singleUser.fio);
      setRole(singleUser['user-type-id']);
      setClient(singleUser['client-id']);
      setPosition(singleUser.pswd);
    }
  }, [singleUser]);

  useEffect(() => {
    if (toSave && !saveLoading) {
      if (!saveError) {
        goBack();
      } else {
        if (saveError === 'Error! Such user exist!') {
          setMailError(t('user_exists'));
        } else if (saveError === 'Error NextCloud' || saveError === 'Error Stderr') {
          setOtherError(t('user_creation_nextcloud_error'));
        } else {
          setOtherError(t('user_creation_error'));
        }
        setToSave(false);
      }
    }
  }, [saveLoading]);

  const goBack = () => {
    navigate(-1, { replace: true });
  };

  const onSave = () => {
    let flag = false;
    if (!mail) {
      setMailError(t('mail') + t('is_required_j_r'));
      flag = true;
    }
    if (!userId) {
      if (!password) {
        setPasswordError(t('password') + t('is_required_m_r'));
        flag = true;
      } else {
        const passwordErr = validateUserPassword(password);
        if (passwordErr) {
          setPasswordError(t(passwordErr));
          flag = true;
        }
      }
    } else if (password) {
      const passwordErr = validateUserPassword(password);
      if (passwordErr) {
        setPasswordError(t(passwordErr));
        flag = true;
      }
    }

    if (!position) {
      setPositionError(t('user_position') + t('is_required_j_r'));
      flag = true;
    }
    if (!fio) {
      setFioError(t('user_fio') + t('is_required_s_r'));
      flag = true;
    } else {
      const splitFio = fio.split(' ');
      if (splitFio.length !== 3) {
        setFioError(t('fio_3_words'));
        flag = true;
      } else if (splitFio[0].length > 15) {
        setFioError(t('fio_15_symbols'));
        flag = true;
      }
    }
    if (!role) {
      setRoleError(t('role') + t('is_required_j_r'));
      flag = true;
    }
    if (isRoot && !Number.isFinite(+client)) {
      setClientError(t('client2') + t('is_required_m_r'));
      flag = true;
    }

    if (flag) {
      return;
    }

    const data = {
      'user-type-id': role,
      descr: position,
      pswd: password || null,
      uid: mail,
      fio,
    };

    if (userId) {
      data.id = +userId;
    }

    if (isRoot) {
      const currentClient = clients.items.find((item) => item.id === client);

      data['group-tag-name'] = currentClient?.['group-tag-name'];
      data['client-id'] = client;
    }

    if (userId) {
      dispatch(saveUserRequest({ data }));
    } else {
      dispatch(saveUserRequest({ data, save: true }));
    }
    setToSave(true);
  };

  return (
    <div className="single_user_wrapper">
      <SinglePageHeader text={userId ? t('edit_user') : t('add_user')} />
      {users?.items && userId && !singleUser?.id ? (
        <p className="no_object">
          {t('no_user')}
          {userId}
        </p>
      ) : (
        <>
          {!userId && (
            <MyInput wrapperClassName="single_user_input" placeholder={`${t('eg')} user@mail.ru`} type="email" label={t('mail')} value={mail} onChange={setMail} error={mailError} />
          )}
          <MyInput wrapperClassName="single_user_input" placeholder="•••••••••" label={t('password')} value={password} onChange={setPassword} error={passwordError} info={t('pass_info')} />
          <MyInput wrapperClassName="single_user_input" label={t('user_position')} value={position} onChange={setPosition} error={positionError} />
          <MyInput wrapperClassName="single_user_input" label={t('user_fio')} value={fio} onChange={setFio} error={fioError} info={t('user_fio_info')} />
          {isRoot && (
            <MySelect wrapperClassName="single_user_input" label={t('user_client')} readOnly={!!userId} onlyDropDown data={clients?.items} showKey="title" buttonText={t('chose_client')} value={client} onChange={setClient} error={clientError} t={t} />
          )}
          <MySelect wrapperClassName="single_user_input user_role" label={t('user_role')} readOnly={!!userId} onlyDropDown data={roles?.items?.filter((item) => (userId ? true : !item.is_admin))} showKey={lang === 'en' ? 'type_name_en' : 'type_name'} buttonText={t('chose_role')} value={role} onChange={setRole} error={roleError} t={t} />
          <div className="buttons_row">
            <MyButton loading={saveLoading} onClick={onSave} text={userId ? t('save_changes') : t('add_user_button')} />
            <CancelButton loading={saveLoading} />
          </div>
          {otherError && <p className="other_error">{otherError}</p>}
        </>
      )}
    </div>
  );
}

export default SingleUser;
