import React from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../../../../../Api';
import useTranslation from '../../../../../translations';

function DrawingViolItem({ viol, index }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onPrescriptionClick = () => {
    if (viol.predpisan_status === 'draft') {
      navigate(`/prescriptions/edit?prescription_id=${viol.predpisan_id}`);
    } else {
      navigate(`/prescriptions/view?prescription_id=${viol.predpisan_id}`);
    }
  };

  return (
    <div className="drawing_viol_item">
      <div className="left_part">
        <div className="row">
          <span className="number">{index + 1}</span>
          <span className="prescription_title" onClick={onPrescriptionClick}>
            {t('prescription')}
            {' '}
            №
            {' '}
            {viol.predpisan_number_out}
          </span>
        </div>
        <p className="drawing_viol_text">{viol.title}</p>
      </div>
      <div className="image_part">
        <img className="drawing_viol_img" src={Api.viols_img_url_no_request + viol.viol_id} alt="I" />
      </div>
    </div>
  );
}

export default DrawingViolItem;
