import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import texts from './texts.json';
import { setLanguage } from '../store/actions/account';
import LocalStorageServices from '../helpers/LocalStorageServices';

const useTranslation = (defaultLang) => {
  const lang = defaultLang || useSelector((store) => store.account.lang);
  const dispatch = useDispatch();

  return {
    t: (code) => t(code, lang),
    lang,
    setLanguage: (newLang) => dispatch(setLanguage(newLang)),
  };
};

export default useTranslation;

export const t = (code, lang) => {
  if (!code) return '';
  if (!lang) lang = LocalStorageServices.getLang();

  const textsBlock = texts[code];
  if (!textsBlock) return `&${code}`;

  return textsBlock[lang] || textsBlock.en || `&${code}`;
};
