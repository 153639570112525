import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  GET_USERS_FAIL,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS, SAVE_USER_FAIL,
  SAVE_USER_REQUEST, SAVE_USER_SUCCESS,
} from '../actions/users';

export default function* watcher() {
  yield takeLatest(GET_USERS_REQUEST, getUsers);
  yield takeLatest(SAVE_USER_REQUEST, saveUser);
  yield takeLatest(DELETE_USER_REQUEST, deleteUser);
}

function* getUsers(action) {
  try {
    const { data } = yield call(Api.getUsers, action.payload);

    yield put({
      type: GET_USERS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting users');
    console.log(e?.response?.data);
    yield put({
      type: GET_USERS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* saveUser(action) {
  try {
    let data;
    if (action.payload.save) {
      const res = yield call(Api.addUser, action.payload.data);
      data = res.data;
    } else {
      const res = yield call(Api.editUser, action.payload.data);
      data = res.data;
    }

    yield put({
      type: SAVE_USER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while saving user');
    console.log(e?.response?.data);
    yield put({
      type: SAVE_USER_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* deleteUser(action) {
  try {
    const { id, requestData } = action.payload;
    yield call(Api.deleteUser, id);
    yield put({
      type: GET_USERS_REQUEST,
      payload: requestData,
    });
  } catch (e) {
    console.log(e, 'while deleting user');
    console.log(e?.response?.data);
    yield put({
      type: DELETE_USER_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
