import React, { useEffect, useState } from 'react';
import '../css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import MyLoading from '../../../../templates/MyLoading';
import { COLORS } from '../../../../helpers/constants';
import useTranslation from '../../../../translations';
import MySelect from '../../../../templates/MySelect';
import MyInput from '../../../../templates/MyInput';
import useInputState from '../../../../helpers/hooks/useInputState';
import priorityData from '../../../../helpers/localData/priorityData';
import MyButton from '../../../../templates/MyButton';
import Api from '../../../../Api';
import CancelButton from '../../../../components/CancelButton';
import { savePrescriptionRequest } from '../../../../store/actions/prescriptions';
import MyCheckbox from '../../../../templates/MyCheckbox';
import AgreementPrescription from './AgreementPrescription';
import PrescriptionViols from '../../../../components/PrescriptionViols';

function EditPrescription({ paramsId, isAssignedToThisPrescr }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const profile = useSelector((store) => store.account.profile);

  const prescription = useSelector((store) => store.prescriptions.singlePrescription);
  const gettingLoading = useSelector((store) => store.prescriptions.gettingSinglePrescriptionLoading);
  const gettingError = useSelector((store) => store.prescriptions.gettingSinglePrescriptionError);

  const savingLoading = useSelector((store) => store.prescriptions.savingPrescriptionLoading);
  const savingError = useSelector((store) => store.prescriptions.savingPrescriptionError);

  const [date, setDate, dateError, setDateError] = useInputState();
  const [priority, setPriority, priorityError, setPriorityError] = useInputState('low');
  const [viols, setViols, violsError, setViolsError] = useInputState([]);
  const [items, setItems, itemsError, setItemsError] = useInputState([]);

  const [signatoryUsers, setSignatoryUsers] = useState([]);
  const [agreementUsers, setAgreementUsers] = useState([]);
  const [needAgreement, setNeedAgreement] = useState(false);

  const [signatoryUser, setSignatoryUser, signatoryUserError, setSignatoryUserError] = useInputState();
  const [agreementUser, setAgreementUser, agreementUserError, setAgreementUserError] = useInputState();

  const [toSave, setToSave] = useState(false);

  useEffect(() => {
    (async () => {
      if (prescription?.items) {
        setDate(prescription['date-out']);
        setPriority(prescription.priority);
        setViols(prescription.viols.map((item) => ({
          ...item,
          text: item.title,
        })), true);
        setItems(prescription.items.map((item) => ({
          ...item,
          text: item.title,
          itemDate: item['date-exp'],
        })), true);
        if (prescription['signatory-user-alc-id']) {
          setSignatoryUser(prescription['signatory-user-alc-id']);
        }
        if (prescription['agreement-user-alc-id']) {
          setNeedAgreement(true);
          setAgreementUser(prescription['agreement-user-alc-id']);
        }
      }

      if (!prescription?.items) return;
      const { data: sign } = await Api.getAllocs({
        'filter-val': {
          'build-comp-id': prescription['build-comp-id'],
          'is-constructor': true,
          'project-id': prescription['project-id'],
        },
        limit: 100,
        offset: 0,
        'page-number': 0,
        'search-val': '',
      });
      setSignatoryUsers(sign);

      const { data: agreement } = await Api.getAllocs({
        'filter-val': {
          'build-comp-id': prescription['build-comp-id'],
          'is-chief': true,
          'project-id': prescription['project-id'],
        },
        limit: 100,
        offset: 0,
        'page-number': 0,
        'search-val': '',
      });
      setAgreementUsers(agreement);
    })();
  }, [prescription]);

  const handleNeedAgreement = (val) => {
    if (needAgreement) {
      setAgreementUser(null, true);
    }
    setNeedAgreement(val);
  };

  useEffect(() => {
    if (!items?.length || (items?.length && items[items.length - 1]?.text)) {
      setItems((prev) => ([...prev, { text: '', itemDate: moment(date).format('yyyy-MM-DD') }]), true);
    }
  }, [items]);

  const updateItems = (index) => (e) => {
    const newItems = items.map((item, i) => {
      if (index === i) {
        return { ...item, [e.target.name]: e.target.value };
      }
      return item;
    });
    setItems(newItems, true);
  };

  const itemsFocusOut = (index) => (ev) => {
    if (!ev.target.value && index !== items.length - 1) {
      setItems((prev) => prev.filter((el, i) => i !== index), true);
    }
  };

  const removeItem = (index) => () => {
    const itemToDelete = items.find((el, i) => i === index);
    itemToDelete['flag-del'] = true;
    setItems([...items], true);
  };

  const handlePriority = (ev) => {
    setPriority(ev);
    if (ev.target.value === 'low') {
      if (needAgreement) {
        setNeedAgreement(false);
      }
      setSignatoryUser(null, true);
      setAgreementUser(null, true);
    }
  };

  const handleDate = (ev) => {
    setDate(ev);
    if (items.length === 1) {
      setItems([], true); // useEffect will catch
    }
  };

  const save = (status = 'draft') => {
    const isPriority = priority !== 'low';
    const files = [];

    const formattedViols = viols.filter(({ text }) => text.trim()).map((item, index) => {
      if (item['flag-del'] && !item['viol-id']) {
        return;
      }

      const uniqueId = uuidv4();
      if (item.file) {
        files.push({ uid: uniqueId, file: item.file });
      }

      const data = {
        'flag-del': false,
        ...item,
        title: item.text,
        'sort-number': index,
        'temp-uid': '',
        'picture-file': item['picture-file'] || '',
        drawing: {
          draw_id: null, point_top: null, point_left: null, point_direction: null,
        },
      };

      if (item.drawing) {
        data.drawing = item.drawing;
      }

      if (item.file?.name) {
        data['picture-file'] = item.file.name;
        data['temp-uid'] = uniqueId;
      }

      delete data.file;
      delete data.udate;
      delete data.cdate;

      return data;
    }).filter((item) => item);

    const formattedItems = items.filter(({ text }) => text.trim()).map((item, index) => {
      if (item['flag-del'] && !item['item-id']) {
        return;
      }

      return ({
        'flag-del': false,
        ...item,
        title: item.text,
        'date-exp': moment(item.itemDate) > moment() ? item.itemDate : moment().format('YYYY-MM-DD'),
        'sort-number': index,
      });
    }).filter((item) => item);

    if (status !== 'draft') {
      let flag = false;

      if (!date) {
        setDateError(t('date') + t('is_required_j_r'));
        flag = true;
      }
      if (isPriority) {
        if (!signatoryUser) {
          setSignatoryUserError(t('signatory_user') + t('is_required_m_r'));
          flag = true;
        }
        if (needAgreement && !agreementUser) {
          setAgreementUserError(t('agreement_user') + t('is_required_s_r'));
          flag = true;
        }
      }

      if (!formattedViols.length) {
        setViolsError(t('fill_one'));
        flag = true;
      }

      if (!formattedItems.length) {
        setItemsError(t('fill_one'));
        flag = true;
      }

      if (flag) return;
    }

    const payload = {
      id: prescription.id,
      'next-id': null, //----------
      title: '', //----------
      'number-n': 0, //----------
      descr: '', //---------
      'scan-file': null, //---------
      'date-out': moment(date).format('yyyy-MM-DD'),
      'date-exp': moment(date).format('yyyy-MM-DD'),
      'number-out': prescription['number-out'],
      status,
      priority,
      'engineer-user-alc-id': prescription['engineer-user-alc-id'],
      'agreement-user-alc-id': agreementUser || null,
      'signatory-user-alc-id': signatoryUser || null,
      'build-obj-id': prescription['build-obj-id'],
      'build-obj-title': prescription['build-obj-title'],
      'project-title': prescription['project-title'],
      'project-id': prescription['project-id'],
      viols: formattedViols,
      items: formattedItems,
    };

    console.log({ payload });
    const formData = new FormData();
    formData.append('body', JSON.stringify(payload));

    files.forEach(({ uid, file }) => {
      formData.append(uid, file);
    });

    setToSave(true);
    dispatch(savePrescriptionRequest(formData));
  };

  useEffect(() => {
    if (!savingLoading && toSave) {
      if (!savingError) {
        navigate('..', { replace: true });
      } else {
        setToSave(false);
      }
    }
  }, [savingLoading]);

  return (
    gettingLoading ? <MyLoading color={COLORS.main} /> : gettingError ? (
      <p>
        {t('no_prescription_with_id')}
        {paramsId}
      </p>
    ) : (
      <div>
        <div className="single_prescription_item row">
          <p className="prescription_item_title prescription_margin_right">
            {t('engineer')}
            :
          </p>
          <p className="prescription_simple_text">
            {prescription && prescription['engineer-user-alc']?.fio}
          </p>
        </div>
        <div className="single_prescription_item row">
          <p className="prescription_item_title prescription_margin_right">
            {t('project')}
            :
          </p>
          <p className="prescription_simple_text">
            {prescription && prescription['project-title']}
          </p>
        </div>
        <div className="single_prescription_item row">
          <p className="prescription_item_title prescription_margin_right">
            {t('object')}
            :
          </p>
          <p className="prescription_simple_text">
            {prescription && `${prescription['build-obj']?.address} (${prescription['build-obj-title']})`}
          </p>
        </div>
        <div className="single_prescription_item row">
          <p className="prescription_item_title prescription_margin_right">
            {t('number')}
            :
          </p>
          <p className="prescription_simple_text">
            {prescription && prescription['number-out']}
          </p>
        </div>
        <div className="line" />
        <MyInput wrapperClassName="single_prescription_item_big default_size" label={`${t('date_of_issue')}:`} value={date} onChange={handleDate} error={dateError} type="date" min={moment().format('YYYY-MM-DD')} />
        <div className="single_prescription_item_big">
          <p className="prescription_item_title prescription_margin_right">
            {t('priority')}
            :
          </p>
          <div className="default_size priority_row">
            {priorityData.map(({ name, color }) => (
              <div key={name} className="priority_item">
                <input type="radio" id={`priority_${name}`} name="priority_radio" value={name} onChange={handlePriority} checked={name === priority} />
                <label className="priority_label" htmlFor={`priority_${name}`}>
                  <span className={`circle ${color}`} />
                  <span>{t(name)}</span>
                </label>
              </div>
            ))}
          </div>
        </div>
        <p className="comment">{t('precr_comment_1_first') + (prescription && prescription['build-obj-title']) + t('precr_comment_1_second')}</p>
        <div className="single_prescription_item_big">
          <p className="prescription_item_title prescription_margin_right">
            {t('revealed')}
            :
          </p>
          <PrescriptionViols
            viols={viols}
            setViols={setViols}
            violsError={violsError}
            buildObj={prescription['build-obj']}
          />
        </div>
        <p className="comment">{t('precr_comment_2')}</p>
        <div className="single_prescription_item_big">
          <div className="row">
            <p className="prescription_item_title prescription_margin_right margin_right default_size">
              {t('prescribe')}
              :
            </p>
            {items[0]?.text && (
            <p className="prescription_item_title prescription_margin_right">
              {t('deadline')}
              :
            </p>
            )}
          </div>
          {items?.length && items.map((item, index) => (
            !item['flag-del'] && (
              <div className="row" key={index}>
                <MyInput name="text" error={itemsError} className="input_main" wrapperClassName="input_item default_size" value={item.text} onChange={updateItems(index)} onBlur={itemsFocusOut(index)} />
                {item.text && <MyInput name="itemDate" value={item.itemDate} wrapperClassName="input_item secondary" className="input_date" onChange={updateItems(index)} type="date" min={moment(date).format('YYYY-MM-DD')} />}
                {item.text && <MyButton className="close_button" iconUri="/images/close.png" onClick={removeItem(index)} />}
              </div>
            )
          ))}
        </div>
        {priority !== 'low' && (
          <div>
            <div className="single_prescription_item_big">
              <p className="prescription_item_title prescription_margin_right">
                {t('signatory_user')}
                :
              </p>
              <MySelect wrapperClassName="default_size" data={signatoryUsers?.items} showKey={['fio-short', 'user-uid', 'role']} buttonText={t('choose_signatory')} error={signatoryUserError} value={signatoryUser} onChange={setSignatoryUser} onlyDropDown t={t} />
            </div>
            {profile?.id !== prescription?.['agreement-user-alc']?.['user-id'] && (
              <div className="single_prescription_item_big">
                <p className="prescription_item_title prescription_margin_right">
                  {t('agreement_user')}
                  :
                </p>
                <MyCheckbox className="need_agreement" value={needAgreement} onChange={handleNeedAgreement} label={t('need_agreement')} />
                {needAgreement && (
                  <MySelect wrapperClassName="default_size" data={agreementUsers?.items} showKey={['fio-short', 'user-uid', 'role']} buttonText={t('choose_agreement')} error={agreementUserError} value={agreementUser} onChange={setAgreementUser} onlyDropDown t={t} />
                )}
              </div>
            )}
          </div>
        )}
        {isAssignedToThisPrescr ? (
          <AgreementPrescription prescription={prescription} onApproveFunction={() => save('signing')} loadingProp={savingLoading} />
        ) : (
          <div className="row">
            {priority === 'low' ? (
              <MyButton text={t('save_active_force')} onClick={() => save('active-force')} loading={savingLoading} />
            ) : needAgreement ? (
              <MyButton text={t('save_agreement')} onClick={() => save('agreement')} loading={savingLoading} />
            ) : (
              <MyButton text={t('save_signing')} onClick={() => save('signing')} loading={savingLoading} />
            )}
            <MyButton text={t('save_as_draft')} onClick={() => save('draft')} className="margin_left" loading={savingLoading} />
            <CancelButton loading={savingLoading} />
          </div>
        )}

        <div style={{ height: 100 }} />
      </div>
    )
  );
}

export default EditPrescription;
