import React from 'react';
import './css/index.scss';

function SortTypeFilter({ data, value, onChange }) {
  return (
    <div className="sort_type_filter">
      <input className="trigger" type="button" value={value.name} />
      <div className="showing">
        {data.map((item) => (
          <div
            key={item.key}
            onMouseDown={() => onChange(item)}
            className={`item ${item.key === value.key ? 'active' : ''}`}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SortTypeFilter;
