import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import SinglePageHeader from '../../../components/SinglePageHeader';
import MySelect from '../../../templates/MySelect';
import langData from '../../../helpers/localData/langData';
import useTranslation from '../../../translations';
import {
  getMyAllocationsRequest,
  getProfileRequest, setMyScheduleRequest,
} from '../../../store/actions/account';
import MyButton from '../../../templates/MyButton';
import MyAllocationsPart from './components/MyAllocationsPart';
import ScheduleItem from './components/ScheduleItem';
import useScheduleState from '../../../helpers/hooks/useScheduleState';
import CancelButton from '../../../components/CancelButton';
import { ROLES } from '../../../helpers/constants';
import PasswordChangingModal from './components/PasswordChangingModal';

function Profile() {
  const dispatch = useDispatch();
  const { t, lang, setLanguage } = useTranslation();
  const role = useSelector((store) => store.account.role);
  const profile = useSelector((store) => store.account.profile);

  const myAllocations = useSelector((store) => store.account.myAllocations);
  const gettingAllocationsLoading = useSelector((store) => store.account.gettingMyAllocationsLoading);

  const settingMyScheduleLoading = useSelector((store) => store.account.settingMyScheduleLoading);

  const [monStart, setMonStart, monEnd, setMonEnd, monEndData] = useScheduleState();
  const [tueStart, setTueStart, tueEnd, setTueEnd, tueEndData] = useScheduleState();
  const [wedStart, setWedStart, wedEnd, setWedEnd, wedEndData] = useScheduleState();
  const [thuStart, setThuStart, thuEnd, setThuEnd, thuEndData] = useScheduleState();
  const [friStart, setFriStart, friEnd, setFriEnd, friEndData] = useScheduleState();
  const [satStart, setSatStart, satEnd, setSatEnd, satEndData] = useScheduleState();
  const [sunStart, setSunStart, sunEnd, setSunEnd, sunEndData] = useScheduleState();

  const [isChangingPasswordModalVisible, setIsChangingPasswordModalVisible] = useState(false);

  const handleLanguage = (newId) => {
    const newLang = langData.find((item) => item.id === newId)?.code;
    if (newLang) {
      setLanguage(newLang);
    }
  };

  const onChangePasswordClick = () => {
    setIsChangingPasswordModalVisible(true);
  };

  const onSaveClick = () => {
    if (role === ROLES.stroymanager) {
      const payload = {
        sun_start: sunStart.toString(),
        sun_stop: sunEnd.toString(),
        mon_start: monStart.toString(),
        mon_stop: monEnd.toString(),
        tue_start: tueStart.toString(),
        tue_stop: tueEnd.toString(),
        wen_start: wedStart.toString(),
        wen_stop: wedEnd.toString(),
        thu_start: thuStart.toString(),
        thu_stop: thuEnd.toString(),
        fri_start: friStart.toString(),
        fri_stop: friEnd.toString(),
        sat_start: satStart.toString(),
        sat_stop: satEnd.toString(),
      };

      dispatch(setMyScheduleRequest(payload));
    }
  };

  const onCancelClick = () => {
    dispatch(getProfileRequest());
  };

  useEffect(() => {
    if (role === ROLES.stroymanager) {
      dispatch(getMyAllocationsRequest());
    }
  }, [role]);

  useEffect(() => {
    if (role === ROLES.stroymanager && profile?.['mon-start']) {
      setMonStart(profile['mon-start'], false);
      setMonEnd(profile['mon-stop'], profile['mon-start']);

      setTueStart(profile['tue-start'], false);
      setTueEnd(profile['tue-stop'], profile['tue-start']);

      setWedStart(profile['wen-start'], false);
      setWedEnd(profile['wen-stop'], profile['wen-start']);

      setThuStart(profile['thu-start'], false);
      setThuEnd(profile['thu-stop'], profile['thu-start']);

      setFriStart(profile['fri-start'], false);
      setFriEnd(profile['fri-stop'], profile['fri-start']);

      setSatStart(profile['sat-start'], false);
      setSatEnd(profile['sat-stop'], profile['sat-start']);

      setSunStart(profile['sun-start'], false);
      setSunEnd(profile['sun-stop'], profile['sun-start']);
    }
  }, [profile]);

  useEffect(() => {
    dispatch(getProfileRequest());
  }, []);

  return (
    <div className="profile_wrapper">
      <SinglePageHeader text={profile?.fio} withGoBack={false} />
      <MySelect
        wrapperClassName="language_select"
        label={t('change_lang')}
        withTranslation
        t={t}
        abilityToClear={false}
        onlyDropDown
        value={langData.find((item) => item.code === lang)?.id}
        data={langData}
        showKey="name"
        onChange={handleLanguage}
      />
      <p className="password_label">{t('password')}</p>
      <MyButton
        text={t('change')}
        onClick={onChangePasswordClick}
        className="password_button"
      />
      {role === ROLES.stroymanager && (
        <>
          <MyAllocationsPart
            data={myAllocations}
            loading={gettingAllocationsLoading}
          />
          <div className="schedule_part">
            <p className="schedule_title">{t('schedule_title')}</p>
            <ScheduleItem
              label={t('monday')}
              start={monStart}
              setStart={setMonStart}
              end={monEnd}
              setEnd={setMonEnd}
              endData={monEndData}
            />
            <ScheduleItem
              label={t('tuesday')}
              start={tueStart}
              setStart={setTueStart}
              end={tueEnd}
              setEnd={setTueEnd}
              endData={tueEndData}
            />
            <ScheduleItem
              label={t('wednesday')}
              start={wedStart}
              setStart={setWedStart}
              end={wedEnd}
              setEnd={setWedEnd}
              endData={wedEndData}
            />
            <ScheduleItem
              label={t('thursday')}
              start={thuStart}
              setStart={setThuStart}
              end={thuEnd}
              setEnd={setThuEnd}
              endData={thuEndData}
            />
            <ScheduleItem
              label={t('friday')}
              start={friStart}
              setStart={setFriStart}
              end={friEnd}
              setEnd={setFriEnd}
              endData={friEndData}
            />
            <ScheduleItem
              label={t('saturday')}
              start={satStart}
              setStart={setSatStart}
              end={satEnd}
              setEnd={setSatEnd}
              endData={satEndData}
            />
            <ScheduleItem
              label={t('sunday')}
              start={sunStart}
              setStart={setSunStart}
              end={sunEnd}
              setEnd={setSunEnd}
              endData={sunEndData}
            />
          </div>

          <div className="buttons_row">
            <MyButton
              text={t('save')}
              className="save_button"
              onClick={onSaveClick}
              loading={settingMyScheduleLoading}
            />
            <CancelButton
              onClick={onCancelClick}
              text={t('cancel_changes') + t('of_schedule')}
              loading={settingMyScheduleLoading}
            />
          </div>
        </>
      )}

      <PasswordChangingModal
        isVisible={isChangingPasswordModalVisible}
        setIsVisible={setIsChangingPasswordModalVisible}
        profile={profile}
      />
    </div>
  );
}

export default Profile;
