import React from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getEventsRequest } from '../../../store/actions/events';
import SortableList from '../../../templates/SortableList';
import HeaderRow from './components/HeaderRow';
import ItemRow from './components/ItemRow';
import useSortTypes from './components/sortTypes';

function Events() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const events = useSelector((store) => store.events.events);
  const eventsLoading = useSelector((store) => store.events.eventsLoading);
  const sortTypes = useSortTypes();

  const getEvents = (data) => {
    dispatch(getEventsRequest(data));
  };

  const onItemClick = (item) => {
    if (item['document-type'] === 'predpisan') {
      if (item.status === 'draft') {
        navigate(`/prescriptions/edit?prescription_id=${item['document-id']}`);
      } else {
        navigate(`/prescriptions/view?prescription_id=${item['document-id']}`);
      }
    } else if (item.status === 'draft') {
      navigate(`/acts/edit?act_id=${item['document-id']}`);
    } else {
      navigate(`/acts/view?act_id=${item['document-id']}`);
    }
  };

  return (
    <div className="events_wrapper">
      <SortableList
        data={events}
        loading={eventsLoading}
        requestData={getEvents}
        sortTypes={sortTypes}
        withSorting
        HeaderRow={HeaderRow}
        renderItem={ItemRow}
        onItemClick={onItemClick}
      />
    </div>
  );
}

export default Events;
