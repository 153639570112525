import React from 'react';

function SelectedLetterheadItem(item) {
  return (
    <div className="selected_letterhead">
      <p className="selected_letterhead_title">{item?.title}</p>
      <p className="selected_letterhead_info">{item?.address}</p>
      <p className="selected_letterhead_info">{item && item['legal-name']}</p>
    </div>
  );
}

export default SelectedLetterheadItem;
