import React from 'react';
import './css/index.scss';

function PagesPart({
  data, pagesCount, changePage, page,
}) {
  return (
    data?.total > 4 ? (
      <div className="page_row">
        {new Array(pagesCount || 0).fill('').map((_, index) => (
          <div onClick={() => changePage(index)} className={`page_item ${index === page && 'page_item_active'}`} key={`page -${index}`}>{index + 1}</div>
        ))}
      </div>
    ) : null
  );
}

export default PagesPart;
