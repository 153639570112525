import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import useTranslation from '../../../../../translations';
import MyButton from '../../../../../templates/MyButton';
import MySelect from '../../../../../templates/MySelect';
import useInputState from '../../../../../helpers/hooks/useInputState';
import MyInput from '../../../../../templates/MyInput';
import MyCheckbox from '../../../../../templates/MyCheckbox';
import { deleteDrawingRequest, editDrawingRequest } from '../../../../../store/actions/drawings';
import MyModal from '../../../../../templates/MyModal';
import Api from '../../../../../Api';
import drawings from '../../../Drawings';
import MyImg from '../../../../../templates/MyImg';

function EditDrawingItem({
  drawing, index, isLastIndex, gaBackAfterAction,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const editingLoading = useSelector((store) => store.drawings.editingDrawingLoading);
  const editingError = useSelector((store) => store.drawings.editingDrawingError);

  const deletingLoading = useSelector((store) => store.drawings.deletingDrawingLoading);
  const deletingError = useSelector((store) => store.drawings.deletingDrawingError);

  const projects = useSelector((store) => store.projects.projects);
  const objects = useSelector((store) => store.objects.objects);

  const [project, setProject, projectError, setProjectError] = useInputState();
  const [object, setObject, objectError, setObjectError] = useInputState();
  const [filteredObjects, setFilteredObjects] = useState([]);

  const [title, setTitle, titleError, setTitleError] = useInputState();
  const [isArchive, setIsArchive] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [toEdit, setToEdit] = useState(false);
  const [toDelete, setToDelete] = useState(false);

  const onDelete = () => {
    setToDelete(true);
    dispatch(deleteDrawingRequest(drawing?.id));
    closeDeleteModal();
  };

  const openDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const onSave = () => {
    let flag = false;

    if (!title) {
      setTitleError(t('title') + t('is_required_s_r'));
      flag = true;
    }
    if (!project) {
      setProjectError(t('project') + t('is_required_m_r'));
      flag = true;
    }
    if (!object) {
      setObjectError(t('object') + t('is_required_m_r'));
      flag = true;
    }

    if (flag) {
      return;
    }

    const payload = {
      id: drawing?.id,
      title,
      mark_arch: isArchive,
      project_id: project,
      build_objs_id: object,
    };

    setToEdit(true);
    dispatch(editDrawingRequest(payload));
  };

  const handleProject = (val) => {
    setProject(val, true);
    if (val) {
      setFilteredObjects(objects?.items?.filter((item) => item['project-id'] === val));
    } else {
      setFilteredObjects([]);
    }
    if (objectError) {
      setObjectError(false);
    }
  };

  useEffect(() => {
    if (drawing?.id) {
      handleProject(drawing.project_id);
      setObject(drawing.build_objs_id);
      setTitle(drawing.title || drawing.draw_title);
      setIsArchive(!!drawing.mark_arch);
    }
  }, []);

  useEffect(() => {
    if (!editingLoading && toEdit) {
      setToEdit(false);

      if (!editingError && gaBackAfterAction) {
        navigate('..');
      }
    }
  }, [editingLoading]);

  useEffect(() => {
    if (!deletingLoading && toDelete) {
      setToDelete(false);

      if (!deletingError && gaBackAfterAction) {
        navigate('..');
      }
    }
  }, [deletingLoading]);

  return (
    <div key={index} className={`edit_drawing_item_wrapper ${isLastIndex && 'last'}`}>
      {Number.isFinite(index) && (
      <p className="drawing_item_title">
        {t('page')}
        {' '}
        {index + 1}
      </p>
      )}
      <div className="drawing_item_row">
        <div className="drawing_item_left">
          <div className="drawing_thumbnail_block">
            <img src={Api.drawings_url_no_request + drawing.id} alt="A" className="drawing_thumbnail" />
          </div>
          <MyButton
            text={t('delete')}
            disabled={editingLoading}
            loading={deletingLoading}
            className="edit_drawing_delete_button"
            onClick={openDeleteModal}
          />
        </div>
        <div className="drawing_item_right">
          <MySelect
            data={projects?.items}
            label={`${t('project')}:`}
            buttonText={`${t('choose_project')}`}
            value={project}
            onChange={handleProject}
            wrapperClassName="edit_drawing_select"
            onlyDropDown
            error={projectError}
            t={t}
          />
          <MySelect
            data={filteredObjects}
            label={`${t('object')}:`}
            buttonText={`${t('choose_object')}`}
            value={object}
            onChange={setObject}
            wrapperClassName="edit_drawing_select"
            onlyDropDown
            error={objectError}
            t={t}
          />
          <MyInput
            value={title}
            onChange={setTitle}
            label={`${t('title')}:`}
            wrapperClassName="edit_drawing_input"
            error={titleError}
          />
          <div className="date_block">
            <p>{`${t('upload_date')}:`}</p>
            <span>{moment(drawing?.cdate).format('YYYY-MM-DD HH:mm')}</span>
          </div>
          <MyCheckbox
            value={isArchive}
            onChange={setIsArchive}
            className="edit_drawing_checkbox"
            label={t('archival')}
          />
          <MyButton
            text={t('save')}
            className="edit_drawing_save_button"
            onClick={onSave}
            loading={editingLoading}
            disabled={deletingLoading}
          />
        </div>
      </div>

      {isDeleteModalVisible && (
        <MyModal isOpen={isDeleteModalVisible} closeFunction={closeDeleteModal}>
          <div className="delete_drawing_wrapper">
            <p className="delete_drawing_text">{t('sure_to_delete_drawing')}</p>
            <p className="delete_drawing_info">{title}</p>
            <div className="delete_drawing_row">
              <MyButton
                text={t('delete')}
                onClick={onDelete}
              />
              <MyButton
                text={t('cancel')}
                onClick={closeDeleteModal}
              />
            </div>
          </div>
        </MyModal>
      )}
    </div>
  );
}

export default EditDrawingItem;
