import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  DELETE_LETTERHEAD_FAIL,
  DELETE_LETTERHEAD_REQUEST,
  GET_LETTERHEADS_FAIL,
  GET_LETTERHEADS_REQUEST,
  GET_LETTERHEADS_SUCCESS, SAVE_LETTERHEAD_FAIL,
  SAVE_LETTERHEAD_REQUEST, SAVE_LETTERHEAD_SUCCESS,
} from '../actions/letterheads';

export default function* watcher() {
  yield takeLatest(GET_LETTERHEADS_REQUEST, getLetterheads);
  yield takeLatest(SAVE_LETTERHEAD_REQUEST, saveLetterhead);
  yield takeLatest(DELETE_LETTERHEAD_REQUEST, deleteLetterhead);
}

function* getLetterheads(action) {
  try {
    const { data } = yield call(Api.getLetterheads, action.payload);

    yield put({
      type: GET_LETTERHEADS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting letterheads');
    console.log(e?.response?.data);
    yield put({
      type: GET_LETTERHEADS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* saveLetterhead(action) {
  try {
    let data;
    if (action.payload.save) {
      const res = yield call(Api.addLetterhead, action.payload.data);
      data = res.data;
    } else {
      const res = yield call(Api.editLetterhead, action.payload.data);
      data = res.data;
    }

    yield put({
      type: SAVE_LETTERHEAD_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while saving letterhead');
    console.log(e?.response?.data);
    yield put({
      type: SAVE_LETTERHEAD_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* deleteLetterhead(action) {
  try {
    const { id, requestData } = action.payload;
    yield call(Api.deleteLetterhead, id);
    yield put({
      type: GET_LETTERHEADS_REQUEST,
      payload: requestData,
    });
  } catch (e) {
    console.log(e, 'while deleting letterhead');
    console.log(e?.response?.data);
    yield put({
      type: DELETE_LETTERHEAD_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
