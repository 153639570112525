import React from 'react';
import '../css/index.scss';
import moment from 'moment';
import MyButton from '../../../../templates/MyButton';
import { CHECKUP_STATUSES } from '../../../../helpers/constants';

function ItemRow({
  item,
  index,
  onItemEdit,
  showModal,
  t,
  loading,
  isAdmin,
  onItemClick,
  additionalCLick,
}) {
  const isOrder = [CHECKUP_STATUSES.requested, CHECKUP_STATUSES.wait].includes(item.status);
  const canBeModified = item.status === CHECKUP_STATUSES.requested;

  if (item.status === CHECKUP_STATUSES.deleted) {
    return null;
  }

  return (
    <div className={`item_row ${onItemClick && 'clickable'}`} key={item.id} onClick={onItemClick ? () => onItemClick(item.id, canBeModified) : undefined}>
      <div className="item second">
        {isOrder ? <p className="order_text">{t('order').toUpperCase()}</p> : item.id}
      </div>
      <p className="item third column">
        {t(item.status)}
        <span className="appointment_date">
          {item.status === CHECKUP_STATUSES.wait && `\n${item.appointment_date}`}
        </span>
      </p>
      <p className="item fourth">{moment(item.udate).format('YYYY-MM-DD HH:mm:ss')}</p>
      <p className="item fifth">{isOrder ? item.request : item.template_title}</p>
      <p className="item sixth pressable">
        {item.object_title}
      </p>
      <p className="item eights">
        {item.engineer_fio}
      </p>
      <div className="item ninth">
        {item.fio}
      </div>
      <div className="item tenth">
        {item.mark_true}
        /
        {item.mark_true + item.mark_false + item.mark_null}
        {item.number_out && (
          <>
            {' '}
            (
            <span className="prescription_number" onClick={(ev) => additionalCLick(ev, item.predpisan_id)}>{item.number_out}</span>
            )
          </>
        )}
      </div>
    </div>
  );
}

export default ItemRow;
