export default [
  {
    name: 'low',
    color: 'green',
  },
  {
    name: 'medium',
    color: 'yellow',
  },
  {
    name: 'critical',
    color: 'red',
  },
];
