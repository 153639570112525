import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { add } from 'resolve-url-loader/lib/position-algerbra';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useInputState from '../../../helpers/hooks/useInputState';
import MyInput from '../../../templates/MyInput';
import MyButton from '../../../templates/MyButton';
import CancelButton from '../../../components/CancelButton';
import {
  getCompaniesRequest,
  saveCompanyRequest,
  setSingleCompany,
} from '../../../store/actions/companies';
import requestAllData from '../../../helpers/requestAllData';

function SingleCompany() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('company_id');

  const companies = useSelector((store) => store.companies.companies);
  const singleCompany = useSelector((store) => store.companies.singleCompany);
  const saveLoading = useSelector((store) => store.companies.saveLoading);
  const saveError = useSelector((store) => store.companies.saveError);

  const [title, setTitle, titleError, setTitleError] = useInputState();
  const [desc, setDesc, descError, setDescError] = useInputState();
  const [address, setAddress, addressError, setAddressError] = useInputState();
  const [legalData, setLegalData, legalDataError, setLegalDataError] = useInputState();
  const [fio, setFio, fioError, setFioError] = useInputState();
  const [position, setPosition, positionError, setPositionError] = useInputState();
  const [toSave, setToSave] = useState(false);

  useEffect(() => {
    if (!companyId) {
      return;
    }
    if (!companies?.items) {
      dispatch(getCompaniesRequest(requestAllData));
    }
  }, []);

  useEffect(() => {
    if (companyId && companies?.items) {
      dispatch(setSingleCompany(companyId));
    }
  }, [companies]);

  useEffect(() => {
    if (companyId && singleCompany?.id) {
      setTitle(singleCompany?.title);
      setDesc(singleCompany?.descr);
      setAddress(singleCompany?.address);
      setLegalData(singleCompany?.legal_data);
      setFio(singleCompany?.chief);
      setPosition(singleCompany?.chief_role);
    }
  }, [singleCompany]);

  useEffect(() => {
    if (toSave && !saveLoading) {
      if (!saveError) {
        goBack();
      } else {
        setToSave(false);
      }
    }
  }, [saveLoading]);

  const goBack = () => {
    navigate(-1, { replace: true });
  };

  const onSave = () => {
    let flag = false;
    if (!title) {
      setTitleError(t('title') + t('is_required_s_r'));
      flag = true;
    }
    if (!desc) {
      setDescError(t('description') + t('is_required_s_r'));
      flag = true;
    }
    if (!address) {
      setAddressError(t('address') + t('is_required_m_r'));
      flag = true;
    }
    if (!legalData) {
      setLegalDataError(t('legal_data') + t('is_required_mn_ch'));
      flag = true;
    }
    if (!fio) {
      setFioError(t('fio') + t('is_required_s_r'));
      flag = true;
    }
    if (!position) {
      setPositionError(t('position') + t('is_required_j_r'));
      flag = true;
    }

    if (flag) {
      return;
    }

    const data = {
      title,
      descr: desc,
      address,
      chief: fio,
      'legal-data': legalData,
      'chief-role': position,
    };

    if (companyId) {
      data.id = companyId;
      dispatch(saveCompanyRequest({ data }));
    } else {
      dispatch(saveCompanyRequest({ data, save: true }));
    }
    setToSave(true);
  };

  return (
    <div className="single_company_wrapper">
      <SinglePageHeader text={companyId ? t('edit_comp') : t('add_comp')} />
      {companies?.items && companyId && !singleCompany?.id ? (
        <p className="no_object">
          {t('no_company')}
          {companyId}
        </p>
      ) : (
        <>
          <MyInput wrapperClassName="single_company_input" label={t('title')} value={title} onChange={setTitle} error={titleError} />
          <MyInput wrapperClassName="single_company_input" label={t('description')} value={desc} onChange={setDesc} error={descError} />
          <MyInput wrapperClassName="single_company_input" label={t('address')} value={address} onChange={setAddress} error={addressError} />
          <MyInput wrapperClassName="single_company_input" label={t('legal_data')} value={legalData} onChange={setLegalData} error={legalDataError} />
          <MyInput wrapperClassName="single_company_input" label={t('fio')} value={fio} onChange={setFio} error={fioError} info={t('fio_info')} />
          <MyInput wrapperClassName="single_company_input" label={t('position')} value={position} onChange={setPosition} error={positionError} info={t('position_info')} />
          <div className="buttons_row">
            <MyButton loading={saveLoading} onClick={onSave} text={companyId ? t('edit_comp_button') : t('add_comp_button')} />
            <CancelButton loading={saveLoading} />
          </div>
        </>
      )}
    </div>
  );
}

export default SingleCompany;
