import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_FAIL,
  GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_REQUEST,
  GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_SUCCESS,
  GET_CHECKLISTS_CATEGORIES_FAIL,
  GET_CHECKLISTS_CATEGORIES_REQUEST,
  GET_CHECKLISTS_CATEGORIES_SUCCESS, GET_CHECKLISTS_SINGLE_TEMPLATE_FAIL,
  GET_CHECKLISTS_SINGLE_TEMPLATE_REQUEST,
  GET_CHECKLISTS_SINGLE_TEMPLATE_SUCCESS,
  GET_CHECKLISTS_TEMPLATES_FAIL,
  GET_CHECKLISTS_TEMPLATES_REQUEST,
  GET_CHECKLISTS_TEMPLATES_SUCCESS,
  GET_CHECKLISTS_TOOLS_FAIL,
  GET_CHECKLISTS_TOOLS_REQUEST,
  GET_CHECKLISTS_TOOLS_SUCCESS,
} from '../actions/checklists';
import sortAndConvertChecklistCategories, {
  sortCategories, sortTemplates,
} from '../../helpers/sortAndConvertChecklistCategories';

export default function* watcher() {
  yield takeLatest(GET_CHECKLISTS_CATEGORIES_REQUEST, getChecklistsCategories);
  yield takeLatest(GET_CHECKLISTS_TEMPLATES_REQUEST, getChecklistsTemplates);
  yield takeLatest(GET_CHECKLISTS_SINGLE_TEMPLATE_REQUEST, getChecklistsSingleTemplate);
  yield takeLatest(GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_REQUEST, getChecklistsCategoriesAndTemplates);
  yield takeLatest(GET_CHECKLISTS_TOOLS_REQUEST, getCheckListsTools);
}

function* getChecklistsCategories(action) {
  try {
    const { data } = yield call(Api.getChecklistsCategories, action.payload);

    yield put({
      type: GET_CHECKLISTS_CATEGORIES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting checklists categories');
    console.log(e?.response?.data);
    yield put({
      type: GET_CHECKLISTS_CATEGORIES_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* getChecklistsTemplates(action) {
  try {
    const { data } = yield call(Api.getChecklistsTemplates, action.payload);

    yield put({
      type: GET_CHECKLISTS_TEMPLATES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting checklists templates');
    console.log(e?.response?.data);
    yield put({
      type: GET_CHECKLISTS_TEMPLATES_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* getChecklistsSingleTemplate(action) {
  try {
    const { data } = yield call(Api.getChecklistsSingleTemplate, action.payload);

    yield put({
      type: GET_CHECKLISTS_SINGLE_TEMPLATE_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting checklists single template');
    console.log(e?.response?.data);
    yield put({
      type: GET_CHECKLISTS_SINGLE_TEMPLATE_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* getChecklistsCategoriesAndTemplates(action) {
  try {
    const { data: categories } = yield call(Api.getChecklistsCategories, action.payload);
    const { data: templates } = yield call(Api.getChecklistsTemplates, action.payload);

    const converted = sortAndConvertChecklistCategories(categories, templates);
    const sortedCategories = sortCategories(categories);
    const sortedTemplates = sortTemplates(templates);

    yield put({
      type: GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_SUCCESS,
      payload: {
        categories,
        templates,
        converted,
        sortedCategories,
        sortedTemplates,
      },
    });
  } catch (e) {
    console.log(e, 'while getting checklists categories and templates');
    console.log(e?.response?.data);
    yield put({
      type: GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* getCheckListsTools(action) {
  try {
    const { data } = yield call(Api.getChecklistTools, action.payload);

    yield put({
      type: GET_CHECKLISTS_TOOLS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting checklists tools');
    console.log(e?.response?.data);
    yield put({
      type: GET_CHECKLISTS_TOOLS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
