import React from 'react';
import './css/index.scss';
import { COLORS } from '../../helpers/constants';

const CIRCLE_SIZE = 20;
const CIRCLE_SIZE_HALF = CIRCLE_SIZE / 2;

function DrawingMarker({
  coordinates, text, sizeCoefficient = 1, markerRef,
}) {
  const onClick = (ev) => {
    ev.stopPropagation();
  };

  return (
    <div
      onClick={onClick}
      ref={markerRef}
      className="drawing_marker_wrapper"
      style={{ ...styles.wrapper, left: (coordinates.x * sizeCoefficient) - CIRCLE_SIZE_HALF, top: (coordinates.y * sizeCoefficient) - CIRCLE_SIZE_HALF }}
    >
      {text && <p className="drawing_marker_text">{text}</p>}
      <div
        className="triangle"
        style={{
          ...styles.triangle,
          transform: `rotate(${coordinates.angle}deg)`,
        }}
      >
        <img src="/images/drawing_marker_triangle.png" alt="T" style={styles.image} />
      </div>
    </div>
  );
}

const styles = {
  wrapper: {
    position: 'absolute',
    backgroundColor: COLORS.main,

    width: CIRCLE_SIZE,
    height: CIRCLE_SIZE,
    borderRadius: CIRCLE_SIZE_HALF,
  },

  triangle: {
    position: 'absolute',
    top: -CIRCLE_SIZE_HALF,
    opacity: 0.5,
    width: CIRCLE_SIZE,
    height: CIRCLE_SIZE * 2,
    zIndex: 1,
  },

  image: {
    width: '100%',
    objectFit: 'contain',
  },
};

export default DrawingMarker;
