import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useInputState from '../../../helpers/hooks/useInputState';
import MyInput from '../../../templates/MyInput';
import MyButton from '../../../templates/MyButton';
import CancelButton from '../../../components/CancelButton';
import {
  getLetterheadsRequest,
  saveLetterheadRequest,
  setSingleLetterhead,
} from '../../../store/actions/letterheads';
import MySelect from '../../../templates/MySelect';
import langData from '../../../helpers/localData/langData';
import requestAllData from '../../../helpers/requestAllData';
import Api from '../../../Api';

function SingleLetterhead() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const letterheadId = searchParams.get('letterhead_id');

  const letterheads = useSelector((store) => store.letterheads.letterheads);
  const singleLetterhead = useSelector((store) => store.letterheads.singleLetterhead);
  const saveLoading = useSelector((store) => store.letterheads.saveLoading);
  const saveError = useSelector((store) => store.letterheads.saveError);

  const [lang, setLang, langError, setLangError] = useInputState();
  const [title, setTitle, titleError, setTitleError] = useInputState();
  const [desc, setDesc, descError, setDescError] = useInputState();
  const [address, setAddress, addressError, setAddressError] = useInputState();
  const [phones, setPhones, phonesError, setPhonesError] = useInputState();
  const [legalName, setLegalName, legalNameError, setLegalNameError] = useInputState();
  const [inn, setInn, innError, setInnError] = useInputState();
  const [kpp, setKpp, kppError, setKppError] = useInputState();
  const [ogrn, setOgrn, ogrnError, setOgrnError] = useInputState();
  const [bill, setBill, billError, setBillError] = useInputState();
  const [footer, setFooter, footerError, setFooterError] = useInputState();
  const [logo, setLogo] = useState();
  const [logoError, setLogoError] = useState('');
  const [toSave, setToSave] = useState(false);

  useEffect(() => {
    if (!letterheadId) {
      return;
    }
    if (!letterheads?.items) {
      dispatch(getLetterheadsRequest(requestAllData));
    }
  }, []);

  useEffect(() => {
    if (letterheadId && letterheads?.items) {
      dispatch(setSingleLetterhead(letterheadId));
    }
  }, [letterheads]);

  useEffect(() => {
    if (letterheadId && singleLetterhead?.id) {
      setTitle(singleLetterhead?.title);
      setDesc(singleLetterhead?.descr);
      setAddress(singleLetterhead?.address);
      setPhones(singleLetterhead?.contacts);
      setLegalName(singleLetterhead['legal-name']);
      setInn(singleLetterhead['legal-inn']);
      setKpp(singleLetterhead['legal-kpp']);
      setOgrn(singleLetterhead['legal-ogrn']);
      setBill(singleLetterhead['legal-bill']);
      setFooter(singleLetterhead.footer);
      setLang(singleLetterhead['lang-id']);
    }
  }, [singleLetterhead]);

  useEffect(() => {
    if (toSave && !saveLoading) {
      if (!saveError) {
        goBack();
      } else {
        setToSave(false);
      }
    }
  }, [saveLoading]);

  const goBack = () => {
    navigate(-1, { replace: true });
  };

  const handleLogo = (ev) => {
    if (ev.target.files) {
      setLogo(ev.target.files[0]);
      if (logoError) {
        setLogoError('');
      }
    }
  };

  const onSave = () => {
    let flag = false;
    let extension;
    if (logo?.name) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      extension = logo.name.split('.')[logo.name.split('.')?.length - 1];
    }

    if (!lang) {
      setLangError(t('language') + t('is_required_m_r'));
      flag = true;
    }
    if (!title) {
      setTitleError(t('title') + t('is_required_s_r'));
      flag = true;
    }
    if (!legalName) {
      setLegalNameError(t('legal_name') + t('is_required_s_r'));
      flag = true;
    }
    if (!singleLetterhead?.id && !logo) {
      setLogoError((t('logo') + t('is_required_m_r')));
      flag = true;
    } else if (logo && !['jpeg', 'jpg', 'JPG', 'JPEG', 'png', 'PNG'].includes(extension)) {
      setLogoError((t('logo_wrong_format')));
      flag = true;
    } else if (logo && logo.size > 1000000) {
      setLogoError((t('max_file')));
      flag = true;
    }

    if (flag) {
      return;
    }

    const data = {
      title,
      descr: desc,
      address,
      contacts: phones,
      footer,
      'legal-name': legalName,
      'legal-inn': inn,
      'legal-kpp': kpp,
      'legal-ogrn': ogrn,
      'legal-bill': bill,
      'lang-id': lang,
    };

    if (letterheadId) {
      data.id = letterheadId;
    }

    const formData = new FormData();

    formData.append('body', JSON.stringify(data));

    if (logo) {
      formData.append('logo', logo);
    }

    if (letterheadId) {
      dispatch(saveLetterheadRequest({ data: formData }));
    } else {
      dispatch(saveLetterheadRequest({ data: formData, save: true }));
    }
    setToSave(true);
  };

  return (
    <div className="single_letterhead_wrapper">
      <SinglePageHeader text={letterheadId ? t('edit_letterhead') : t('add_letterhead')} />
      {letterheads?.items && letterheadId && !singleLetterhead?.id ? (
        <p className="no_object">
          {t('no_letterhead')}
          {letterheadId}
        </p>
      ) : (
        <>
          <MySelect onlyDropDown withTranslation t={t} wrapperClassName="single_letterhead_select" showKey="name" label={t('language') + t('of_letterhead')} buttonText={t('language') + t('of_letterhead')} value={lang} onChange={setLang} error={langError} data={langData} />
          <MyInput wrapperClassName="single_letterhead_input" label={t('title') + t('of_letterhead')} value={title} onChange={setTitle} error={titleError} />
          <MyInput wrapperClassName="single_letterhead_input" label={t('description')} value={desc} onChange={setDesc} error={descError} />
          <MyInput wrapperClassName="single_letterhead_input" label={t('address')} value={address} onChange={setAddress} error={addressError} />
          <MyInput wrapperClassName="single_letterhead_input" label={t('phone') + t('of_company')} value={phones} onChange={setPhones} error={phonesError} />
          <MyInput wrapperClassName="single_letterhead_input" label={t('legal_name')} value={legalName} onChange={setLegalName} error={legalNameError} />
          <MyInput wrapperClassName="single_letterhead_input" label={t('legal_inn')} value={inn} onChange={setInn} error={innError} />
          <MyInput wrapperClassName="single_letterhead_input" label={t('legal_kpp')} value={kpp} onChange={setKpp} error={kppError} />
          <MyInput wrapperClassName="single_letterhead_input" label={t('legal_ogrn')} value={ogrn} onChange={setOgrn} error={ogrnError} />
          <MyInput wrapperClassName="single_letterhead_input" label={t('legal_bill')} value={bill} onChange={setBill} error={billError} />
          <MyInput wrapperClassName="single_letterhead_input" label={t('footer2')} value={footer} onChange={setFooter} error={footerError} />
          <MyInput wrapperClassName="single_letterhead_input" label={t('logo')} error={logoError} onChange={handleLogo} type="file" accept="image/jpeg, image/png" />
          {singleLetterhead?.id && !logo && (
            <img className="logo_cell" src={`${Api.url}/api/image/logo/26d26ef8-4d7b-4706-b9ed-2bff1d50043a/${singleLetterhead?.id}`} alt="logo" />
          )}
          <div className="buttons_row">
            <MyButton loading={saveLoading} onClick={onSave} text={letterheadId ? t('edit_letterhead_button') : t('add_letterhead_button')} />
            <CancelButton loading={saveLoading} />
          </div>
        </>
      )}
    </div>
  );
}

export default SingleLetterhead;
