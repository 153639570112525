import React from 'react';
import moment from 'moment';
import EditButton from '../../../../components/EditButton';
import DeleteButton from '../../../../components/DeleteButton';

function AllocItem(item, index, onItemEdit, showDeleteModal, t, loading, isAdmin) {
  return item && (
    <div key={item.id} className="alloc_item">
      <div className="alloc_left">
        <img src="/images/allocs.png" alt="img" className="alloc_img" />
        <div className="alloc_info_div">
          <p className="alloc_small_title">{item['user-uid']}</p>
          <p className="alloc_title">{item.fio}</p>
          <p className="alloc_desc">{item.role}</p>
          <p className="alloc_desc">{item['prikaz-number']}</p>
          <p className="alloc_desc">{moment(item['prikaz-date']).format('DD.MM.YYYY')}</p>
          <p className="alloc_info">
            {' '}
            {item['user-uid']}
            {' '}
            (
            {item['user-descr']}
            )
          </p>
          {item['comp-title'] && (
            <p className="alloc_info">
              {item['comp-title']}
              {' '}
              (
              {item['comp-descr']}
              )
            </p>
          )}
          <p className="alloc_info">
            {item['project-title']}
            {' '}
            (
            {item['project-descr']}
            )
          </p>
        </div>
      </div>
      {!loading && isAdmin && (
        <div className="row">
          <EditButton onClick={() => onItemEdit(item)} />
          <DeleteButton onClick={() => showDeleteModal(item)} />
        </div>
      )}
    </div>
  );
}

export default AllocItem;
