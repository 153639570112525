import React, { useEffect, useRef, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SinglePageHeader from '../../../../components/SinglePageHeader';
import useTranslation from '../../../../translations';
import MyInput from '../../../../templates/MyInput';
import MyButton from '../../../../templates/MyButton';
import { createDrawingsRequest } from '../../../../store/actions/drawings';
import navigation from '../../../../navigation';

function AddDrawings() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fileInputRef = useRef();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isWrongFormatSelected, setIsWrongFormatSelected] = useState(false);
  const [toCreate, setToCreate] = useState(false);

  const creatingLoading = useSelector((store) => store.drawings.creatingDrawingsLoading);
  const creatingError = useSelector((store) => store.drawings.creatingDrawingsError);

  const openSelectFile = () => {
    fileInputRef.current?.click();
  };

  const addFiles = (ev) => {
    if (ev.target?.files?.length) {
      if (!['.pdf', '.jpg', '.jpeg', '.png', '.bmp', '.tif', '.webp', '.dwg'].includes(getFileExtension(ev.target.files[0]).toLowerCase())) {
        setIsWrongFormatSelected(true);
        return;
      }

      if (isWrongFormatSelected) setIsWrongFormatSelected(false);

      // setSelectedFiles((prev) => [...ev.target.files, ...prev]);
      setSelectedFiles([ev.target.files[0]]);
    }
  };

  const removeFile = (index) => {
    selectedFiles.splice(index, 1);
    setSelectedFiles((prev) => [...prev]);
  };

  const getFileNameWithoutExtension = (file) => {
    if (file?.name) {
      const splitName = file.name.split('.');
      return splitName.slice(0, splitName.length - 1).join('.');
    }
  };

  const getFileExtension = (file) => {
    if (file?.name) {
      const splitName = file.name.split('.');
      return `.${splitName[splitName.length - 1]}`;
    }
  };

  const onCreate = () => {
    if (selectedFiles?.length) {
      const formData = new FormData();

      selectedFiles.forEach((item) => {
        formData.append('draw', item);
      });

      setToCreate(true);
      dispatch(createDrawingsRequest(formData));
    }
  };

  useEffect(() => {
    if (toCreate && !creatingLoading) {
      setToCreate(false);

      if (!creatingError) {
        navigate('/drawings/edit');
      } else {
        console.log('error');
      }
    }
  }, [creatingLoading]);

  return (
    <div className="add_drawing_wrapper">
      <SinglePageHeader text={t('new_drawing')} />
      <p className="new_drawing_title">{t('new_drawing_title')}</p>
      <div className="add_drawing_row">
        <img src="/images/add_drawing.png" alt="F" />
        <span>{t('new_drawing_info')}</span>
      </div>
      <div className="add_drawing_buttons_row">
        {selectedFiles.length ? (
          <div className="files_part">
            {selectedFiles.map((item, index) => (
              <div key={index} className="file_item" onClick={() => removeFile(index)}>
                <p className="file_item_text">{getFileNameWithoutExtension(item)}</p>
                <p className="file_item_ext">{getFileExtension(item)}</p>
                <img src="/images/pink_close.png" alt="X" className="file_close" />
              </div>
            ))}
          </div>
        ) : (
          <p className="add_drawing__no_files">{t('no_files')}</p>
        )}
        <MyInput innerRef={fileInputRef} name="file" wrapperClassName="add_drawing_input" onChange={addFiles} type="file" accept=".jpg,.pdf,.png,.bmp,.tif,.webp,.dwg" />
        <MyButton onClick={openSelectFile} text={t('select_file')} />
      </div>

      {isWrongFormatSelected && (
        <p className="only_jpg_pdf_text">{t('drawing_allowed_format')}</p>
      )}
      {selectedFiles.length ? (
        <MyButton loading={creatingLoading} onClick={onCreate} text={t('create')} className="create_button" />
      ) : null}
    </div>
  );
}

export default AddDrawings;
