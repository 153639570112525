import React from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSortTypes from '../../DocumentsPages/Events/components/sortTypes';
import { deleteCompanyRequest, getCompaniesRequest } from '../../../store/actions/companies';
import SortableList from '../../../templates/SortableList';
import CompanyItem from './components/CompanyItem';
import AddButton from '../../../components/AddButton';
import useTranslation from '../../../translations';
import { deleteLetterheadRequest } from '../../../store/actions/letterheads';

function Companies() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const companies = useSelector((store) => store.companies.companies);
  const companiesLoading = useSelector((store) => store.companies.companiesLoading);
  const sortTypes = useSortTypes();

  const getCompanies = (data) => {
    dispatch(getCompaniesRequest(data));
  };

  const onAddClick = () => {
    navigate('/partners/add');
  };

  const onEditClick = (item) => {
    navigate(`/partners/edit?company_id=${item.id}`);
  };

  const onDeleteClick = ({ id, requestData }) => {
    dispatch(deleteCompanyRequest({ id, requestData }));
  };

  const addButton = () => (
    AddButton({ text: t('add_company'), onClick: onAddClick })
  );

  return (
    <div className="companies_wrapper">
      <SortableList
        data={companies}
        loading={companiesLoading}
        requestData={getCompanies}
        sortTypes={sortTypes}
        renderItem={CompanyItem}
        onItemEdit={onEditClick}
        TitleButton={addButton}
        onDeleteClick={onDeleteClick}
        limitOnEachPage={10}
      />
    </div>
  );
}

export default Companies;
