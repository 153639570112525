export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export function loginRequest(data) {
  return {
    type: LOGIN_REQUEST,
    payload: data,
  };
}

export const SET_LANGUAGE = 'SET_LANGUAGE';
export function setLanguage(lang) {
  return {
    type: SET_LANGUAGE,
    payload: lang,
  };
}

export const SET_TOKEN = 'SET_TOKEN';
export function setToken(token) {
  return {
    type: SET_TOKEN,
    payload: token,
  };
}

export const LOG_OUT = 'LOG_OUT';
export function logOut() {
  return {
    type: LOG_OUT,
  };
}

export const SET_IS_ROOT = 'SET_IS_ROOT';
export function setIsRoot(val) {
  return {
    type: SET_IS_ROOT,
    payload: val,
  };
}
export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export function setIsAdmin(val) {
  return {
    type: SET_IS_ADMIN,
    payload: val,
  };
}

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
export function getProfileRequest() {
  return {
    type: GET_PROFILE_REQUEST,
  };
}

export const GET_MY_ALLOCATIONS_REQUEST = 'GET_MY_ALLOCATIONS_REQUEST';
export const GET_MY_ALLOCATIONS_SUCCESS = 'GET_MY_ALLOCATIONS_SUCCESS';
export const GET_MY_ALLOCATIONS_FAIL = 'GET_MY_ALLOCATIONS_FAIL';
export function getMyAllocationsRequest() {
  return {
    type: GET_MY_ALLOCATIONS_REQUEST,
  };
}

export const SET_MY_SCHEDULE_REQUEST = 'SET_MY_SCHEDULE_REQUEST';
export const SET_MY_SCHEDULE_SUCCESS = 'SET_MY_SCHEDULE_SUCCESS';
export const SET_MY_SCHEDULE_FAIL = 'SET_MY_SCHEDULE_FAIL';
export function setMyScheduleRequest(data) {
  return {
    type: SET_MY_SCHEDULE_REQUEST,
    payload: data,
  };
}
