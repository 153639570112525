import React, { useEffect } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import PrescriptionViolItem from '../PrescriptionViolItem';
import { getDrawingsRequest } from '../../store/actions/drawings';

function PrescriptionViols({
  viols, setViols, violsError, buildObj,
}) {
  const dispatch = useDispatch();

  const drawings = useSelector((store) => store.drawings.drawings);

  useEffect(() => {
    if (!viols?.length || (viols?.length && viols[viols.length - 1]?.text)) {
      setViols((prev) => ([...prev, { text: '' }]), true);
    }
  }, [viols]);

  useEffect(() => {
    if (buildObj?.id) {
      dispatch(getDrawingsRequest({ build_objs_id: buildObj.id }));
    }
  }, [buildObj]);

  return (
    viols?.length && viols.map((viol, index) => (
      !viol['flag-del'] && (
        <PrescriptionViolItem
          key={index}
          viol={viol}
          index={index}
          viols={viols}
          setViols={setViols}
          violsError={violsError}
          buildObj={buildObj}
        />
      )
    ))
  );
}

export default PrescriptionViols;
