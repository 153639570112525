import {
  DELETE_CONFIGURATION_REQUEST,
  GET_CONFIGURATIONS_FAIL,
  GET_CONFIGURATIONS_REQUEST,
  GET_CONFIGURATIONS_SUCCESS, SAVE_CONFIGURATION_FAIL, SAVE_CONFIGURATION_REQUEST, SAVE_CONFIGURATION_SUCCESS,
  SET_SINGLE_CONFIGURATION,
} from '../actions/configurations';
import { LOG_OUT } from '../actions/account';

const initialState = {
  configurations: {},
  configurationsLoading: false,
  configurationsError: '',

  singleConfiguration: {},

  saveLoading: false,
  saveError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONFIGURATIONS_REQUEST: {
      return {
        ...state,
        configurationsLoading: true,
        configurationsError: '',
        singleConfiguration: {},
      };
    }
    case GET_CONFIGURATIONS_SUCCESS: {
      return {
        ...state,
        configurations: action.payload,
        configurationsLoading: false,
        configurationsError: '',
      };
    }
    case GET_CONFIGURATIONS_FAIL: {
      return {
        ...state,
        configurationsLoading: false,
        configurationsError: 'error',
      };
    }

    case SET_SINGLE_CONFIGURATION: {
      const singleConfiguration = state.configurations?.items?.find((item) => item.key === action.payload) || {};
      return {
        ...state,
        singleConfiguration,
      };
    }

    case SAVE_CONFIGURATION_REQUEST: {
      return {
        ...state,
        saveLoading: true,
        saveError: '',
      };
    }

    case SAVE_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        saveLoading: false,
        saveError: '',
      };
    }

    case SAVE_CONFIGURATION_FAIL: {
      return {
        ...state,
        saveLoading: false,
        saveError: 'error',
      };
    }

    case DELETE_CONFIGURATION_REQUEST: {
      return {
        ...state,
        configurationsLoading: true,
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
