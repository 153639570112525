import React, { useState } from 'react';
import '../css/index.scss';
import MyButton from '../../../../templates/MyButton';
import useTranslation from '../../../../translations';
import Api from '../../../../Api';

function AgreementPrescription({
  prescription, goBack, rightButton, onApproveFunction, loadingProp,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onAgreement = async () => {
    try {
      if (onApproveFunction) {
        setLoading(true);
        await onApproveFunction();
        setLoading(false);
        return;
      }
      setLoading(true);

      const payload = {
        'predpisan-id': prescription.id,
        status: 'signing',
      };

      const { data } = await Api.setPrescriptionStatus(payload);

      if (data.message === 'Ok') {
        goBack();
      }
    } catch (e) {
      console.warn('Error while agreement', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signing_wrapper">
      <div className="line" />
      <MyButton className="uploading_button" text={t('approve')} onClick={onAgreement} loading={loadingProp} />
      {rightButton}
    </div>
  );
}

export default AgreementPrescription;
