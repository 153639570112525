import React from 'react';
import './css/index.scss';
import { t } from '../../translations';

function DescFilter({ isDesc, onChange }) {
  return (
    <div className="desc_filter_trigger" onClick={() => onChange(!isDesc)}>
      {isDesc ? t('desc') : t('asc')}
    </div>
  );
}

export default DescFilter;
