export default (prescription) => {
  if (!prescription?.id) {
    return;
  }

  return {
    ...prescription,
    viols: prescription.viols.map((item) => (item.draw_id ? ({
      ...item,
      drawing: {
        draw_id: item.draw_id,
        point_top: item.point_top,
        point_left: item.point_left,
        point_direction: item.point_direction,
        point_zoom: item.point_zoom,
      },
    }) : item)),
  };
};
