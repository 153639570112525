import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { all } from 'axios';
import MyLoading from '../../../../templates/MyLoading';
import { COLORS } from '../../../../helpers/constants';
import useTranslation from '../../../../translations';
import MyCheckbox from '../../../../templates/MyCheckbox';

const NUMBER_OF_COLUMNS = 2;

function ToolsList({ list, onChange, needEverythingSelected }) {
  const { t, lang } = useTranslation();

  const tools = useSelector((store) => store.checklists.tools);
  const toolsObj = useSelector((store) => store.checklists.toolsObj);
  const gettingLoading = useSelector((store) => store.checklists.gettingToolsLoading);
  const gettingError = useSelector((store) => store.checklists.gettingToolsError);

  const [allToolsIds, setAllToolsIds] = useState([]);

  const splitTools = useMemo(() => {
    if (tools?.length) {
      const countInChunk = Math.ceil(tools.length / NUMBER_OF_COLUMNS);
      return _.chunk(tools, countInChunk);
    }
  }, [tools]);

  const onClicked = (item) => () => {
    if (list.includes(item.id)) {
      onChange((prev) => prev.filter((i) => i !== item.id));
    } else {
      onChange((prev) => [...prev, item.id]);
    }
  };

  const onAllClicked = () => {
    if (list.length === allToolsIds.length) {
      onChange([]);
    } else {
      onChange(allToolsIds);
    }
  };

  useEffect(() => {
    const allIds = Object.keys(toolsObj).map((i) => +i);
    setAllToolsIds(allIds);

    /*     if (!gettingLoading && needEverythingSelected && tools?.length && toolsObj) {
      onChange(allIds);
    } */
  }, [gettingLoading]);

  return (
    <div>
      <div className="tools_header">
        <p className="bold_text bold_text_tools">{t('tools')}</p>
        <MyCheckbox className="tools_item" value={list.length === allToolsIds.length} onChange={onAllClicked} label={t('select_all')} />
      </div>
      <div className="tools_wrapper">
        {gettingLoading ? <MyLoading color={COLORS.main} width={40} height={40} className="tools_loading" />
          : (!splitTools?.length || gettingError)
            ? <p className="no_tools">{t('no_tools')}</p> : (
              splitTools.map((column, columnIndex) => (
                <div className="tools_column" key={`column${columnIndex}`}>
                  {column.map((toolItem, itemIndex) => (
                    <MyCheckbox key={toolItem.id} className="tools_item" value={list.includes(toolItem.id)} onChange={onClicked(toolItem)} label={toolItem[`name_${lang}`]} />
                  ))}
                </div>
              ))
            )}
      </div>
    </div>

  );
}

export default ToolsList;
