export default `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Pdf Content</title>
    <style>
body {
    margin: 0;
    padding: 0;
    font-family: Inter, sans-serif !important;
}

@media print {
    body {
        padding: 20mm 10mm 20mm 30mm;
    }

    #footer {
        display: block;
    }
}

@page {
    margin: 20mm 10mm 20mm 30mm;
    size: A4;
}


p {
    margin: 0 0 1px 0;
    font-weight: 400;
    color: #363636;
    font-size: 18px;
}

.empty {
    height: 54px;
    background: #3F274D;
}

.wrapper {
    width: 816px;
    background: #FFFFFF;
}

section {
    break-inside: avoid;
    margin: 0 auto;
    padding: 5mm 0;
    background: #ffffff;
}

ul {
    list-style: decimal;
    margin: 20px 0;
}

ul.dotted {
    list-style: disc;
}

li {
    break-inside: avoid;
    margin-bottom: 20px;
}
</style>
    <style>
.header {
    margin-bottom: 50px;
}

.header__inner {
    display: flex;
    margin-bottom: 30px;
}

.info__list {
    margin-left: 60px;
}
.info_item {
    font-size: 14px;
}

.logo__wrapper {
    width: 280px;
    height: 125px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.logo {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
}
.line {
    border-bottom: 2px solid;
}
.number {
    width: 66%;
}
.row {
    display: flex;
    justify-content: space-between;
}
.to__who {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.object__block {
    margin-top: 50px;
}
.object__text {
    margin-bottom: 40px;
    display: inline-block;
}
.object__text span {
    border-bottom: 1px solid;
    font-style: italic;
    margin-right: 2px;
}
.main__title {
    text-align: center;
}
.main__margin {
    margin: 30px 0;
}
.no__margin {
    margin: 0 0 40px;
}

.people {
    padding-top: 30px;
    padding-bottom: 70px;
}

.people__item {
    margin-bottom: 30px;
}

.people__item:last-child {
    margin-top: 30px;
    position: relative;
}
.bold__text {
    font-weight: 700;
}
.role {
    width: 320px;
}
.signature {
    text-align: right;
    margin-top: -24px;
}
.date_text {
    text-align: right;
}
.absolute {
    position: absolute;
    right: 0;
    bottom: 0;
}

.footer {
    position: fixed;
    bottom: 0;
    background: #fff;
    width: 816px;
    height: 22px;
}

.ios__footer {
    position: absolute;
    bottom: 0;
    background: #ffffff;
    width: 850px;
    height: 190px;
    display: none; /*flag 3*/
}

.footer_line {
    border-bottom: 1px solid #ccc;
}
.footer__row {
    padding: 0 10px;
    margin: 4px 0;
    display: flex;
    justify-content: space-between;
}
.footer__text {
    font-size: 14px;
    font-weight: 700;
}

.image {
    padding: 30px 0 60px 0;

    height: 880px;
    /*background-color: #b9b9b9;*/
}

.image_attachment_title {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 16px;
}

.image__img {
    max-height: 250px;
    max-width: 660px;
}

.image__img.first {
    max-height: 230px;
}

.drawing_fragment {

}
.fragment_img {
}


.view__of_drawing {
    margin-top: 30px;
    position: relative;
}

.view__of_drawing_fragment {
    margin-top: 30px;
    height: 250px;
    width: 660px;
    position: relative;
    overflow: hidden;
    border: 2px solid #D4246D;
    box-sizing: border-box;
}

.image__text {
    font-weight: 700;
    font-size: 14px;
    margin: 12px 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.image__text__tiny {
    font-weight: 300;
    font-size: 14px;
}

.drawing__zoom__outline {
    border: 2px solid #D4246D;
    position: absolute;
    z-index: 100;
    box-sizing: border-box;
}

/* Acts */

.without_top_margin {
    margin-top: -20px;
}

.top__item {
    display: flex;
    margin-top: 40px;
}

.no__margin_top {
    margin-top: 6px;
}

.top__title {
    width: 40%;
    margin-right: 5%;
}

.top__text {
    width: 60%;
}

.top__text span {
    display: block;
    text-align: right;
    margin-top: 4px;
}

.middle__text {
    margin: 70px 0;
    width: 80%;
}

.middle__text__bottom {
    margin: 30px 0;
    width: 80%;
}

.middle__cursive {
    margin: 20px 0;
    font-style: italic;
}

.remark_text {
    margin: 10px 0 0 70px;
}

.act__sign__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.act__sign__left {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.drawing__marker__wrapper {
    position: absolute;
    background-color: #D4246D;
    width: 14px;
    height: 14px;
    border-radius: 7px;
}

.triangle {
    position: absolute;
    opacity: 0.5;
    z-index: 1;
}

@media screen and (max-width: 600px) {
    .signature {
        margin-top: 0;
    }
}
</style>
</head>
<body>
<!--PUT HERE-->
</body>
</html>
`;
