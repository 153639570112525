import React from 'react';
import './css/index.scss';
import { useNavigate } from 'react-router-dom';
import MyButton from '../../templates/MyButton';

function SinglePageHeader({
  withGoBack = true, text, rightComponent, leftComponent, functionAtGoingBack,
}) {
  const navigate = useNavigate();

  const goBack = () => {
    if (functionAtGoingBack) {
      functionAtGoingBack();
    }
    navigate(-1, { replace: true });
  };

  return (
    <div className="single_page_header">
      <div className="single_page_left_part">
        {leftComponent}
        {withGoBack && <MyButton iconUri="/images/arrow_back.png" className="go_back" iconClassName="go_back_icon" onClick={goBack} />}
        <p className="single_page_title">{text}</p>
      </div>
      {rightComponent}
    </div>
  );
}

export default SinglePageHeader;
