import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import {
  getSinglePrescriptionRequest,
} from '../../../store/actions/prescriptions';
import MyLoading from '../../../templates/MyLoading';
import { COLORS, ROLES } from '../../../helpers/constants';
import SigningPrescription from './components/SigningPrescription';
import PdfIframe from '../../../components/PdfIframe';
import { getLetterheadsRequest } from '../../../store/actions/letterheads';
import MyButton from '../../../templates/MyButton';
import Api from '../../../Api';
import DeleteButton from '../../../components/DeleteButton';
import AgreementPrescription from './components/AgreementPrescription';
import { createActRequest } from '../../../store/actions/acts';
import requestAllData from '../../../helpers/requestAllData';
import { getMyAllocationsRequest } from '../../../store/actions/account';

function View() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const prescriptionId = searchParams.get('prescription_id');
  const [activeLetterhead, setActiveLetterhead] = useState();
  const [statusLoading, setStatusLoading] = useState(false);

  const profile = useSelector((store) => store.account.profile);
  const myAllocations = useSelector((store) => store.account.myAllocations);
  const role = useSelector((store) => store.account.role);
  const singlePrescription = useSelector((store) => store.prescriptions.singlePrescription);
  const gettingSinglePrescriptionLoading = useSelector((store) => store.prescriptions.gettingSinglePrescriptionLoading);
  const gettingSinglePrescriptionError = useSelector((store) => store.prescriptions.gettingSinglePrescriptionError);
  const letterheads = useSelector((store) => store.letterheads.letterheads);

  const singleAct = useSelector((store) => store.acts.singleAct);
  const creatingActLoading = useSelector((store) => store.acts.creatingActLoading);
  const creatingActError = useSelector((store) => store.acts.creatingActError);

  const status = singlePrescription?.status;
  const agreementUserId = singlePrescription?.['agreement-user-alc']?.id;
  const isOverdue = singlePrescription && singlePrescription['flag-overdue'];

  const [toCreate, setToCreate] = useState(false);

  const goBack = () => {
    navigate(-1, { replace: true });
  };

  const repeatPrescription = async () => {
    try {
      setStatusLoading(true);
      const payload = {
        'predpisan-id': singlePrescription.id,
        'build-obj-id': singlePrescription['build-obj-id'],
        'engineer-user-alc-id': singlePrescription['engineer-user-alc-id'],
      };

      const { data } = await Api.repeatPrescription(payload);
      setStatusLoading(false);
      if (data.result === 'OK') {
        navigate(`/prescriptions/edit?prescription_id=${data?.id}`);
      }
    } catch (e) {
      console.warn(e, 'while repeating prescription');
    }
  };

  const onEditAndApprove = () => {
    if (prescriptionId) {
      navigate(`/prescriptions/edit?prescription_id=${prescriptionId}`);
    }
  };

  const cancelPrescription = async () => {
    try {
      setStatusLoading(true);
      const payload = {
        'predpisan-id': singlePrescription.id,
        status: 'canceled',
      };

      const { data } = await Api.setPrescriptionStatus(payload);
      setStatusLoading(false);
      if (data.message === 'Ok') {
        navigate('..', { replace: true });
      }
    } catch (e) {
      console.log(e, 'while canceling prescription');
    }
  };

  const checkIfAssignedToApprove = () => {
    const currentAlloc = myAllocations.find((item) => item.project_id === singlePrescription['project-id']
      && item.object_id === singlePrescription['build-obj-id']
      && item.alloc_id === agreementUserId);

    if (currentAlloc) {
      return true;
    }
  };

  useEffect(() => {
    if (prescriptionId) {
      if (prescriptionId !== singlePrescription.id) {
        dispatch(getSinglePrescriptionRequest({ 'filter-val': { id: prescriptionId } }));
      }
    }
  }, [prescriptionId]);

  useEffect(() => {
    dispatch(getLetterheadsRequest(requestAllData));
    dispatch(getMyAllocationsRequest());
  }, []);

  useEffect(() => {
    if (letterheads?.items && singlePrescription?.id) {
      const letterhead = letterheads.items.find((item) => item.id === singlePrescription['stc-id']);
      if (letterhead) {
        setActiveLetterhead(letterhead);
      } else {
        setActiveLetterhead('');
      }
    }
  }, [letterheads, singlePrescription]);

  useEffect(() => {
    if (toCreate && !creatingActLoading && !creatingActError && singleAct?.id) {
      navigate(`/acts/edit?act_id=${singleAct.id}`);
    }
  }, [creatingActLoading]);

  const createActDraft = () => {
    const payload = {
      'predpisan-id': singlePrescription.id,
      'project-id': singlePrescription['project-id'],
    };
    dispatch(createActRequest(payload));
    setToCreate(true);
  };

  const isAllItemsClosed = useMemo(() => {
    if (!singlePrescription?.items?.length) return;

    for (let i = 0; i < singlePrescription.items.length; i++) {
      if (!singlePrescription.items[i]?.['act-id']) {
        return;
      }
    }

    return true;
  }, [singlePrescription]);

  return (
    <div className="single_prescription_wrapper">
      <SinglePageHeader text={t('view_prescription')} />
      {gettingSinglePrescriptionLoading ? <MyLoading color={COLORS.main} height={20} width={20} /> : gettingSinglePrescriptionError ? (
        <p>
          {t('no_prescription_with_id')}
          {prescriptionId}
        </p>
      ) : (
        <div>
          <div className="view_info_row">
            <p className="view_info_text margin_right">
              {t('number')}
              :
            </p>
            <p className="view_info_text">{singlePrescription['number-out']}</p>
          </div>
          <div className="view_info_row">
            <p className="view_info_text margin_right">
              {t('status')}
              :
            </p>
            <p className="view_info_text">{t(singlePrescription.status)}</p>
          </div>
          <div className="view_info_row">
            <p className="view_info_text margin_right">
              {t('priority')}
              :
            </p>
            <div className={`circle ${singlePrescription.priority === 'low' ? 'green' : singlePrescription.priority === 'medium' ? 'yellow' : 'red'}`} />
            <p className="view_info_text">{t(singlePrescription.priority)}</p>
          </div>
          {isOverdue && (
            <div className="view_info_row">
              <p className="view_info_text overdue">{t('overdue')}</p>
            </div>
          )}
          {role === ROLES.stroymanager && (
            <>
              <div className="buttons_row">
                <MyButton className="repeat_button" text={t('repeat')} onClick={repeatPrescription} loading={statusLoading} />
                {isOverdue ? (
                  <DeleteButton text={t('cancel_overdue')} onClick={cancelPrescription} loading={statusLoading} />
                ) : ['active', 'force-active', 'done-part', 'active-force'].includes(singlePrescription.status) && !isAllItemsClosed ? (
                  <MyButton className="margin_left" text={t('create_act_on_prescription')} onClick={createActDraft} loading={creatingActLoading} />
                ) : null}
              </div>
              {status === 'signing' && !isOverdue && <SigningPrescription prescription={singlePrescription} goBack={goBack} />}
            </>
          )}
          {status === 'agreement' && checkIfAssignedToApprove() && !isOverdue && (
            <AgreementPrescription prescription={singlePrescription} goBack={goBack} rightButton={<MyButton className="uploading_button" text={t('edit_and_approve')} onClick={onEditAndApprove} />} />
          )}
          <PdfIframe document={singlePrescription} activeLetterhead={activeLetterhead} documentType="prescription" />
        </div>
      )}

    </div>
  );
}

export default View;
