import { LOG_OUT } from '../actions/account';
import {
  GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_FAIL,
  GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_REQUEST,
  GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_SUCCESS,
  GET_CHECKLISTS_CATEGORIES_FAIL,
  GET_CHECKLISTS_CATEGORIES_REQUEST,
  GET_CHECKLISTS_CATEGORIES_SUCCESS, GET_CHECKLISTS_SINGLE_TEMPLATE_FAIL,
  GET_CHECKLISTS_SINGLE_TEMPLATE_REQUEST,
  GET_CHECKLISTS_SINGLE_TEMPLATE_SUCCESS,
  GET_CHECKLISTS_TEMPLATES_FAIL,
  GET_CHECKLISTS_TEMPLATES_REQUEST,
  GET_CHECKLISTS_TEMPLATES_SUCCESS,
  GET_CHECKLISTS_TOOLS_FAIL,
  GET_CHECKLISTS_TOOLS_REQUEST,
  GET_CHECKLISTS_TOOLS_SUCCESS, SET_CONVERTED_CHECKLISTS,
} from '../actions/checklists';

const initialState = {
  checklistsCategories: [],
  gettingChecklistsCategoriesLoading: false,
  gettingChecklistsCategoriesError: '',

  checklistsTemplates: [],
  gettingChecklistsTemplatesLoading: false,
  gettingChecklistsTemplatesError: '',

  checklistsSingleTemplate: {},
  gettingChecklistsSingleTemplateLoading: false,
  gettingChecklistsSingleTemplateError: '',

  convertedChecklists: [],
  sortedCategories: [],
  sortedTemplates: [],

  gettingChecklistsCategoriesAndTemplatesLoading: false,
  gettingChecklistsCategoriesAndTemplatesError: '',

  tools: [],
  toolsObj: {},
  gettingToolsLoading: false,
  gettingToolsError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // Categories
    case GET_CHECKLISTS_CATEGORIES_REQUEST: {
      return {
        ...state,
        checklistsCategories: [],
        gettingChecklistsCategoriesLoading: true,
        gettingChecklistsCategoriesError: '',
      };
    }
    case GET_CHECKLISTS_CATEGORIES_SUCCESS: {
      return {
        ...state,
        checklistsCategories: action.payload,
        gettingChecklistsCategoriesLoading: false,
        gettingChecklistsCategoriesError: '',
      };
    }
    case GET_CHECKLISTS_CATEGORIES_FAIL: {
      return {
        ...state,
        gettingChecklistsCategoriesLoading: false,
        gettingChecklistsCategoriesError: action.payload,
      };
    }

    // Templates
    case GET_CHECKLISTS_TEMPLATES_REQUEST: {
      return {
        ...state,
        checklistsTemplates: [],
        gettingChecklistsTemplatesLoading: true,
        gettingChecklistsTemplatesError: '',
      };
    }
    case GET_CHECKLISTS_TEMPLATES_SUCCESS: {
      return {
        ...state,
        checklistsTemplates: action.payload,
        gettingChecklistsTemplatesLoading: false,
        gettingChecklistsTemplatesError: '',
      };
    }
    case GET_CHECKLISTS_TEMPLATES_FAIL: {
      return {
        ...state,
        gettingChecklistsTemplatesLoading: false,
        gettingChecklistsTemplatesError: action.payload,
      };
    }

    case GET_CHECKLISTS_SINGLE_TEMPLATE_REQUEST: {
      return {
        ...state,
        checklistsSingleTemplate: {},
        gettingChecklistsSingleTemplateLoading: true,
        gettingChecklistsSingleTemplateError: '',
      };
    }
    case GET_CHECKLISTS_SINGLE_TEMPLATE_SUCCESS: {
      return {
        ...state,
        checklistsSingleTemplate: action.payload,
        gettingChecklistsSingleTemplateLoading: false,
        gettingChecklistsSingleTemplateError: '',
      };
    }
    case GET_CHECKLISTS_SINGLE_TEMPLATE_FAIL: {
      return {
        ...state,
        gettingChecklistsSingleTemplateLoading: false,
        gettingChecklistsSingleTemplateError: action.payload,
      };
    }

    // Categories and Templates
    case GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_REQUEST: {
      return {
        ...state,
        gettingChecklistsCategoriesAndTemplatesLoading: true,
        gettingChecklistsCategoriesAndTemplatesError: '',
      };
    }
    case GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_SUCCESS: {
      const {
        categories, templates, converted, sortedCategories, sortedTemplates,
      } = action.payload;

      return {
        ...state,
        convertedChecklists: converted,
        sortedCategories,
        sortedTemplates,

        checklistsCategories: categories,
        checklistsTemplates: templates,
        gettingChecklistsCategoriesAndTemplatesLoading: false,
        gettingChecklistsCategoriesAndTemplatesError: '',
      };
    }
    case GET_CHECKLISTS_CATEGORIES_AND_TEMPLATES_FAIL: {
      return {
        ...state,
        gettingChecklistsCategoriesAndTemplatesLoading: false,
        gettingChecklistsCategoriesAndTemplatesError: action.payload,
      };
    }

    case SET_CONVERTED_CHECKLISTS: {
      return {
        ...state,
        convertedChecklists: action.payload,
      };
    }

    case GET_CHECKLISTS_TOOLS_REQUEST: {
      return {
        ...state,
        gettingToolsLoading: true,
        gettingToolsError: '',
      };
    }
    case GET_CHECKLISTS_TOOLS_SUCCESS: {
      const obj = {};
      action.payload?.forEach((item) => {
        obj[item.id] = item;
      });

      return {
        ...state,
        tools: action.payload,
        toolsObj: obj,
        gettingToolsLoading: false,
        gettingToolsError: '',
      };
    }
    case GET_CHECKLISTS_TOOLS_FAIL: {
      return {
        ...state,
        gettingToolsLoading: false,
        gettingToolsError: action.payload,
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
