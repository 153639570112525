import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import useTranslation from '../../../../translations';
import MyInput from '../../../../templates/MyInput';
import MyButton from '../../../../templates/MyButton';

function ControlItem({
  title, list, setList, doc, setDoc,
}) {
  const { t } = useTranslation();

  const listWithoutDeleted = useMemo(() => list.filter((i) => !i.flagDel), [list]);

  const onUpPress = (index) => () => {
    if (index !== 0) {
      const newList = [...listWithoutDeleted];
      [newList[index - 1], newList[index]] = [newList[index], newList[index - 1]];
      setList(newList);
    }
  };

  const onDownPress = (index) => () => {
    if (index < listWithoutDeleted.length - 2) {
      const newList = [...listWithoutDeleted];
      [newList[index], newList[index + 1]] = [newList[index + 1], newList[index]];
      setList(newList);
    }
  };

  const updateInput = (index) => (e) => {
    const itemToUpdate = listWithoutDeleted[index];
    itemToUpdate[e.target.name] = e.target.value;

    setList([...list]);
  };

  useEffect(() => {
    if (!listWithoutDeleted?.length || (listWithoutDeleted?.length && listWithoutDeleted[listWithoutDeleted.length - 1]?.title)) {
      setList((prev) => ([...prev, { title: '', descr: '' }]));
    }
  }, [list]);

  const removeItem = (index) => (ev) => {
    if (!ev.target.value && index !== listWithoutDeleted.length - 1) {
      const itemToDelete = listWithoutDeleted[index];
      itemToDelete.flagDel = true;
      setList([...list]);
    }
  };

  return (
    <div className="control_item">
      <p className="control_item_title">{title}</p>
      <div className="control_item_inner">
        <MyInput textarea label={t('documentation')} className="control_item_input" wrapperClassName="control_item_input_wrapper_doc" value={doc} onChange={setDoc} />
        <p className="control_item_small_title">{t('checklist')}</p>
        {listWithoutDeleted.map((item, index) => (
          <div key={`control_item_${index}`} className="control_item_row">
            <div className="buttons_div">
              <MyButton onClick={onUpPress(index)} disabled={!item.title || index === 0} iconUri="/images/controls_up.png" className="controls_button" iconClassName="controls_button_icon" />
              <MyButton onClick={removeItem(index)} disabled={!item.title} iconUri="/images/controls_bin.png" className="controls_button" iconClassName="controls_button_icon" />
              <MyButton onClick={onDownPress(index)} disabled={!item.title || index >= listWithoutDeleted.length - 2} iconUri="/images/controls_down.png" className="controls_button" iconClassName="controls_button_icon" />
            </div>
            <div className="inputs_div">
              <MyInput value={item.title} name="title" onChange={updateInput(index)} onBlur={removeItem(index)} textarea placeholder={t('controlled_operation')} className="control_item_input" wrapperClassName="control_item_input_wrapper" />
              <MyInput value={item.descr} name="descr" onChange={updateInput(index)} textarea placeholder={t('control_method')} className="control_item_input" wrapperClassName="control_item_input_wrapper" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ControlItem;
