import React from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSortTypes from '../../DocumentsPages/Events/components/sortTypes';
import {
  deleteAllocRequest,
  getAllocsRequest,
} from '../../../store/actions/allocs';
import SortableList from '../../../templates/SortableList';
import AllocItem from './components/AllocItem';
import AddButton from '../../../components/AddButton';
import useTranslation from '../../../translations';

function Allocs() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const allocs = useSelector((store) => store.allocs.allocs);
  const allocsLoading = useSelector((store) => store.allocs.allocsLoading);
  const sortTypes = useSortTypes();

  const getAllocs = (data) => {
    dispatch(getAllocsRequest(data));
  };

  const onAddClick = () => {
    navigate('/allocated_users/add');
  };

  const onEditClick = (item) => {
    navigate(`/allocated_users/edit?alloc_id=${item.id}`);
  };

  const onDeleteClick = ({ id, requestData }) => {
    dispatch(deleteAllocRequest({ id, requestData }));
  };

  const addButton = () => (
    AddButton({ text: t('add_alloc_btn'), onClick: onAddClick })
  );

  return (
    <div className="allocs_wrapper">
      <SortableList
        data={allocs}
        loading={allocsLoading}
        requestData={getAllocs}
        sortTypes={sortTypes}
        renderItem={AllocItem}
        onItemEdit={onEditClick}
        onDeleteClick={onDeleteClick}
        TitleButton={addButton}
        limitOnEachPage={10}
        labelToShowOnDelete={t('alloc_delete_label')}
        keyToShowOnDelete="user-uid"
      />
    </div>
  );
}

export default Allocs;
