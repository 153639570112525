import React from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSortTypes from '../../DocumentsPages/Events/components/sortTypes';
import {
  deleteConfigurationRequest,
  getConfigurationsRequest,
} from '../../../store/actions/configurations';
import SortableList from '../../../templates/SortableList';
import ConfigurationItem from './components/ConfigurationItem';
import AddButton from '../../../components/AddButton';
import useTranslation from '../../../translations';

function Configurations() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const configurations = useSelector((store) => store.configurations.configurations);
  const configurationsLoading = useSelector((store) => store.configurations.configurationsLoading);
  const sortTypes = useSortTypes();

  const getConfigurations = (data) => {
    dispatch(getConfigurationsRequest(data));
  };

  const onAddClick = () => {
    navigate('/configuration/add');
  };

  const onEditClick = (item) => {
    navigate(`/configuration/edit?configuration_key=${item.key}`);
  };

  const onDeleteClick = ({ id, key, requestData }) => {
    dispatch(deleteConfigurationRequest({ id: key, requestData }));
  };

  const addButton = () => (
    AddButton({ text: t('add_configuration_btn'), onClick: onAddClick })
  );

  return (
    <div className="configurations_wrapper">
      <SortableList
        data={configurations}
        loading={configurationsLoading}
        requestData={getConfigurations}
        sortTypes={sortTypes}
        renderItem={ConfigurationItem}
        onItemEdit={onEditClick}
        onDeleteClick={onDeleteClick}
        TitleButton={addButton}
        limitOnEachPage={10}
        keyToShowOnDelete="key"
      />
    </div>
  );
}

export default Configurations;
