import React from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSortTypes from '../../DocumentsPages/Events/components/sortTypes';
import { deleteClientRequest, getClientsRequest } from '../../../store/actions/clients';
import SortableList from '../../../templates/SortableList';
import ClientItem from './components/ClientItem';
import AddButton from '../../../components/AddButton';
import useTranslation from '../../../translations';

function Clients() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const clients = useSelector((store) => store.clients.clients);
  const clientsLoading = useSelector((store) => store.clients.clientsLoading);
  const sortTypes = useSortTypes();

  const getClients = (data) => {
    dispatch(getClientsRequest(data));
  };

  const onAddClick = () => {
    navigate('/clients/add');
  };

  const onEditClick = (item) => {
    navigate(`/clients/edit?client_id=${item.id}`);
  };

  const onDeleteClick = ({ id, requestData }) => {
    dispatch(deleteClientRequest({ id, requestData }));
  };

  const addButton = () => (
    AddButton({ text: t('add_client_btn'), onClick: onAddClick })
  );

  return (
    <div className="clients_wrapper">
      <SortableList
        data={clients}
        loading={clientsLoading}
        requestData={getClients}
        sortTypes={sortTypes}
        renderItem={ClientItem}
        onItemEdit={onEditClick}
        onDeleteClick={onDeleteClick}
        TitleButton={addButton}
        limitOnEachPage={10}
        keyToShowOnDelete="title"
      />
    </div>
  );
}

export default Clients;
