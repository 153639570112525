import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  DELETE_ALLOC_FAIL,
  DELETE_ALLOC_REQUEST,
  GET_ALLOCS_FAIL,
  GET_ALLOCS_REQUEST,
  GET_ALLOCS_SUCCESS, SAVE_ALLOC_FAIL,
  SAVE_ALLOC_REQUEST, SAVE_ALLOC_SUCCESS,
} from '../actions/allocs';

export default function* watcher() {
  yield takeLatest(GET_ALLOCS_REQUEST, getAllocs);
  yield takeLatest(SAVE_ALLOC_REQUEST, saveAlloc);
  yield takeLatest(DELETE_ALLOC_REQUEST, deleteAlloc);
}

function* getAllocs(action) {
  try {
    const { data } = yield call(Api.getAllocs, action.payload);

    yield put({
      type: GET_ALLOCS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting allocs');
    console.log(e?.response?.data);
    yield put({
      type: GET_ALLOCS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* saveAlloc(action) {
  try {
    let data;
    if (action.payload.save) {
      const res = yield call(Api.addAlloc, action.payload.data);
      data = res.data;
    } else {
      const res = yield call(Api.editAlloc, action.payload.data);
      data = res.data;
    }

    yield put({
      type: SAVE_ALLOC_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while saving alloc');
    console.log(e?.response?.data);
    yield put({
      type: SAVE_ALLOC_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* deleteAlloc(action) {
  try {
    const { id, requestData } = action.payload;
    yield call(Api.deleteAlloc, id);
    yield put({
      type: GET_ALLOCS_REQUEST,
      payload: requestData,
    });
  } catch (e) {
    console.log(e, 'while deleting alloc');
    console.log(e?.response?.data);
    yield put({
      type: DELETE_ALLOC_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
