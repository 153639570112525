export default (val, onlyLowerCase) => {
  for (let el of val) {
    if (!onlyLowerCase) {
      el = el.toLowerCase();
    }
    if (!allowedList.includes(el)) {
      return 'wrong_symbol';
    }
  }
};

const allowedList = 'abcdefghijklmnopqrstuvwxyz_';
