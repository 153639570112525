import {
  CHANGE_SHOW_PDF, CLEAN_SINGLE_PRESCRIPTION,
  CREATE_PRESCRIPTION_FAIL,
  CREATE_PRESCRIPTION_REQUEST,
  CREATE_PRESCRIPTION_SUCCESS,
  GET_PRESCRIPTIONS_FAIL,
  GET_PRESCRIPTIONS_REQUEST,
  GET_PRESCRIPTIONS_SUCCESS,
  GET_SINGLE_PRESCRIPTION_FAIL,
  GET_SINGLE_PRESCRIPTION_REQUEST,
  GET_SINGLE_PRESCRIPTION_SUCCESS,
  SAVE_PRESCRIPTION_FAIL,
  SAVE_PRESCRIPTION_REQUEST,
  SAVE_PRESCRIPTION_SUCCESS,
} from '../actions/prescriptions';
import { LOG_OUT } from '../actions/account';
import convertViolDrawingInPrescription from '../../helpers/convertViolDrawingInPrescription';

const initialState = {
  prescriptions: {},
  prescriptionsLoading: false,
  prescriptionsError: '',

  singlePrescription: {},
  creatingPrescriptionLoading: false,
  creatingPrescriptionError: '',

  savingPrescriptionLoading: false,
  savingPrescriptionError: '',

  gettingSinglePrescriptionLoading: false,
  gettingSinglePrescriptionError: '',

  showPdf: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRESCRIPTIONS_REQUEST: {
      return {
        ...state,
        prescriptionsLoading: true,
        prescriptionsError: '',
      };
    }
    case GET_PRESCRIPTIONS_SUCCESS: {
      return {
        ...state,
        prescriptions: action.payload,
        prescriptionsLoading: false,
        prescriptionsError: '',
      };
    }
    case GET_PRESCRIPTIONS_FAIL: {
      return {
        ...state,
        prescriptionsLoading: false,
        prescriptionsError: 'error',
      };
    }

    case CREATE_PRESCRIPTION_REQUEST: {
      return {
        ...state,
        creatingPrescriptionLoading: true,
        creatingPrescriptionError: '',
        singlePrescription: {},
      };
    }
    case CREATE_PRESCRIPTION_SUCCESS: {
      return {
        ...state,
        singlePrescription: action.payload,
        creatingPrescriptionLoading: false,
        creatingPrescriptionError: '',
      };
    }
    case CREATE_PRESCRIPTION_FAIL: {
      return {
        ...state,
        creatingPrescriptionLoading: false,
        creatingPrescriptionError: 'errors',
      };
    }

    case CLEAN_SINGLE_PRESCRIPTION: {
      return {
        ...state,
        singlePrescription: {},
      };
    }

    case SAVE_PRESCRIPTION_REQUEST: {
      return {
        ...state,
        savingPrescriptionLoading: true,
        savingPrescriptionError: '',
      };
    }
    case SAVE_PRESCRIPTION_SUCCESS: {
      return {
        ...state,
        savingPrescriptionLoading: false,
        savingPrescriptionError: '',
      };
    }
    case SAVE_PRESCRIPTION_FAIL: {
      return {
        ...state,
        savingPrescriptionLoading: false,
        savingPrescriptionError: 'errors',
      };
    }

    case GET_SINGLE_PRESCRIPTION_REQUEST: {
      return {
        ...state,
        gettingSinglePrescriptionLoading: true,
        gettingSinglePrescriptionError: '',
        singlePrescription: {},
      };
    }
    case GET_SINGLE_PRESCRIPTION_SUCCESS: {
      return {
        ...state,
        gettingSinglePrescriptionLoading: false,
        gettingSinglePrescriptionError: '',
        singlePrescription: convertViolDrawingInPrescription(action.payload),
      };
    }
    case GET_SINGLE_PRESCRIPTION_FAIL: {
      return {
        ...state,
        gettingSinglePrescriptionLoading: false,
        gettingSinglePrescriptionError: 'error',
        singlePrescription: {},
      };
    }

    case CHANGE_SHOW_PDF: {
      return {
        ...state,
        showPdf: action.payload,
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
