import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../../../translations';
import FolderItem from './FolderItem';
import TemplateItem from './TemplateItem';
import { setConvertedChecklists } from '../../../../store/actions/checklists';

function ChecklistsTree() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const convertedChecklists = useSelector((store) => store.checklists.convertedChecklists);

  const onArrowClick = () => {
    dispatch(setConvertedChecklists([...convertedChecklists]));
  };

  const nestedItems = (items) => (
    <ul className="checklists_ul">
      {items.map((item, index) => (
        <li key={`${Number.isFinite(item.parent_id) ? 'folder' : 'template'}${item.id}${index}`} className="checklists_li">
          {Number.isFinite(item.parent_id) ? (
            <FolderItem item={item} onArrowClick={onArrowClick} hasArrow={Boolean(item.children?.length)} currentList={items} />
          ) : <TemplateItem item={item} />}
          {item.isExpanded && item.children?.length && nestedItems(item.children)}
        </li>
      ))}
    </ul>
  );

  return (
    <div className="checklists_tree">
      {nestedItems(convertedChecklists)}
    </div>
  );
}

export default ChecklistsTree;
