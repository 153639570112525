import React, { useMemo } from 'react';
import './css/index.scss';

function MySelect({
  data,
  value,
  buttonText,
  renderItem,
  label,
  error,
  className = '',
  wrapperClassName = '',
  labelClassName = '',
  info,
  showKey = 'title',
  showKeyInInput,
  onChange,
  maxHeight,
  t,
  withTranslation,
  readOnly,
  onlyDropDown = false,
  abilityToClear = true,
  idToNum = true,
}) {
  const valueItem = data?.find((item) => (item.id === (idToNum ? +value : value)));

  const drawItem = (item) => {
    if (item?.id === 'empty') {
      return t('empty');
    }

    if (item?.id === 'clear') {
      return t('clear');
    }

    if (typeof showKey === 'string') {
      return withTranslation ? t(item[showKey]) : item[showKey];
    }

    let s = '';
    showKey.forEach((showKeyItem, index) => {
      if (index % 2) {
        s += `(${item[showKeyItem]})  `;
      } else {
        s += `${item[showKeyItem]}  `;
      }
    });
    return `${s}`;
  };

  const handleClick = (itemId) => () => {
    if (itemId === 'empty') return;
    if (itemId === 'clear') return onChange(null, true);

    if (itemId === 0) return onChange(itemId, true);

    onChange(itemId);
  };

  const newData = useMemo(() => {
    if (!data?.length) {
      return [{ id: 'empty' }];
    }

    if (abilityToClear && Number.isFinite(value)) {
      return [{ id: 'clear' }, ...data];
    }

    return data;
  }, [data, value]);

  return (
    onlyDropDown ? (
      <div className={`select_div ${wrapperClassName} ${onlyDropDown && !readOnly && 'select_div_with_height'}`}>
        <div>{label && <label className={`label ${labelClassName}`}>{label}</label>}</div>
        {readOnly ? <p className="simple_text">{valueItem && drawItem(valueItem)}</p> : (
          <>
            <input onChange={() => {}} value={valueItem ? (showKeyInInput ? valueItem[showKeyInInput] : drawItem(valueItem)) : buttonText} className={`select_trigger ${onlyDropDown && 'select_trigger_with_height'} ${className}`} />
            <div className={`select_list at_only_dropdown ${!label && 'without_label'}`} style={maxHeight && { maxHeight }}>
              {newData?.map((item) => <div onMouseDown={handleClick(item.id)} key={item.id} className="select_option">{drawItem(item)}</div>)}
            </div>
          </>
        )}
        {error ? <p className="error">{error}</p> : info ? <p className="info">{info}</p> : null}
      </div>
    ) : (
      <div className={`select_div ${wrapperClassName}`}>
        {label && <label className={`label ${labelClassName}`}>{label}</label>}
        <div className={`select_inner ${error && 'select_error_inner'}`}>
          {value && newData && (
            valueItem ? renderItem(valueItem, t) : <p className="not_found">{t('not_found')}</p>
          )}
          {!readOnly && (
            <>
              <input type="button" value={buttonText} className={`select_trigger ${className}`} />
              <div className="select_list">
                {newData?.map((item) => <div onMouseDown={handleClick(item.id)} key={item.id} className="select_option">{drawItem(item)}</div>)}
              </div>
            </>
          )}
        </div>
        {error ? <p className="error">{error}</p> : info ? <p className="info">{info}</p> : null}
      </div>
    )

  );
}

export default MySelect;
