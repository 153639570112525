import React, { useEffect, useMemo, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useInputState from '../../../helpers/hooks/useInputState';
import MyInput from '../../../templates/MyInput';
import MySelect from '../../../templates/MySelect';
import MyLoading from '../../../templates/MyLoading';
import { COLORS } from '../../../helpers/constants';
import MyButton from '../../../templates/MyButton';
import sortAndConvertChecklistCategories from '../../../helpers/sortAndConvertChecklistCategories';
import Api from '../../../Api';
import getFlatCategoriesListFromNested from '../../../helpers/getFlatCategoriesListFromNested';
import CancelButton from '../../../components/CancelButton';

function SingleChecklistCategory() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryId = searchParams.get('category_id');

  const [singleChecklistCategory, setSingleChecklistCategory] = useState();
  const [categoryName, setCategoryName, categoryNameError, setCategoryNameError] = useInputState();
  const [categoryParent, setCategoryParent, categoryParentError, setCategoryParentError] = useInputState(0);

  const [savingLoading, setSavingLoading] = useState(false);
  const [nestedCategories, setNestedCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [gettingLoading, setGettingLoading] = useState(true);

  const categoriesToDraw = useMemo(() => {
    if (nestedCategories?.length) {
      const flattenedCategories = getFlatCategoriesListFromNested({ nestedArr: nestedCategories, currentId: categoryId });

      return [{ id: 0, title: t('root_directory'), titleAsTree: t('root_directory') }, ...flattenedCategories];
    }
    return [];
  }, [nestedCategories]);

  useEffect(() => {
    (async () => {
      try {
        setGettingLoading(true);
        const { data } = await Api.getChecklistsCategories();
        if (data?.length) {
          setNestedCategories(sortAndConvertChecklistCategories(data));
          setCategories(data);
        }
      } catch (e) {
        console.warn(e, 'while getting checklist categories');
      } finally {
        setGettingLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (categories?.length && categoryId) {
      const currentCategory = categories.find((item) => item.id === +categoryId);
      if (currentCategory) {
        setCategoryName(currentCategory.title);
        setCategoryParent(currentCategory.parent_id);
      }
      setSingleChecklistCategory(currentCategory);
    }
  }, [categories]);

  const save = async () => {
    let flag = false;

    if (!categoryName) {
      setCategoryNameError(t('category_name') + t('is_required_s_r'));
      flag = true;
    }

    if (flag) {
      return;
    }
    try {
      setSavingLoading(true);

      let newSortNumber;

      if (!categoryParent) {
        newSortNumber = nestedCategories.length;
      } else {
        let parentLastSortNumber = 0;
        let isChanged = false;

        categories.forEach((item) => {
          if (item.parent_id === categoryParent && item.sort_number >= parentLastSortNumber) {
            parentLastSortNumber = item.sort_number;
            isChanged = true;
          }
        });

        newSortNumber = isChanged ? parentLastSortNumber + 1 : parentLastSortNumber;
      }

      const sortNumber = categoryId && singleChecklistCategory.parent_id === categoryParent
        ? singleChecklistCategory.sort_number
        : newSortNumber;

      const payload = {
        parent_id: categoryParent || 0,
        title: categoryName,
        sort_number: sortNumber,
      };

      console.log(payload);

      if (categoryId) {
        payload.id = singleChecklistCategory.id;
        const { data } = await Api.editChecklistCategory(payload);
        if (data.message === 'OK') {
          navigate('..');
        }
      } else {
        const { data } = await Api.addChecklistCategory(payload);
        if (data.message === 'OK') {
          navigate('..');
        }
      }
    } catch (e) {
      console.warn(e, 'while editing checklist category');
    } finally {
      setSavingLoading(false);
    }
  };

  return (
    <div className="single_checklist_wrapper">
      <SinglePageHeader text={categoryId ? t('edit_checklist_category') : t('add_checklist_category')} />
      {gettingLoading ? <MyLoading color={COLORS.main} /> : (
        categories?.length && categoryId && !singleChecklistCategory ? (
          <p className="no_category">
            {t('no_category')}
            {' '}
            {categoryId}
          </p>
        ) : (
          <div className="single_checklist_container">
            {categoryId && (
              <div className="id_row">
                <p className="bold_text">ID:</p>
                <p className="simple_text">{categoryId}</p>
              </div>
            )}
            <MyInput wrapperClassName="category_input" label={t('category_name')} value={categoryName} onChange={setCategoryName} error={categoryNameError} />
            <MySelect className="category_select" buttonText={t('choose_category')} abilityToClear={false} showKey="titleAsTree" showKeyInInput="title" onlyDropDown label={t('category')} data={categoriesToDraw} value={categoryParent} onChange={setCategoryParent} error={categoryParentError} t={t} />
            <div className="buttons_row">
              <MyButton text={t('save')} onClick={save} className="save_button" loading={savingLoading} />
              <CancelButton />
            </div>
          </div>
        )
      )}

    </div>
  );
}

export default SingleChecklistCategory;
