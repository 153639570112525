export const GET_PRESCRIPTIONS_REQUEST = 'GET_PRESCRIPTIONS_REQUEST';
export const GET_PRESCRIPTIONS_SUCCESS = 'GET_PRESCRIPTIONS_SUCCESS';
export const GET_PRESCRIPTIONS_FAIL = 'GET_PRESCRIPTIONS_FAIL';
export function getPrescriptionsRequest(data) {
  return {
    type: GET_PRESCRIPTIONS_REQUEST,
    payload: data,
  };
}
export const GET_SINGLE_PRESCRIPTION_REQUEST = 'GET_SINGLE_PRESCRIPTION_REQUEST';
export const GET_SINGLE_PRESCRIPTION_SUCCESS = 'GET_SINGLE_PRESCRIPTION_SUCCESS';
export const GET_SINGLE_PRESCRIPTION_FAIL = 'GET_SINGLE_PRESCRIPTION_FAIL';
export function getSinglePrescriptionRequest(data) {
  return {
    type: GET_SINGLE_PRESCRIPTION_REQUEST,
    payload: data,
  };
}

export const CREATE_PRESCRIPTION_REQUEST = 'CREATE_PRESCRIPTION_REQUEST';
export const CREATE_PRESCRIPTION_SUCCESS = 'CREATE_PRESCRIPTION_SUCCESS';
export const CREATE_PRESCRIPTION_FAIL = 'CREATE_PRESCRIPTION_FAIL';
export function createPrescriptionRequest(data) {
  return {
    type: CREATE_PRESCRIPTION_REQUEST,
    payload: data,
  };
}

export const SAVE_PRESCRIPTION_REQUEST = 'SAVE_PRESCRIPTION_REQUEST';
export const SAVE_PRESCRIPTION_SUCCESS = 'SAVE_PRESCRIPTION_SUCCESS';
export const SAVE_PRESCRIPTION_FAIL = 'SAVE_PRESCRIPTION_FAIL';
export function savePrescriptionRequest(data) {
  return {
    type: SAVE_PRESCRIPTION_REQUEST,
    payload: data,
  };
}

export const CLEAN_SINGLE_PRESCRIPTION = 'CLEAN_SINGLE_PRESCRIPTION';
export function cleanSinglePrescription() {
  return {
    type: CLEAN_SINGLE_PRESCRIPTION,
  };
}

export const CHANGE_SHOW_PDF = 'CHANGE_SHOW_PDF';
export function changeShowPdf(data) {
  return {
    type: CHANGE_SHOW_PDF,
    payload: data,
  };
}
