import {
  DELETE_COMPANY_REQUEST,
  GET_COMPANIES_FAIL,
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS, SAVE_COMPANY_FAIL, SAVE_COMPANY_REQUEST, SAVE_COMPANY_SUCCESS,
  SET_SINGLE_COMPANY,
} from '../actions/companies';
import { LOG_OUT } from '../actions/account';

const initialState = {
  companies: {},
  companiesLoading: false,
  companiesError: '',

  singleCompany: {},

  saveLoading: false,
  saveError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMPANIES_REQUEST: {
      return {
        ...state,
        companiesLoading: true,
        companiesError: '',
        singleCompany: {},
      };
    }
    case GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        companies: action.payload,
        companiesLoading: false,
        companiesError: '',
      };
    }
    case GET_COMPANIES_FAIL: {
      return {
        ...state,
        companiesLoading: false,
        companiesError: 'error',
      };
    }

    case SET_SINGLE_COMPANY: {
      const singleCompany = state.companies?.items?.find((item) => item.id === +action.payload) || {};
      return {
        ...state,
        singleCompany,
      };
    }

    case SAVE_COMPANY_REQUEST: {
      return {
        ...state,
        saveLoading: true,
        saveError: '',
      };
    }

    case SAVE_COMPANY_SUCCESS: {
      return {
        ...state,
        saveLoading: false,
        saveError: '',
      };
    }

    case SAVE_COMPANY_FAIL: {
      return {
        ...state,
        saveLoading: false,
        saveError: 'error',
      };
    }

    case DELETE_COMPANY_REQUEST: {
      return {
        ...state,
        companiesLoading: true,
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
