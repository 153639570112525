import {
  DELETE_CLIENT_REQUEST,
  GET_CLIENTS_FAIL,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS, SAVE_CLIENT_FAIL, SAVE_CLIENT_REQUEST, SAVE_CLIENT_SUCCESS,
  SET_SINGLE_CLIENT,
} from '../actions/clients';
import { LOG_OUT } from '../actions/account';

const initialState = {
  clients: {},
  clientsLoading: false,
  clientsError: '',

  singleClient: {},

  saveLoading: false,
  saveError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTS_REQUEST: {
      return {
        ...state,
        clientsLoading: true,
        clientsError: '',
        singleClient: {},
      };
    }
    case GET_CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: action.payload,
        clientsLoading: false,
        clientsError: '',
      };
    }
    case GET_CLIENTS_FAIL: {
      return {
        ...state,
        clientsLoading: false,
        clientsError: 'error',
      };
    }

    case SET_SINGLE_CLIENT: {
      const singleClient = state.clients?.items?.find((item) => item.id === +action.payload) || {};
      return {
        ...state,
        singleClient,
      };
    }

    case SAVE_CLIENT_REQUEST: {
      return {
        ...state,
        saveLoading: true,
        saveError: '',
      };
    }

    case SAVE_CLIENT_SUCCESS: {
      return {
        ...state,
        saveLoading: false,
        saveError: '',
      };
    }

    case SAVE_CLIENT_FAIL: {
      return {
        ...state,
        saveLoading: false,
        saveError: action.payload?.error || 'error',
      };
    }

    case DELETE_CLIENT_REQUEST: {
      return {
        ...state,
        clientsLoading: true,
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
