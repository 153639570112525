import React from 'react';

function SelectedProjectItem(item) {
  return (
    <div className="selected_alloc">
      <p className="selected_alloc_title">{item?.title}</p>
      <p className="selected_alloc_desc">{item?.descr}</p>
    </div>
  );
}

export default SelectedProjectItem;
