import React from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSortTypes from '../../DocumentsPages/Events/components/sortTypes';
import { deleteLetterheadRequest, getLetterheadsRequest } from '../../../store/actions/letterheads';
import SortableList from '../../../templates/SortableList';
import LetterheadItem from './components/LetterheadItem';
import AddButton from '../../../components/AddButton';
import useTranslation from '../../../translations';

function Letterheads() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const letterheads = useSelector((store) => store.letterheads.letterheads);
  const letterheadsLoading = useSelector((store) => store.letterheads.letterheadsLoading);
  const sortTypes = useSortTypes();

  const getLetterheads = (data) => {
    dispatch(getLetterheadsRequest(data));
  };

  const onAddClick = () => {
    navigate('/company_letterheads/add');
  };

  const onEditClick = (item) => {
    navigate(`/company_letterheads/edit?letterhead_id=${item.id}`);
  };

  const onDeleteClick = ({ id, requestData }) => {
    dispatch(deleteLetterheadRequest({ id, requestData }));
  };

  const addButton = () => (
    AddButton({ text: t('add_letterhead_btn'), onClick: onAddClick })
  );

  return (
    <div className="letterheads_wrapper">
      <SortableList
        data={letterheads}
        loading={letterheadsLoading}
        requestData={getLetterheads}
        sortTypes={sortTypes}
        renderItem={LetterheadItem}
        onItemEdit={onEditClick}
        onDeleteClick={onDeleteClick}
        TitleButton={addButton}
        limitOnEachPage={10}
      />
    </div>
  );
}

export default Letterheads;
