import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROLES } from '../../../../helpers/constants';
import MyButton from '../../../../templates/MyButton';
import useTranslation from '../../../../translations';
import { getChecklistsCategoriesAndTemplatesRequest } from '../../../../store/actions/checklists';
import Api from '../../../../Api';
import MyModal from '../../../../templates/MyModal';

function TemplateItem({ item }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile = useSelector((store) => store.account.profile);
  const myAllocations = useSelector((store) => store.account.myAllocations);
  const role = useSelector((store) => store.account.role);

  const [changingLoading, setChangingLoading] = useState(false);
  const [isWarningModalVisible, setIsWarningModalVisible] = useState(false);

  const gettingLoading = useSelector((store) => store.checklists.gettingChecklistsCategoriesAndTemplatesLoading);

  const onCheckUpStart = async () => {
    if (!myAllocations?.length) {
      setIsWarningModalVisible(true);
      return;
    }
    try {
      setChangingLoading(true);
      const { data } = await Api.createCheckup({ checklist_template_id: item.id });
      if (Number.isFinite(data?.id)) {
        navigate(`/checkups/edit?checkup_id=${data.id}`);
      }
    } catch (e) {
      console.warn(e, 'while creating checkup');
    } finally {
      setChangingLoading(false);
    }
  };

  const edit = () => {
    navigate(`template/edit?template_id=${item.id}`);
  };

  const requestData = () => {
    dispatch(getChecklistsCategoriesAndTemplatesRequest());
  };

  const closeModal = () => {
    setIsWarningModalVisible(false);
  };

  const remove = async () => {
    try {
      setChangingLoading(true);
      const { data } = await Api.deleteChecklistTemplate({ id: item.id });

      if (data) {
        requestData();
      } else if (data.message === 'OK') {
        requestData();
      }
    } catch (e) {
      console.warn(e, 'while deleting checklist template');
    } finally {
      setChangingLoading(false);
    }
  };

  return (
    <div className={`checklists_item ${role === ROLES.stroymanager && 'checklists_template_to_start'} ${role === ROLES.admin && 'checklists_template_to_edit'}`}>
      <img src="/images/checklist_template.png" alt="T" className="checklists_icon" />
      <p className="checklists_template_text">{item?.title}</p>
      <MyButton loading={changingLoading} onClick={onCheckUpStart} text={t('start_checkup')} className="checklists_template_button" />
      <div className={`checklists_folder_buttons ${(gettingLoading || changingLoading) && 'disabled'}`}>
        <div className="checklists_folder_button" onClick={edit}>
          <img src="/images/checklist_edit.png" alt="E" />
        </div>
        <div className="checklists_folder_button" onClick={remove}>
          <img src="/images/checklist_bin.png" alt="D" />
        </div>
      </div>
      {isWarningModalVisible && (
        <MyModal isOpen={isWarningModalVisible} closeFunction={closeModal}>
          <div className="checklist_modal_wrapper">
            <p className="checklist_modal_text">{t('checklist_you_are_not_allocated')}</p>
            <MyButton className="checklist_modal_button" onClick={closeModal} text={t('ok')} />
          </div>
        </MyModal>
      )}
    </div>
  );
}

export default TemplateItem;
