import React, { useEffect, useMemo, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDrawingsRequest, setDrawings } from '../../../store/actions/drawings';
import useTranslation from '../../../translations';
import MyButton from '../../../templates/MyButton';
import SinglePageHeader from '../../../components/SinglePageHeader';
import MySelect from '../../../templates/MySelect';
import { getObjectsRequest } from '../../../store/actions/objects';
import requestAllData from '../../../helpers/requestAllData';
import { getProjectsRequest } from '../../../store/actions/projects';
import DrawingItem from './components/DrawingItem';

function Drawings() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isAdmin = useSelector((store) => store.account.isAdmin);
  const role = useSelector((store) => store.account.role);

  const drawings = useSelector((store) => store.drawings.drawings);
  const drawingsLoading = useSelector((store) => store.drawings.drawingsLoading);

  const objects = useSelector((store) => store.objects.objects);
  const projects = useSelector((store) => store.projects.projects);

  const [project, setProject] = useState();
  const [object, setObject] = useState();

  const separatedDrawings = useMemo(() => {
    if (drawings?.length) {
      const obj = {};
      const restObj = {};

      drawings.forEach((item) => {
        if (item.project_title) {
          if (obj[item.project_title]) {
            obj[item.project_title].push(item);
          } else {
            obj[item.project_title] = [item];
          }
        } else if (restObj[t('rest_drawings')]) {
          restObj[t('rest_drawings')].push(item);
        } else {
          restObj[t('rest_drawings')] = [item];
        }
      });

      const sortedKeys = Object.keys(obj).sort((prev, next) => (prev > next ? 1 : -1));

      const list = sortedKeys.map((key) => ({ key, data: obj[key] }));
      list.push({ key: t('rest_drawings'), data: restObj[t('rest_drawings')] });
      return list;
    }

    return {};
  }, [drawings]);

  const getDrawings = () => {
    const payload = {
      project_id: project,
      build_objs_id: object,
    };

    dispatch(getDrawingsRequest(payload));
  };

  const onCreateClick = () => {
    if (isAdmin) {
      navigate('/drawings/add');
    }
  };

  const handleProject = (val) => {
    setProject(val);
  };

  const handleObject = (val) => {
    setObject(val);
  };

  const onItemClick = (drawing) => {
    navigate(`/drawings/details?drawing_id=${drawing?.id}`);
  };

  useEffect(() => {
    getDrawings();
  }, [project, object]);

  useEffect(() => {
    dispatch(setDrawings({}));
  }, []);

  useEffect(() => {
    dispatch(getProjectsRequest(requestAllData));
    dispatch(getObjectsRequest(requestAllData));
  }, []);

  return (
    <div className="drawings_wrapper">
      <SinglePageHeader
        text={t('drawings')}
        withGoBack={false}
        rightComponent={isAdmin && <MyButton onClick={onCreateClick} text={t('add_drawing')} />}
        leftComponent={(
          <MyButton
            onClick={getDrawings}
            className="refresh_button"
            iconClassName="refresh"
            iconUri="/images/refresh.png"
            loading={drawingsLoading}
            loadingEqualsDisabled={false}
          />
        )}
      />

      <div className="filter_buttons_row">
        <MySelect wrapperClassName="filter_item" buttonText={t('all_projects')} data={projects?.items} value={project} onChange={handleProject} onlyDropDown t={t} />
        <MySelect wrapperClassName="filter_item" buttonText={t('all_objects')} data={objects?.items} value={object} onChange={handleObject} onlyDropDown t={t} />
      </div>

      <div className="drawings_container">
        {separatedDrawings?.length ? (
          separatedDrawings.map(({ key, data }, index) => (
            <div key={`drawing_block${index}`} className="drawings_block">
              <p className="drawings_block_title">{key}</p>
              <div className="drawings_block_row">
                {data?.map((item, drawingIndex) => (
                  <DrawingItem
                    keyField={`${item.id}-${drawingIndex}-${index}`}
                    key={`${item.id}-${drawingIndex}-${index}`}
                    drawing={item}
                    onItemClick={onItemClick}
                  />
                ))}
              </div>
            </div>
          ))
        ) : !drawingsLoading && <p className="nothing_found_text">{t('nothing_found')}</p>}
      </div>
    </div>
  );
}

export default Drawings;
