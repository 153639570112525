import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import useTranslation from '../../../../translations';
import { createPrescriptionRequest } from '../../../../store/actions/prescriptions';
import MyLoading from '../../../../templates/MyLoading';
import { COLORS } from '../../../../helpers/constants';
import PrescriptionViols from '../../../../components/PrescriptionViols';
import useInputState from '../../../../helpers/hooks/useInputState';
import MyButton from '../../../../templates/MyButton';

function ChecklistPrescription({
  project, object, setIsDisabledObjectEditing, viols, setViols, goToPrescription, editCheckup, goingToPrescription, buildObj,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const singlePrescription = useSelector((store) => store.prescriptions.singlePrescription);
  const creatingLoading = useSelector((store) => store.prescriptions.creatingPrescriptionLoading);

  const [noEnoughDataErrorShow, setNoEnoughDataErrorShow] = useState(false);

  const onCreateClick = () => {
    if (singlePrescription?.id) return;
    if (!project || !object) {
      setNoEnoughDataErrorShow(true);
      setTimeout(() => setNoEnoughDataErrorShow(false), 2000);
      return;
    }

    dispatch(createPrescriptionRequest({ projectId: project, build_obj_id: object }));
  };

  const onGoToPrescription = () => {
    if (!singlePrescription?.id) return;
    goToPrescription(singlePrescription.id);
  };

  useEffect(() => {
    if (!creatingLoading && singlePrescription?.id) {
      setIsDisabledObjectEditing(true);
      editCheckup({ predpisan_id: singlePrescription.id });
    }
  }, [creatingLoading]);

  return (
    <div className={`prescription_part ${singlePrescription?.id && 'expanded'}`}>
      {noEnoughDataErrorShow && <p className="checklist_no_object">{t('checklist_no_object')}</p>}
      <div onClick={onCreateClick} className={`floating_part ${singlePrescription?.id && 'non_pressable'}`}>
        {creatingLoading ? <MyLoading color={COLORS.main} /> : singlePrescription?.id ? (
          <p>
            {t('prescription_draft')}
            {' '}
            №
            {singlePrescription['number-out'] || singlePrescription.number_out}
            {' '}
            {t('from')}
            {' '}
            {moment(singlePrescription.cdate).format('YYYY-MM-DD')}
          </p>
        ) : <p>{t('create_prescription')}</p>}
      </div>
      {singlePrescription?.id && (
        <>
          <p className="revealed_text">{t('revealed')}</p>
          <PrescriptionViols viols={viols} setViols={setViols} buildObj={buildObj} />
          <MyButton loading={goingToPrescription} onClick={onGoToPrescription} text={t('go_to_prescription')} className="go_to_prescription_button" />
        </>
      )}
    </div>
  );
}

export default ChecklistPrescription;
