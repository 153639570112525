import React, { useState } from 'react';
import { ScheduleData, ScheduleDayOffId } from '../localData/scheduleData';
import useTranslation from '../../translations';

const UseScheduleState = () => {
  const { lang } = useTranslation();

  const [start, setStart] = useState(ScheduleDayOffId);
  const [end, setEnd] = useState(ScheduleDayOffId);

  const [endData, setEndData] = useState(ScheduleData(lang));

  const handleStartChange = (val, shouldChangeEnd = true) => {
    if (val === null) {
      val = ScheduleDayOffId;
    }
    setStart(val);

    if (!shouldChangeEnd) {
      return;
    }

    if (val === ScheduleDayOffId) {
      setEnd(ScheduleDayOffId);
      setEndData([]);
      return;
    }

    const newEndData = ScheduleData(lang).filter((item) => item.id > val);
    setEndData(newEndData);

    if (val >= end || [undefined, null].includes(end)) {
      setEnd(newEndData[0].id);
    }
  };

  const handleEndChange = (val, startVal) => {
    setEnd(val);

    if (!startVal) {
      return;
    }

    const newEndData = ScheduleData(lang).filter((item) => item.id > startVal);
    setEndData(newEndData);
  };

  return [start, handleStartChange, end, handleEndChange, endData];
};

export default UseScheduleState;
