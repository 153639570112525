export const GET_CONFIGURATIONS_REQUEST = 'GET_CONFIGURATIONS_REQUEST';
export const GET_CONFIGURATIONS_SUCCESS = 'GET_CONFIGURATIONS_SUCCESS';
export const GET_CONFIGURATIONS_FAIL = 'GET_CONFIGURATIONS_FAIL';
export function getConfigurationsRequest(data) {
  return {
    type: GET_CONFIGURATIONS_REQUEST,
    payload: data,
  };
}

export const SET_SINGLE_CONFIGURATION = 'SET_SINGLE_CONFIGURATION';
export function setSingleConfiguration(data) {
  return {
    type: SET_SINGLE_CONFIGURATION,
    payload: data,
  };
}

export const SAVE_CONFIGURATION_REQUEST = 'SAVE_CONFIGURATION_REQUEST';
export const SAVE_CONFIGURATION_SUCCESS = 'SAVE_CONFIGURATION_SUCCESS';
export const SAVE_CONFIGURATION_FAIL = 'SAVE_CONFIGURATION_FAIL';
export function saveConfigurationRequest(data) {
  return {
    type: SAVE_CONFIGURATION_REQUEST,
    payload: data,
  };
}

export const DELETE_CONFIGURATION_REQUEST = 'DELETE_CONFIGURATION_REQUEST';
export const DELETE_CONFIGURATION_SUCCESS = 'DELETE_CONFIGURATION_SUCCESS';
export const DELETE_CONFIGURATION_FAIL = 'DELETE_CONFIGURATION_FAIL';
export function deleteConfigurationRequest(data) {
  return {
    type: DELETE_CONFIGURATION_REQUEST,
    payload: data,
  };
}
