import Api from '../Api';

const createChecklistTemplateItems = async ({
  items,
  templateId,
  type,
}) => {
  try {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (!item.title) continue;

      if (Number.isFinite(item.id) && item.flagDel) {
        const { data } = await Api.deleteCheckListTemplateItem({ id: item.id });
        continue;
      }

      const payload = {
        checklist_template_id: +templateId,
        title: item.title,
        descr: item.descr,
        sort_number: i,

        is_incoming: type === CHECKLIST_TEMPLATE_ITEMS_TYPES.incoming,
        is_operational: type === CHECKLIST_TEMPLATE_ITEMS_TYPES.operational,
        is_output: type === CHECKLIST_TEMPLATE_ITEMS_TYPES.output,
      };

      if (Number.isFinite(item.id)) {
        payload.id = item.id;
        const { data } = await Api.editCheckListTemplateItem(payload);
      } else {
        const { data } = await Api.addCheckListTemplateItem(payload);
      }
    }

    return true;
  } catch (e) {
    console.warn(e, 'inside createChecklistTemplateItems function');
    return false;
  }
};

export const CHECKLIST_TEMPLATE_ITEMS_TYPES = {
  incoming: 'incoming',
  operational: 'operational',
  output: 'output',
};

export default createChecklistTemplateItems;
