export const GET_CHECKUPS_REQUEST = 'GET_CHECKUPS_REQUEST';
export const GET_CHECKUPS_SUCCESS = 'GET_CHECKUPS_SUCCESS';
export const GET_CHECKUPS_FAIL = 'GET_CHECKUPS_FAIL';
export function getCheckupsRequest(data) {
  return {
    type: GET_CHECKUPS_REQUEST,
    payload: data,
  };
}

export const SET_CHECKUPS = 'SET_CHECKUPS';
export function setCheckups(data) {
  return {
    type: SET_CHECKUPS,
    payload: data,
  };
}
