import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  CREATE_PRESCRIPTION_FAIL,
  CREATE_PRESCRIPTION_REQUEST,
  CREATE_PRESCRIPTION_SUCCESS,
  GET_PRESCRIPTIONS_FAIL,
  GET_PRESCRIPTIONS_REQUEST,
  GET_PRESCRIPTIONS_SUCCESS,
  GET_SINGLE_PRESCRIPTION_FAIL,
  GET_SINGLE_PRESCRIPTION_REQUEST,
  GET_SINGLE_PRESCRIPTION_SUCCESS,
  SAVE_PRESCRIPTION_FAIL,
  SAVE_PRESCRIPTION_REQUEST,
  SAVE_PRESCRIPTION_SUCCESS,
} from '../actions/prescriptions';

export default function* watcher() {
  yield takeLatest(GET_PRESCRIPTIONS_REQUEST, getPrescriptions);
  yield takeLatest(CREATE_PRESCRIPTION_REQUEST, createPrescription);
  yield takeLatest(SAVE_PRESCRIPTION_REQUEST, savePrescription);
  yield takeLatest(GET_SINGLE_PRESCRIPTION_REQUEST, getSinglePrescriptions);
}

function* getPrescriptions(action) {
  try {
    const { data } = yield call(Api.getPrescriptions, action.payload);

    yield put({
      type: GET_PRESCRIPTIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting prescriptions');
    console.log(e?.response?.data);
    yield put({
      type: GET_PRESCRIPTIONS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* createPrescription(action) {
  try {
    const { data } = yield call(Api.createPrescription, action.payload);

    yield put({
      type: CREATE_PRESCRIPTION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while creating prescription');
    console.log(e?.response?.data);
    yield put({
      type: CREATE_PRESCRIPTION_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* savePrescription(action) {
  try {
    const { data } = yield call(Api.savePrescription, action.payload);

    yield put({
      type: SAVE_PRESCRIPTION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while saving prescription');
    console.log(e?.response?.data);
    yield put({
      type: SAVE_PRESCRIPTION_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* getSinglePrescriptions(action) {
  try {
    const { data } = yield call(Api.getSinglePrescription, action.payload);

    yield put({
      type: GET_SINGLE_PRESCRIPTION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting single prescription');
    console.log(e?.response?.data);
    yield put({
      type: GET_SINGLE_PRESCRIPTION_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
