import {
  DELETE_PROJECT_REQUEST,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS, SAVE_PROJECT_FAIL, SAVE_PROJECT_REQUEST, SAVE_PROJECT_SUCCESS,
  SET_SINGLE_PROJECT,
} from '../actions/projects';
import { LOG_OUT } from '../actions/account';

const initialState = {
  projects: {},
  projectsLoading: false,
  projectsError: '',

  singleProject: {},

  saveLoading: false,
  saveError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS_REQUEST: {
      return {
        ...state,
        projectsLoading: true,
        projectsError: '',
        singleProject: {},
      };
    }
    case GET_PROJECTS_SUCCESS: {
      return {
        ...state,
        projects: action.payload,
        projectsLoading: false,
        projectsError: '',
      };
    }
    case GET_PROJECTS_FAIL: {
      return {
        ...state,
        projectsLoading: false,
        projectsError: 'error',
      };
    }

    case SET_SINGLE_PROJECT: {
      const singleProject = state.projects?.items?.find((item) => item.id === +action.payload) || {};
      return {
        ...state,
        singleProject,
      };
    }

    case SAVE_PROJECT_REQUEST: {
      return {
        ...state,
        saveLoading: true,
        saveError: '',
      };
    }

    case SAVE_PROJECT_SUCCESS: {
      return {
        ...state,
        saveLoading: false,
        saveError: '',
      };
    }

    case SAVE_PROJECT_FAIL: {
      return {
        ...state,
        saveLoading: false,
        saveError: 'error',
      };
    }

    case DELETE_PROJECT_REQUEST: {
      return {
        ...state,
        projectsLoading: true,
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
