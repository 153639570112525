import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  DELETE_PROJECT_FAIL,
  DELETE_PROJECT_REQUEST,
  GET_PROJECTS_FAIL,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS, SAVE_PROJECT_FAIL,
  SAVE_PROJECT_REQUEST, SAVE_PROJECT_SUCCESS,
} from '../actions/projects';

export default function* watcher() {
  yield takeLatest(GET_PROJECTS_REQUEST, getProjects);
  yield takeLatest(SAVE_PROJECT_REQUEST, saveProject);
  yield takeLatest(DELETE_PROJECT_REQUEST, deleteProject);
}

function* getProjects(action) {
  try {
    const { data } = yield call(Api.getProjects, action.payload);

    yield put({
      type: GET_PROJECTS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting projects');
    console.log(e?.response?.data);
    yield put({
      type: GET_PROJECTS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* saveProject(action) {
  try {
    let data;
    if (action.payload.save) {
      const res = yield call(Api.addProject, action.payload.data);
      data = res.data;
    } else {
      const res = yield call(Api.editProject, action.payload.data);
      data = res.data;
    }

    yield put({
      type: SAVE_PROJECT_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while saving project');
    console.log(e?.response?.data);
    yield put({
      type: SAVE_PROJECT_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* deleteProject(action) {
  try {
    const { id, requestData } = action.payload;
    yield call(Api.deleteProject, id);
    yield put({
      type: GET_PROJECTS_REQUEST,
      payload: requestData,
    });
  } catch (e) {
    console.log(e, 'while deleting project');
    console.log(e?.response?.data);
    yield put({
      type: DELETE_PROJECT_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
