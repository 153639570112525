import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Api from '../../../../Api';
import useTranslation from '../../../../translations';
import MyButton from '../../../../templates/MyButton';

function DrawingItem({ drawing, keyField, onItemClick }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAdmin = useSelector((store) => store.account.isAdmin);

  const onEditClick = (ev) => {
    ev.stopPropagation();

    if (isAdmin) {
      navigate(`/drawings/edit?drawing_id=${drawing.id}`);
    }
  };

  return (
    <div onClick={() => onItemClick(drawing)} key={keyField} className={`drawings_item ${isAdmin && 'changeable'}`}>
      <div className="drawings_item_clickable">
        <div className="drawings_thumb">
          <img src={Api.drawings_thumb_url_no_request + drawing.id} alt="A" />
        </div>
        <div>
          {!!drawing.mark_arch && (
            <div className="drawing_archive">
              <p>{t('archival')}</p>
            </div>
          )}
          <p className="drawing_item_title">{drawing.draw_title}</p>
          <p className="drawing_item_obj">{drawing.build_objs_title}</p>
        </div>
      </div>

      {isAdmin && (
        <div className="drawing_edit_button">
          <MyButton
            text={t('pravka')}
            onClick={onEditClick}
            className="drawing_edit_button_inner"
          />
        </div>
      )}

      {!!drawing.viols_count && <p className="viols_count">{drawing.viols_count}</p>}
    </div>
  );
}

export default DrawingItem;
