import React, { useEffect, useMemo, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SinglePageHeader from '../../../../components/SinglePageHeader';
import useTranslation from '../../../../translations';
import EditDrawingItem from './components/EditDrawingItem';
import requestAllData from '../../../../helpers/requestAllData';
import { getObjectsRequest } from '../../../../store/actions/objects';
import { getProjectsRequest } from '../../../../store/actions/projects';
import MyLoading from '../../../../templates/MyLoading';
import { COLORS } from '../../../../helpers/constants';
import Api from '../../../../Api';

function EditDrawings() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const drawingId = searchParams.get('drawing_id');

  const createdDrawings = useSelector((store) => store.drawings.createdDrawings);

  const [singleDrawing, setSingleDrawing] = useState();
  const [gettingLoading, setGettingLoading] = useState(false);

  const onCheckIfNoDrawings = () => {
    if (!drawingId && filteredCreatedDrawings?.length === 0) {
      navigate('/drawings');
    }
  };

  const filteredCreatedDrawings = useMemo(() => {
    if (createdDrawings?.length) {
      return createdDrawings.filter((item) => !item.isDisabled);
    }
  }, [createdDrawings]);

  useEffect(() => {
    dispatch(getProjectsRequest(requestAllData));
    dispatch(getObjectsRequest(requestAllData));
  }, []);

  useEffect(() => {
    onCheckIfNoDrawings();
  }, [createdDrawings]);

  useEffect(() => {
    if (drawingId) {
      (async () => {
        try {
          setGettingLoading(true);
          const { data } = await Api.getDrawing(drawingId);
          setSingleDrawing(data);
        } catch (e) {
          console.warn(e, 'while getting single drawing');
        } finally {
          setGettingLoading(false);
        }
      })();
    }
  }, [drawingId]);

  return (
    <div className="edit_drawing_wrapper">
      <SinglePageHeader
        text={
          drawingId ? t('edit_drawing')
            : createdDrawings?.length > 1 ? t('edit_new_drawings')
              : t('edit_new_drawing')
        }
      />
      {drawingId ? (
        <div>
          {gettingLoading ? <MyLoading color={COLORS.main} /> : !singleDrawing ? (
            <p className="no_drawing">
              {t('no_drawing')}
              {drawingId}
            </p>
          ) : (
            <EditDrawingItem
              drawing={singleDrawing}
              isLastIndex
              gaBackAfterAction
            />
          )}
        </div>
      ) : (
        createdDrawings?.map((item, index) => !item.isDisabled && (
          <EditDrawingItem
            drawing={item}
            index={index}
            isLastIndex={index === createdDrawings.length - 1}
            key={index}
          />
        ))
      )}
    </div>
  );
}

export default EditDrawings;
