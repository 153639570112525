import { GET_EVENTS_FAIL, GET_EVENTS_REQUEST, GET_EVENTS_SUCCESS } from '../actions/events';
import { LOG_OUT } from '../actions/account';

const initialState = {
  events: {},
  eventsLoading: false,
  eventsError: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS_REQUEST: {
      return {
        ...state,
        eventsLoading: true,
        eventsError: '',
      };
    }
    case GET_EVENTS_SUCCESS: {
      return {
        ...state,
        events: action.payload,
        eventsLoading: false,
        eventsError: '',
      };
    }
    case GET_EVENTS_FAIL: {
      return {
        ...state,
        eventsLoading: false,
        eventsError: 'error',
      };
    }

    case LOG_OUT: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
