import React, { useCallback, useEffect, useState } from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useTranslation from '../../../translations';
import SinglePageHeader from '../../../components/SinglePageHeader';
import useInputState from '../../../helpers/hooks/useInputState';
import MyButton from '../../../templates/MyButton';
import {
  getObjectsRequest,
} from '../../../store/actions/objects';
import MySelect from '../../../templates/MySelect';
import { getProjectsRequest } from '../../../store/actions/projects';
import {
  createPrescriptionRequest,
  getSinglePrescriptionRequest,
} from '../../../store/actions/prescriptions';
import useNavigateSearch from '../../../helpers/hooks/useNavigateSearch';
import EditPrescription from './components/EditPrescription';
import { ROLES } from '../../../helpers/constants';
import requestAllData from '../../../helpers/requestAllData';
import { getMyAllocationsRequest } from '../../../store/actions/account';

function Edit() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const prescriptionId = searchParams.get('prescription_id');
  const navigateSearch = useNavigateSearch();
  const location = useLocation();

  const projects = useSelector((store) => store.projects.projects);
  const objects = useSelector((store) => store.objects.objects);

  const profile = useSelector((store) => store.account.profile);
  const myAllocations = useSelector((store) => store.account.myAllocations);
  const isAdmin = useSelector((store) => store.account.isAdmin);
  const role = useSelector((store) => store.account.role);
  const projectsLoading = useSelector((store) => store.projects.projectsLoading);
  const singlePrescription = useSelector((store) => store.prescriptions.singlePrescription);
  const creatingPrescriptionLoading = useSelector((store) => store.prescriptions.creatingPrescriptionLoading);
  const creatingPrescriptionError = useSelector((store) => store.prescriptions.creatingPrescriptionError);

  const [project, setProject, projectError, setProjectError] = useInputState();
  const [object, setObject, objectError, setObjectError] = useInputState();
  const [filteredObjects, setFilteredObjects] = useState();

  const [toCreate, setToCreate] = useState(false);

  useEffect(() => {
    if (prescriptionId) {
      if (prescriptionId !== singlePrescription.id) {
        dispatch(getSinglePrescriptionRequest({ 'filter-val': { id: prescriptionId } }));
      }
    }
  }, [prescriptionId]);

  useEffect(() => {
    if (!projects?.items) {
      dispatch(getProjectsRequest(requestAllData));
    }
    if (!objects?.items) {
      dispatch(getObjectsRequest(requestAllData));
    }
    if (!myAllocations?.length) {
      dispatch(getMyAllocationsRequest());
    }
  }, []);

  useEffect(() => {
    if (toCreate && !creatingPrescriptionLoading && !creatingPrescriptionError && singlePrescription?.id) {
      navigateSearch(location.pathname, { prescription_id: singlePrescription.id });
    }
  }, [creatingPrescriptionLoading]);

  const createDraft = () => {
    if (project && object) {
      dispatch(createPrescriptionRequest({ projectId: project, build_obj_id: object }));
      setToCreate(true);
    }
  };

  const checkIfAssignedToApprove = () => {
    const currentAlloc = myAllocations.find((item) => item.project_id === singlePrescription['project-id']
      && item.object_id === singlePrescription['build-obj-id']
      && item.alloc_id === singlePrescription?.['agreement-user-alc']?.id);

    if (currentAlloc) {
      return true;
    }
  };

  const handleProject = (val, setInstantly) => {
    setProject(val, setInstantly);
    setObject(null, true);
    if (val) {
      setFilteredObjects(objects?.items?.filter((item) => item.project_id === +val));
    }
  };

  return (
    <div className="single_prescription_wrapper">
      <SinglePageHeader text={prescriptionId ? t('edit_prescription') : t('add_prescription')} />
      {isAdmin ? <p className="prescription_simple_text">{t('admin_not_creating_prescription')}</p>
        : role === ROLES.constructor ? <p className="prescription_simple_text">{t('constructor_not_creating_prescription')}</p>
          : role === ROLES.client ? <p className="prescription_simple_text">{t('client_not_creating_prescription')}</p>
            : role === ROLES.chief && !checkIfAssignedToApprove() && !projectsLoading ? <p className="prescription_simple_text">{t('chief_not_creating_prescription')}</p>
              : prescriptionId ? <EditPrescription paramsId={prescriptionId} isAssignedToThisPrescr={checkIfAssignedToApprove()} />
                : !projectsLoading && !prescriptionId && !projects?.items?.length ? <p>{t('you_are_not_allocated')}</p>
                  : (
                    <div>
                      <div className="single_prescription_item row">
                        <p className="prescription_item_title prescription_margin_right">
                          {t('engineer')}
                          :
                        </p>
                        <p className="prescription_simple_text">
                          {profile?.fio}
                        </p>
                      </div>
                      <div className="single_prescription_item">
                        <p className="prescription_item_title prescription_margin_right">
                          {t('project')}
                          :
                        </p>
                        <MySelect data={projects?.items} showKey="title" buttonText={t('choose_project')} value={project} onChange={handleProject} onlyDropDown t={t} />
                      </div>
                      {project && (
                      <div className="single_prescription_item">
                        <p className="prescription_item_title prescription_margin_right">
                          {t('object')}
                          :
                        </p>
                        <MySelect data={filteredObjects} showKey="title" buttonText={t('choose_object')} value={object} onChange={setObject} onlyDropDown t={t} />
                      </div>
                      )}

                      {object && project && (
                      <div>
                        <MyButton loading={creatingPrescriptionLoading} onClick={createDraft} text={t('create')} className="display_inline_block margin_top" />
                        <p className="info_text">{t('you_cant_change_after')}</p>
                      </div>
                      )}
                    </div>
                  )}
    </div>
  );
}

export default Edit;
