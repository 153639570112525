import React from 'react';

function HeaderRightComponent({ t }) {
  return (
    <div className="checkup_header_right">
      <img src="/images/checkup_save.png" alt="S" />
      <p>{t('auto_save')}</p>
    </div>
  );
}

export default HeaderRightComponent;
