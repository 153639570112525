import React from 'react';
import EditButton from '../../../../components/EditButton';
import DeleteButton from '../../../../components/DeleteButton';
import getRoleName from '../../../../helpers/getRoleName';

function UserItem(item, index, onItemEdit, showDeleteModal, t, loading) {
  return item && (
    <div key={item.id} className="user_item">
      <div className="user_left">
        <img src="/images/users.png" alt="img" className="user_img" />
        <div className="user_info_div">
          <p className="user_title">{item.uid}</p>
          <p className="user_desc">{item.descr}</p>
          <p className="user_info">{item.fio}</p>
          <div className="user_row">
            <p className="group_item">
              client_id
              {' '}
              {item['client-id']}
            </p>
            {/* {item.groups.map((group, groupIndex) => <p key={group + groupIndex} className="group_item">{group}</p>)} */}
          </div>
          <p className="user_info">{t(getRoleName(item))}</p>
        </div>
      </div>
      {!loading && (
        <div className="row">
          <EditButton onClick={() => onItemEdit(item)} />
          {!item.is_admin && (
            <DeleteButton onClick={() => showDeleteModal(item)} />
          )}
        </div>
      )}
    </div>
  );
}

export default UserItem;
