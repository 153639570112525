import { t } from '../../translations';

const list = new Array(25).fill('').map((_, index) => ({
  id: index,
  title: `${index}:00`,
}));

export const ScheduleData = (lang) => [{
  id: ScheduleDayOffId,
  title: t('day_off', lang),
}, ...list];

export const ScheduleDayOffId = -1;
