import React, { useEffect, useRef, useState } from 'react';
import './css/index.scss';
import MyInput from '../../templates/MyInput';
import MyButton from '../../templates/MyButton';
import DrawingsModal from './components/DrawingsModal';
import Api from '../../Api';

function PrescriptionViolItem({
  viol, index: violIndex, viols, setViols, violsError, buildObj,
}) {
  const fileInputRef = useRef();
  const [photoPreview, setPhotoPreview] = useState();
  const [selectedFile, setSelectedFile] = useState();

  const [isDrawingsModalVisible, setIsDrawingsModalVisible] = useState(false);

  const updateViols = (index) => (e) => {
    const newViols = viols.map((item, i) => {
      if (index === i) {
        let { value } = e.target;
        if (e.target.files && e.target.files.length) {
          [value] = e.target.files;
          setSelectedFile(value);
        }
        return { ...item, [e.target.name]: value };
      }
      return item;
    });
    setViols(newViols, true);
  };

  const violsFocusOut = (index) => (ev) => {
    if (!ev.target.value && index !== viols.length - 1) {
      removeViol(index)();
    }
  };

  const removeViol = (index) => () => {
    const violToDelete = viols.find((el, i) => i === index);
    violToDelete['flag-del'] = true;
    setViols([...viols], true);
  };

  useEffect(() => {
    if (!selectedFile) {
      setPhotoPreview(undefined);
      return;
    }

    const photoUrl = URL.createObjectURL(selectedFile);
    setPhotoPreview(photoUrl);

    return () => URL.revokeObjectURL(photoUrl);
  }, [selectedFile]);

  const onCameraClick = () => {
    console.log(fileInputRef?.current);
    fileInputRef?.current?.click();
  };

  const closeDrawingsModal = () => {
    setIsDrawingsModalVisible(false);
  };

  const openDrawingsModal = () => {
    setIsDrawingsModalVisible(true);
  };

  const updateViolsDrawing = (drawingData) => {
    const currentViol = viols.find((el, i) => i === violIndex);
    currentViol.drawing = drawingData;
    setViols([...viols], true);
  };

  return (
    <div className="viol_item row">
      <MyInput name="text" error={violsError} className="viol_input_main" wrapperClassName="viol_input_item default_size" value={viol.text} onChange={updateViols(violIndex)} onBlur={violsFocusOut(violIndex)} />
      <MyButton
        onClick={onCameraClick}
        iconUri={
          photoPreview || (viol['picture-file'] ? Api.viols_img_url_no_request + viol['viol-id']
            : '/images/camera.png')
        }
        className={`camera_button ${(viol['picture-file'] || photoPreview) && 'with_photo'}`}
        iconClassName="camera_button_image"
        disabled={!viol.text}
      />
      {viol.text && (
        <MyInput
          innerRef={fileInputRef}
          name="file"
          wrapperClassName="viol_input_item hide"
          onChange={updateViols(violIndex)}
          type="file"
          accept="image/png, image/jpeg"
        />
      )}
      {/* {(viol['picture-file'] || photoPreview) && buildObj?.id && ( */}
      {buildObj?.id && (
        <>
          <MyButton
            className="viol_drawing_button"
            iconUri={viol.drawing ? '/images/viol_drawing_checked.png' : '/images/viol_drawing.png'}
            onClick={openDrawingsModal}
            disabled={!viol.text}
          />
          {viol.text && (
            <DrawingsModal
              viol={viol}
              isOpen={isDrawingsModalVisible}
              closeFunction={closeDrawingsModal}
              updateViolsDrawing={updateViolsDrawing}
              buildObj={buildObj}
            />
          )}
        </>
      )}
      {viol.text && <MyButton className="viol_close_button" iconUri="/images/close.png" onClick={removeViol(violIndex)} />}
    </div>
  );
}

export default PrescriptionViolItem;
