import React from 'react';
import '../css/index.scss';
import { t } from '../../../../translations';

function HeaderRow() {
  return (
    <div className="header_row">
      <p className="item second">{t('number')}</p>
      <p className="item third">{t('date')}</p>
      <p className="item fourth">{t('object')}</p>
      <p className="item fifth">{t('worker')}</p>
      <p className="item sixth">{t('predp_number')}</p>
      <p className="item eights">{t('status')}</p>
      <p className="item ninth" />
    </div>
  );
}

export default HeaderRow;
