import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import { GET_EVENTS_FAIL, GET_EVENTS_REQUEST, GET_EVENTS_SUCCESS } from '../actions/events';

export default function* watcher() {
  yield takeLatest(GET_EVENTS_REQUEST, getEvents);
}

function* getEvents(action) {
  try {
    const { data } = yield call(Api.getEvents, action.payload);

    yield put({
      type: GET_EVENTS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting events');
    console.log(e?.response?.data);
    yield put({
      type: GET_EVENTS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
