import React, { useEffect, useState } from 'react';
import './css/index.scss';
import { Buffer } from 'buffer';
import Api, { api } from '../../Api';
import MyLoading from '../MyLoading';
import { COLORS } from '../../helpers/constants';

function MyImg({
  src, alt, className, ...p
}) {
  const [imageSrc, setImagesSrc] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const { data } = await Api.getRequest(src, { responseType: 'arraybuffer' });

      const mainPart = Buffer.from(data, 'binary').toString('base64');
      const source = `data:image/jpeg;base64,${mainPart}`;

      setImagesSrc(source);
      setLoading(false);
    })();
  }, [src]);

  return (
    loading ? (
      <div className={`my_img_loading_div ${className}`}>
        <MyLoading color={COLORS.main} height={42} width={42} />
      </div>
    ) : <img src={imageSrc} className={className} alt={alt} {...p} />
  );
}

export default MyImg;
