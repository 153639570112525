import React from 'react';
import './css/index.scss';
import { Oval } from 'react-loader-spinner';
import { COLORS } from '../../helpers/constants';

function MyLoading({
  height = 20, width = 20, className, visible, color = COLORS.white, secondaryColor,
}) {
  return (
    <Oval
      height={height}
      width={width}
      color={color}
      wrapperClass={className}
      visible={visible}
      ariaLabel="oval-loading"
      secondaryColor={secondaryColor || color}
      strokeWidth={2}
      strokeWidthSecondary={2}

    />
  );
}

export default MyLoading;
