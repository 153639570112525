import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROLES } from '../../../../helpers/constants';
import Api from '../../../../Api';
import { getChecklistsCategoriesAndTemplatesRequest } from '../../../../store/actions/checklists';

function FolderItem({
  item, onArrowClick, hasArrow, currentList,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [changingLoading, setChangingLoading] = useState(false);

  const role = useSelector((store) => store.account.role);
  const gettingLoading = useSelector((store) => store.checklists.gettingChecklistsCategoriesAndTemplatesLoading);

  const edit = () => {
    navigate(`category/edit?category_id=${item.id}`);
  };

  const requestData = () => {
    dispatch(getChecklistsCategoriesAndTemplatesRequest());
  };

  const onExpandArrowClick = () => {
    item.isExpanded = !item.isExpanded;
    onArrowClick();
  };

  const move = async (val) => {
    try {
      const sortedOnlyCategories = currentList.filter((el) => isFinite(el.parent_id));
      setChangingLoading(true);
      const currentIndex = sortedOnlyCategories.findIndex((el) => el.id === item.id);

      if (currentIndex + val < 0 || currentIndex + val >= sortedOnlyCategories.length) return;

      if (sortedOnlyCategories[currentIndex + val].parent_id === item.parent_id) {
        const otherItem = sortedOnlyCategories[currentIndex + val];

        const newOtherItem = { ...otherItem, sort_number: item.sort_number, children: undefined };
        const newItem = { ...item, sort_number: otherItem.sort_number, children: undefined };

        const { data: firstRes } = await Api.editChecklistCategory(newItem);
        const { data: secondRes } = await Api.editChecklistCategory(newOtherItem);

        if (firstRes.message === 'OK' && secondRes.message === 'OK') {
          requestData();
        }
      }
    } catch (e) {
      console.warn(e, 'while editing checklist category');
    } finally {
      setChangingLoading(false);
    }
  };

  const up = async () => {
    await move(-1);
  };

  const down = async () => {
    await move(1);
  };

  const remove = async () => {
    try {
      setChangingLoading(true);
      const { data } = await Api.deleteChecklistCategory({ id: item.id, parent_id: item.parent_id });

      if (data.message === 'OK') {
        requestData();
      }
    } catch (e) {
      console.warn(e, 'while deleting checklist category');
    } finally {
      setChangingLoading(false);
    }
  };

  return (
    <div className={`checklists_item ${role === ROLES.admin && 'checklists_folder_buttons_visible'}`}>
      {hasArrow && (
        <div onClick={onExpandArrowClick} className={`checklists_expand_arrow ${!item.isExpanded && 'closed'}`}>
          <img src="/images/expand_arrow.png" alt="<" className="checklists_expand_arrow_img" />
        </div>
      )}
      <img src="/images/checklist_folder.png" alt="F" className="checklists_icon" />
      <p className="checklists_folder_text">{item?.title}</p>
      <div className={`checklists_folder_buttons ${(gettingLoading || changingLoading) && 'disabled'}`}>
        <div className="checklists_folder_button" onClick={edit}>
          <img src="/images/checklist_edit.png" alt="E" />
        </div>
        <div className="checklists_folder_button" onClick={up}>
          <img src="/images/checklist_up.png" alt="U" />
        </div>
        <div className="checklists_folder_button" onClick={down}>
          <img src="/images/checklist_down.png" alt="D" />
        </div>
        <div className="checklists_folder_button" onClick={remove}>
          <img src="/images/checklist_bin.png" alt="D" />
        </div>
      </div>
    </div>
  );
}

export default FolderItem;
