export default (password) => {
  if (password.length < 11) {
    return 'pass_length_error';
  } if (!includesUppercase(password)) {
    return 'pass_uppercase_error';
  } if (!includesLowercase(password)) {
    return 'pass_lowercase_error';
  } if (includesList(password)) {
    return 'pass_wrong_symbol_error';
  }
};

const includesUppercase = (string) => {
  for (const stringElement of string) {
    if (stringElement === stringElement.toUpperCase()) {
      return true;
    }
  }
};

const includesLowercase = (string) => {
  for (const stringElement of string) {
    if (stringElement === stringElement.toLowerCase()) {
      return true;
    }
  }
};

const includesList = (string) => {
  const list = '\\/$# \'"`';
  for (const stringElement of string) {
    if (list.includes(stringElement)) {
      return true;
    }
  }
};
