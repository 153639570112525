import React from 'react';
import './css/index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSortTypes from '../../DocumentsPages/Events/components/sortTypes';
import { deleteUserRequest, getUsersRequest } from '../../../store/actions/users';
import SortableList from '../../../templates/SortableList';
import UserItem from './components/UserItem';
import AddButton from '../../../components/AddButton';
import useTranslation from '../../../translations';

function Users() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isRoot = useSelector((store) => store.account.isRoot);

  const users = useSelector((store) => store.users.users);
  const usersLoading = useSelector((store) => store.users.usersLoading);
  const sortTypes = useSortTypes();

  const getUsers = (data) => {
    dispatch(getUsersRequest(data));
  };

  const onAddClick = () => {
    navigate('/users/add');
  };

  const onEditClick = (item) => {
    navigate(`/users/edit?user_id=${item.id}`);
  };

  const onDeleteClick = ({ id, requestData }) => {
    dispatch(deleteUserRequest({ id, requestData }));
  };

  const addButton = () => (
    !isRoot && AddButton({ text: t('add_user_btn'), onClick: onAddClick })
  );

  return (
    <div className="users_wrapper">
      <SortableList
        data={users}
        loading={usersLoading}
        requestData={getUsers}
        sortTypes={sortTypes}
        renderItem={UserItem}
        onItemEdit={onEditClick}
        onDeleteClick={onDeleteClick}
        TitleButton={addButton}
        limitOnEachPage={10}
        keyToShowOnDelete="uid"
      />
    </div>
  );
}

export default Users;
