import React from 'react';

function SelectedProjectItem(item) {
  return (
    <div className="selected_company">
      <p className="selected_company_title">{item?.title}</p>
      <p className="selected_company_desc">{item?.descr}</p>
    </div>
  );
}

export default SelectedProjectItem;
