import React, { useState } from 'react';
import './css/index.scss';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import navLinkList, { rootNavLinkList } from '../../helpers/localData/navLinkList';
import useTranslation from '../../translations';
import { logOut } from '../../store/actions/account';
import LocalStorageServices from '../../helpers/LocalStorageServices';
import { COLORS } from '../../helpers/constants';
import MyButton from '../../templates/MyButton';

function NavBar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const profile = useSelector((store) => store.account.profile);
  const role = useSelector((store) => store.account.role);

  const isRoot = useSelector((store) => store.account.isRoot);
  const isAdmin = useSelector((store) => store.account.isAdmin);

  const listToDraw = isRoot ? rootNavLinkList : navLinkList;

  const [showMenu, setShowMenu] = useState(false);

  const logout = async () => {
    await LocalStorageServices.removeToken();
    dispatch(logOut());
  };

  const onNavigate = () => {
    if (showMenu) {
      setShowMenu(false);
    }
  };

  return (
    <div className={`nav_bar_wrapper ${showMenu && 'show'}`}>
      <div className="nav_bar">
        <div className="nav_info_div">
          <img src="/images/logoWhite.png" alt="" className="logo" />
          <p className="name">{profile?.fio}</p>
          <NavLink to="/profile">
            <p className="change_profile">{t('change_profile')}</p>
          </NavLink>
          <p className="info">{profile?.descr}</p>
          <p className="info2">{profile['type-name']}</p>
          <p className="mail">{profile?.uid}</p>
        </div>
        <div className="line" />
        <div className="nav_list">
          {listToDraw?.map((section) => (
            <div className="section" key={section.title}>
              <p className="title">{t(section.title)}</p>
              {section.links.map((item) => (
                item.name === 'logout' ? (
                  <div
                    key={item.name}
                    className="nav_item"
                    onClick={logout}
                  >
                    <img src={item.icon} alt="" className="nav_item_img" />
                    <p className="nav_item_text">{t(item.name)}</p>
                  </div>
                ) : item.name === 'next_cloud' ? (
                  <Link
                    target="_blank"
                    to="https://cloud.teamject.com/login"
                    key={item.name}
                    className="nav_item"
                  >
                    <img src={item.icon} alt="" className="nav_item_img" />
                    <p className="nav_item_text">{t(item.name)}</p>
                  </Link>
                ) : (item.adminProtected && !isAdmin) || item.hideFor?.includes(role) ? (
                  <div
                    key={item.name}
                    className="nav_item disabled"
                  >
                    <img src={item.icon} alt="" className="nav_item_img" />
                    <p className="nav_item_text">{t(item.name)}</p>
                  </div>
                ) : (
                  <NavLink
                    onClick={onNavigate}
                    style={({ isActive }) => (isActive ? { backgroundColor: COLORS.main } : undefined)}
                    to={item.name}
                    key={item.name}
                    className="nav_item"
                  >
                    <img src={item.icon} alt="" className="nav_item_img" />
                    <p className="nav_item_text">{t(item.name)}</p>
                  </NavLink>
                )
              ))}
            </div>
          ))}
        </div>
        <p className="footer">2021-2022 TeamJect</p>
      </div>
      <MyButton onClick={() => setShowMenu((prev) => !prev)} iconClassName={`open_menu_image ${showMenu && 'show'}`} iconUri="/images/menu_arrow.png" className="open_menu" />
    </div>

  );
}

export default NavBar;
