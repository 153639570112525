import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import {
  DELETE_CONFIGURATION_FAIL,
  DELETE_CONFIGURATION_REQUEST,
  GET_CONFIGURATIONS_FAIL,
  GET_CONFIGURATIONS_REQUEST,
  GET_CONFIGURATIONS_SUCCESS, SAVE_CONFIGURATION_FAIL,
  SAVE_CONFIGURATION_REQUEST, SAVE_CONFIGURATION_SUCCESS,
} from '../actions/configurations';

export default function* watcher() {
  yield takeLatest(GET_CONFIGURATIONS_REQUEST, getConfigurations);
  yield takeLatest(SAVE_CONFIGURATION_REQUEST, saveConfiguration);
  yield takeLatest(DELETE_CONFIGURATION_REQUEST, deleteConfiguration);
}

function* getConfigurations(action) {
  try {
    const { data } = yield call(Api.getConfigurations, action.payload);

    yield put({
      type: GET_CONFIGURATIONS_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting configurations');
    console.log(e?.response?.data);
    yield put({
      type: GET_CONFIGURATIONS_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* saveConfiguration(action) {
  try {
    let data;
    if (action.payload.save) {
      const res = yield call(Api.addConfiguration, action.payload.data);
      data = res.data;
    } else {
      const res = yield call(Api.editConfiguration, action.payload.data);
      data = res.data;
    }

    yield put({
      type: SAVE_CONFIGURATION_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while saving configuration');
    console.log(e?.response?.data);
    yield put({
      type: SAVE_CONFIGURATION_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}

function* deleteConfiguration(action) {
  try {
    const { id, requestData } = action.payload;
    yield call(Api.deleteConfiguration, id);
    yield put({
      type: GET_CONFIGURATIONS_REQUEST,
      payload: requestData,
    });
  } catch (e) {
    console.log(e, 'while deleting configuration');
    console.log(e?.response?.data);
    yield put({
      type: DELETE_CONFIGURATION_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
