import React from 'react';

function SelectedUserItem(item) {
  return (
    <div className="selected_alloc">
      <p className="selected_alloc_small_title">{item && item.uid}</p>
      <p className="selected_alloc_title">{item?.fio}</p>
      <p className="selected_alloc_desc">{item?.descr}</p>
      <p className="selected_alloc_desc">{item && item['type-name']}</p>
    </div>
  );
}

export default SelectedUserItem;
