export default (count = 14) => {
  let result = '';
  result += getRandom(upperCase, 1);
  result += getRandom(lowerCase, 1);
  result += getRandom(allCharacters, count - 2);
  return result;
};

function getRandom(characters, length) {
  let result = '';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
const allCharacters = `${upperCase + lowerCase}0123456789_`;
