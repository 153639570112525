import React, { useMemo } from 'react';
import orderBySomeField from '../../../../helpers/orderBySomeField';
import Api from '../../../../Api';

function ChecklistMarks({
  setData, data, title, subTitle, checkupId,
}) {
  const sortedData = useMemo(() => {
    if (data?.length) {
      return orderBySomeField(data);
    }
  }, [data]);

  const editMarker = async (index, markType) => {
    const currentMark = data[index];
    const firstValue = currentMark.mark;
    try {
      if (currentMark.mark === markType) return;

      currentMark.mark = markType;
      setData([...data]);

      const { data: resData } = await Api.editCheckupMark({ mark_id: currentMark.id, mark: markType, checkup_id: +checkupId });
      if (resData?.result !== 'success') {
        currentMark.mark = firstValue;
        setData([...data]);
      }
    } catch (e) {
      currentMark.mark = firstValue;
      setData([...data]);
      console.warn(e, 'while editing mark');
    }
  };

  const onDenyMarker = (index) => () => {
    editMarker(index, 0).then();
  };

  const onAcceptMarker = (index) => async () => {
    editMarker(index, 1).then();
  };

  if (!sortedData?.length) {
    return null;
  }

  return (
    <div className="marks_wrapper">
      <p className="marks_title">{title}</p>
      <div className="marks_subtitle_row">
        <img src="/images/marks_file.png" alt="F" />
        <p>{subTitle}</p>
      </div>
      <div className="marks_list">
        {sortedData?.map((mark, index) => (
          <div className="mark_item" key={`${mark.id}${index}`}>
            <div className={`mark_button ${mark.mark === 1 && 'accepted'} ${mark.mark === 0 && 'disabled'}`} onClick={onAcceptMarker(index)}>
              <img src="/images/white_tick.png" alt="V" />
            </div>
            <div className={`mark_button ${mark.mark === 0 && 'denied'} ${mark.mark === 1 && 'disabled'}`} onClick={onDenyMarker(index)}>
              <img src="/images/close.png" alt="X" />
            </div>
            <div className="mark_info_block">
              <p className="mark_info_title">{mark.title}</p>
              <p className="mark_info_text">{mark.descr}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChecklistMarks;
