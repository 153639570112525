export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL';
export function getProjectsRequest(data) {
  return {
    type: GET_PROJECTS_REQUEST,
    payload: data,
  };
}

export const SET_SINGLE_PROJECT = 'SET_SINGLE_PROJECT';
export function setSingleProject(data) {
  return {
    type: SET_SINGLE_PROJECT,
    payload: data,
  };
}

export const SAVE_PROJECT_REQUEST = 'SAVE_PROJECT_REQUEST';
export const SAVE_PROJECT_SUCCESS = 'SAVE_PROJECT_SUCCESS';
export const SAVE_PROJECT_FAIL = 'SAVE_PROJECT_FAIL';
export function saveProjectRequest(data) {
  return {
    type: SAVE_PROJECT_REQUEST,
    payload: data,
  };
}

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAIL = 'DELETE_PROJECT_FAIL';
export function deleteProjectRequest(data) {
  return {
    type: DELETE_PROJECT_REQUEST,
    payload: data,
  };
}
