import { takeLatest, call, put } from 'redux-saga/effects';
import Api from '../../Api';
import { GET_ROLES_FAIL, GET_ROLES_REQUEST, GET_ROLES_SUCCESS } from '../actions/roles';

export default function* watcher() {
  yield takeLatest(GET_ROLES_REQUEST, getRoles);
}

function* getRoles(action) {
  try {
    const { data } = yield call(Api.getRoles, action.payload);

    yield put({
      type: GET_ROLES_SUCCESS,
      payload: data,
    });
  } catch (e) {
    console.log(e, 'while getting roles');
    console.log(e?.response?.data);
    yield put({
      type: GET_ROLES_FAIL,
      message: e?.message,
      payload: e?.response?.data,
    });
  }
}
