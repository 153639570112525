import React, { useState } from 'react';
import useInputState from '../../../../helpers/hooks/useInputState';
import sleep from '../../../../helpers/sleep';
import MyModal from '../../../../templates/MyModal';
import MyInput from '../../../../templates/MyInput';
import useTranslation from '../../../../translations';
import MyButton from '../../../../templates/MyButton';
import CancelButton from '../../../../components/CancelButton';
import validateUserPassword from '../../../../helpers/validateUserPassword';
import Api from '../../../../Api';

function PasswordChangingModal({ isVisible, setIsVisible, profile }) {
  const { t } = useTranslation();

  const [newPassword, setNewPassword, newPasswordError, setNewPasswordError] = useInputState();
  const [repeatPassword, setRepeatPassword, repeatPasswordError, setRepeatPasswordError] = useInputState();

  const [savingLoading, setSavingLoading] = useState(false);

  const closeModal = async () => {
    setIsVisible(false);
    await sleep(300);
    setNewPassword('', true);
    setRepeatPassword('', true);
  };

  const save = async () => {
    let flag = false;
    const error = validateUserPassword(newPassword);
    if (error) {
      setNewPasswordError(t(error));
      flag = true;
    }
    if (repeatPassword !== newPassword) {
      setRepeatPasswordError(t('repeat_password_does_not_match'));
      flag = true;
    }
    if (flag) {
      return;
    }

    try {
      setSavingLoading(true);

      const payload = {
        id: profile.id,
        pswd: newPassword,
        uid: profile.uid,
        descr: profile.descr,
        fio: profile.fio,
        'user-type-id': profile['user-type-id'],
      };

      const { data } = await Api.editUser(payload);

      if (data?.message === 'Ok') {
        await closeModal();
      }
    } catch (e) {
      console.warn(e, 'while changing user password');
    } finally {
      setSavingLoading(false);
    }

    console.log('saving');
  };

  return (
    <MyModal isOpen={isVisible} closeFunction={closeModal}>
      <div className="change_password_modal_wrapper">
        <MyInput
          value={newPassword}
          onChange={setNewPassword}
          label={t('new_password')}
          error={newPasswordError}
          wrapperClassName="change_password_modal_input"
          info={t('pass_info')}
        />
        <MyInput
          value={repeatPassword}
          onChange={setRepeatPassword}
          label={t('repeat_password')}
          error={repeatPasswordError}
          wrapperClassName="change_password_modal_input"
        />

        <div className="change_password_modal_buttons_row">
          <MyButton
            text={t('change')}
            onClick={save}
            loading={savingLoading}
          />
          <CancelButton
            text={t('cancel')}
            onClick={closeModal}
            disabled={savingLoading}
          />
        </div>
      </div>
    </MyModal>
  );
}

export default PasswordChangingModal;
