import React from 'react';
import EditButton from '../../../../components/EditButton';
import DeleteButton from '../../../../components/DeleteButton';

function ConfigurationItem(item, index, onItemEdit, showDeleteModal, t, loading, isAdmin) {
  return item && (
    <div key={item.key + index} className="configuration_item">
      <div className="configuration_left">
        <img src="/images/configurations.png" alt="img" className="configuration_img" />
        <div className="configuration_info_div">
          <p className="configuration_title">{item.key}</p>
          <p className="configuration_desc">{item.value}</p>
          <p className="configuration_info">{item.data_type}</p>
          <p className="configuration_info">{item.descr}</p>
        </div>
      </div>
      {!loading && isAdmin && (
        <div className="row">
          <EditButton onClick={() => onItemEdit(item)} />
          <DeleteButton onClick={() => showDeleteModal(item)} />
        </div>
      )}
    </div>
  );
}

export default ConfigurationItem;
