import React from 'react';

function SelectedAllocItem(item) {
  return (
    <div className="selected_alloc">
      <p className="selected_alloc_title">{item?.title}</p>
      <p className="selected_alloc_desc">{item?.descr}</p>
      <p className="selected_alloc_info">{item?.address}</p>
    </div>
  );
}

export default SelectedAllocItem;
