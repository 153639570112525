import React from 'react';
import '../css/index.scss';
import moment from 'moment';
import { t } from '../../../../translations';

function ItemRow(item, index, ...rest) {
  return (
    <div className={`item_row ${rest[5] && 'clickable'}`} key={item.id} onClick={() => rest[5](item)}>
      <p className="item first">{moment(item.cdate).format('YYYY-MM-DD HH:mm')}</p>
      <p className="item second">{item['document-type'] === 'predpisan' ? item['predp-project-title'] : item['act-project-title']}</p>
      <p className="item third">{item['document-type'] === 'predpisan' ? item['predp-user-fio-short'] : item['act-user-fio-short']}</p>
      <p className="item fourth">{t(item['document-type'])}</p>
      <p className="item fifth">{item['document-type'] === 'predpisan' ? item['predp-number-out'] : item['act-number-out']}</p>
      <p className="item sixth">{t(item.status)}</p>
    </div>
  );
}

export default ItemRow;
