import React from 'react';
import MyLoading from '../../../../templates/MyLoading';
import { COLORS } from '../../../../helpers/constants';
import useTranslation from '../../../../translations';

function MyAllocationsPart({ data, loading }) {
  const { t } = useTranslation();
  return (
    <div className="my_allocations_wrapper">
      <div className="my_allocations_header">
        <p className="my_allocations_title">{t('my_allocations_title')}</p>
        {loading && <MyLoading color={COLORS.main} />}
      </div>

      {!loading && !data?.length ? <p className="no_data">{t('no_my_allocs')}</p>
        : data.map((item, index) => (
          <div key={index} className="my_allocations_item">
            <p className="my_alloc_item_title">{item.project_title}</p>
            <p className="my_alloc_item_info">{item.object_title}</p>
            <p className="my_alloc_item_info">
              {`${t('user_position_via_project')}: ${item.role}`}
            </p>
            <p className="my_alloc_item_info">
              {`${t('prikaz')}: ${item.prikaz_number} ${t('from')} ${item.prikaz_date}`}
            </p>
          </div>
        ))}
    </div>
  );
}

export default MyAllocationsPart;
