import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import MyButton from '../../../templates/MyButton';
import useTranslation from '../../../translations';
import DrawingMarker from '../../DrawingMarker';
import MyLoading from '../../../templates/MyLoading';
import MyImg from '../../../templates/MyImg';
import Api from '../../../Api';

const MAX_SIZE = 5;
const STEP = 0.5;

function DrawingsModal({
  isOpen, closeFunction, children, style, closeOnPressOutside = true, viol, updateViolsDrawing, buildObj,
}) {
  const { t } = useTranslation();
  const markerRef = useRef(null);

  const drawings = useSelector((store) => store.drawings.drawings);
  const drawingsLoading = useSelector((store) => store.drawings.drawingsLoading);

  const [modalState, setModalState] = useState(0);
  const [dotCoordinates, setDotCoordinates] = useState({});
  const [activeDrawing, setActiveDrawing] = useState();

  const onClosePress = () => {
    closeFunction();
    setTimeout(() => {
      setDotCoordinates({});
      setModalState(0);
      setActiveDrawing();
    }, 500);
  };

  const onBackPress = () => {
    if (dotCoordinates?.x) {
      setDotCoordinates((prev) => ({
        ...prev,
        x: undefined,
        y: undefined,
        angle: 0,
        sizeCoefficient: 1,
      }));
    } else {
      setModalState(0);
      setActiveDrawing();
      updateViolsDrawing(undefined);
    }
  };

  const onDrawingItemClick = (item) => {
    setActiveDrawing(item);
    setModalState(1);
  };

  const onPlusClick = () => {
    if (dotCoordinates.sizeCoefficient <= MAX_SIZE - STEP) {
      setDotCoordinates((prev) => ({
        ...prev,
        sizeCoefficient: prev.sizeCoefficient + STEP,
      }));
    }
  };

  const onMinusClick = () => {
    if (dotCoordinates.sizeCoefficient >= 1 + STEP) {
      setDotCoordinates((prev) => ({
        ...prev,
        sizeCoefficient: prev.sizeCoefficient - STEP,
      }));
    }
  };

  const onDrawingClick = (ev) => {
    if (!dotCoordinates?.offsetHeight) {
      return;
    }

    const bounds = ev.target.getBoundingClientRect();
    const x = (ev.clientX - bounds.left) / dotCoordinates.sizeCoefficient;
    const y = (ev.clientY - bounds.top) / dotCoordinates.sizeCoefficient;

    setDotCoordinates((prev) => ({ ...prev, x, y }));
  };

  const onSliderChange = (ev) => {
    setDotCoordinates((prev) => ({ ...prev, angle: ev.target.value }));
  };

  const onOkClick = () => {
    const {
      x, y, angle, offsetHeight, offsetWidth, sizeCoefficient,
    } = dotCoordinates;

    const payload = {
      draw_id: activeDrawing?.id,
      point_top: Math.round((y / offsetHeight) * 100),
      point_left: Math.round((x / offsetWidth) * 100),
      point_direction: Math.round(+angle),
      point_zoom: sizeCoefficient,
    };

    updateViolsDrawing(payload);
    onClosePress();
  };

  const onDrawingLoad = (ev) => {
    const { offsetHeight, offsetWidth } = ev.target;

    if (viol?.drawing) {
      setDotCoordinates({
        y: (offsetHeight * viol.drawing.point_top) / 100,
        x: (offsetWidth * viol.drawing.point_left) / 100,
        angle: viol.drawing.point_direction,
        offsetHeight,
        offsetWidth,
        sizeCoefficient: viol.drawing.point_zoom,
      });
    } else {
      setDotCoordinates((prev) => ({
        ...prev,
        offsetHeight,
        offsetWidth,
        sizeCoefficient: 1,
        angle: 0,
      }));
    }
  };

  useEffect(() => {
    if (viol.drawing && drawings?.length) {
      const currentDrawing = drawings.find((item) => item.id === viol.drawing.draw_id);
      onDrawingItemClick(currentDrawing);
    }
  }, [viol, isOpen, drawings]);

  useEffect(() => {
    if (isOpen && markerRef?.current && dotCoordinates.x) {
      markerRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [markerRef?.current, isOpen, dotCoordinates.x]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeOnPressOutside ? closeFunction : undefined}
      style={{ overlay: styles.overlay, content: { ...styles.content, ...style } }}
      closeTimeoutMS={200}
    >
      <div className="drawing_modal_wrapper">
        <div className="drawing_modal_container">
          <div className="buttons_row">
            {modalState !== 0 ? (
              <MyButton
                className="drawing_modal_button"
                iconUri="/images/arrow_back.png"
                onClick={onBackPress}
              />
            ) : <div />}
            <MyButton className="drawing_modal_button" iconUri="/images/close.png" onClick={onClosePress} />
          </div>

          {modalState === 0 ? (
            <div className="list_wrapper">
              <p className="object_title">{buildObj.title}</p>
              <p className="object_address">{buildObj.address}</p>
              <div className="list_scroll_view">
                {drawingsLoading ? <MyLoading /> : drawings?.length && drawings?.map((drawingItem, index) => (
                  <div key={index} className="drawing_item" onClick={() => onDrawingItemClick(drawingItem)}>
                    <img src={Api.drawings_thumb_url_no_request + drawingItem.id} alt="A" className="drawing_preview" />
                    <p className="drawing_name">{drawingItem.draw_title}</p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="item_wrapper">
              <p className="drawing_title">{activeDrawing?.draw_title}</p>
              <div className="drawing_info_row">
                <img src="/images/projects_icon.png" alt="p" className="drawing_info_img" />
                <p className="drawing_info_text">{buildObj.title}</p>
                <img src="/images/objects_icon.png" alt="o" className="drawing_info_img" />
                <p className="drawing_info_text">{buildObj.address}</p>
              </div>
              <div className="single_drawing_wrapper">
                <div className="zoom_buttons_row">
                  <MyButton
                    text="+"
                    className="size_button"
                    textClassName="size_button_text"
                    onClick={onPlusClick}
                    disabled={dotCoordinates.sizeCoefficient > MAX_SIZE - STEP || !dotCoordinates.offsetHeight}
                  />
                  <MyButton
                    text="–"
                    className="size_button"
                    textClassName="size_button_text"
                    onClick={onMinusClick}
                    disabled={dotCoordinates.sizeCoefficient < 1 + STEP || !dotCoordinates.offsetHeight}
                  />
                </div>
                <div className="drawing_clickable_wrapper">
                  {/* transform: `scale(${sizeCoefficient})` */}
                  <div className="drawing_clickable" onClick={onDrawingClick}>
                    <img
                      style={{ width: `${100 * dotCoordinates.sizeCoefficient}%` }}
                      onLoad={onDrawingLoad}
                      src={`${Api.drawings_url_no_request}${activeDrawing?.id}`}
                      alt="D"
                      className="single_drawing_photo"
                    />
                    {dotCoordinates?.x && (
                      <DrawingMarker
                        markerRef={markerRef}
                        coordinates={dotCoordinates}
                        sizeCoefficient={dotCoordinates.sizeCoefficient}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="drawing_bottom_row">
                <div className="drawing_info_div">
                  <img src={dotCoordinates?.x ? '/images/drawing_slider.png' : '/images/drawing_finger.png'} alt="A" />
                  <span>{dotCoordinates?.x ? t('drawing_slider_text') : t('drawing_finger_text')}</span>
                </div>
                {dotCoordinates?.x && (
                  <div className="slider_div">
                    <div className="slider_container">
                      <input type="range" min="-180" max="180" value={dotCoordinates?.angle || 0} className="slider" onChange={onSliderChange} />
                      <div className="angles_row">
                        {[-180, -90, 0, 90, 180].map((item) => (
                          <div key={item} className="angle_item">
                            <div className={`angle_stick ${item === 0 && 'with_margin'}`} />
                            <span className="angle_value">
                              {item}
                              °
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <MyButton text={t('ok')} className="ok_button" onClick={onOkClick} />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {children}
      </div>
    </Modal>
  );
}

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    maxHeight: '100vh',
    overflow: 'hidden',
  },
  content: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(255,255,255,0)',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 0,
  },
};

export default DrawingsModal;
